export default class CalendlyService {
    province;
    jobGroupId;
    noordhollandUtrecht;
    gelderlandFlevoland;
    noordbrabantZeeland;
    otherProvinces;

    constructor(province, jobGroupId) {
        this.province = province;
        this.jobGroupId = jobGroupId;
        this.noordhollandUtrecht = ['Noord-Holland', 'Utrecht'];
        this.gelderlandFlevoland = ['Gelderland', 'Flevoland'];
        this.noordbrabantZeeland = ['Noord-Brabant', 'Zeeland'];
        this.otherProvinces = ['Zuid-Holland', 'Limburg', 'Friesland', 'Groningen', 'Drenthe', 'Overijssel'];
    }

    getCalendlyUrl(){
        return 'https://calendly.com/myshifts/uitleg-info-meeting-voor-bedrijven';

        /*
        if(this.isNoordhollandUtrecht()){
            return 'https://calendly.com/myshifts-intake/my-shifts-intake';
            //return 'https://calendly.com/mandy-111/my-shifts-intake-kitchen-crew-hospitality-met-volker-willems';
        }

        if(this.isGelderlandFlevoland()){
            return 'https://calendly.com/myshifts-intake/my-shifts-intake';
            //return 'https://calendly.com/dimphiemyshifts/intake';
        }

        if(this.isNoordbrabantZeeland()){
            return 'https://calendly.com/myshifts-intake/my-shifts-intake';
            //return 'https://calendly.com/noortje0/my-shifts-intake-kitchen-crew-hospitality-met-volker-willems';
        }

        if(this.isOtherProvinces()){
            return 'https://calendly.com/myshifts-intake/my-shifts-intake';
        }
        */
    }

   isNoordhollandUtrecht() {
     return this.noordhollandUtrecht.includes(this.province);
    }

    isGelderlandFlevoland() {
        return this.gelderlandFlevoland.includes(this.province);
    }

    isNoordbrabantZeeland() {
        return this.noordbrabantZeeland.includes(this.province);
    }

    isOtherProvinces() {
        return this.otherProvinces.includes(this.province);
    }

}
