<template>
  <div v-if="dataFetched">
    <div class="bg-black bg-noise-top text-white py-4 pb-16">
      <div class="flex flex-col items-center text-center p-4">
        <LogoBlackSvg/>

        <h1 v-html="$t('dashboard_recruiter.title', {
                companyName: '<span class=\'text-orange\'>' + userData.companyName + '</span>'
            })"></h1>

      </div>
    </div>
    <div class="black-bar mb-4"></div>

    <div class="container mx-auto p-4 -mt-32 relative z-10 break-words">
      <div class="flex space-between mb-8">
        <div class="info-block flex-1" @click="overlay.details = true">
          <span class="info-block__data">{{subscribedUsers.length}}</span>
          <p class="info-block__description text-sm">{{ $t('dashboard_recruiter.subtitle_shiftys_subscribed') }}</p>

          <button type="button" class="button button--text button--small text-sm">
            {{ $t('general.details') }}
          </button>
        </div>

        <div class="info-block flex-1" @click="shareCode">
          <span class="info-block__data">{{ userData.recruiterCode }}</span>
          <p class="info-block__description text-sm">{{ $t('dashboard_recruiter.subtitle_unique_code') }}</p>

          <button type="button" class="button button--text button--small text-sm">
            <template v-if="hasWebApi()">
              <i class="icon-share mr-2"/>
              {{ $t('general.share') }}
            </template>
            <template v-else>
              <i class="icon-copy mr-2"/>
              {{ $t('general.code') }} {{ $t('general.copy') }}
            </template>
          </button>

          <transition name="fade">
            <p class="text-sm mt-1" v-if="showCopiedCode"> {{ $t('general.code') }}  {{ $t('general.copied') }}</p>
          </transition>

        </div>
      </div>

      <div class="text-center mb-12">
        <h3>{{ $t('dashboard_recruiter.share_unique_code') }}</h3>
        <p class="text-gray">{{ userData.recruiterUrl }}</p>


        <button type="button" class="button button--primary_text--normal" @click="shareUrl">
          <template v-if="hasWebApi()">
            <i class="icon-share mr-2"/>
            {{ $t('general.share') }}
          </template>
          <template v-else>
             <i class="icon-copy mr-2"/>
              {{ $t('general.link') }} {{ $t('general.copy') }}
          </template>
        </button>

        <transition name="fade">
          <p class="text-sm mt-1" v-if="showCopiedUrl">{{ $t('general.link') }}  {{ $t('general.copied') }}</p>
        </transition>

        <p class="text-gray mt-8">{{ $t('dashboard_recruiter.share_description') }}</p>
        <button type="button" class="button button--primary_text--normal" @click="logout()">
          {{ $t('general.logout') }}
        </button>
      </div>
    </div>

    <hr>

    <div class="container mx-auto p-4">
      <div class="text-center mb-8">
        <h3>{{ $t('dashboard_recruiter.edit_account_title') }}</h3>
        <p class="text-gray">{{ $t('dashboard_recruiter.edit_account_text') }}</p>
        <a :href="`mailto:${emailAddress}`" class="button button--primary_text--normal">{{ $t('dashboard_recruiter.edit_account_link_text') }}</a>
      </div>
    </div>

    <div v-if="overlay.details">
      <Overlay @closeOverlay="overlay.details = false">
        <h3 class="text-center">{{ $t('dashboard_recruiter.subscriptions_title') }}</h3>

        <UserList :users="subscribedUsers" class="-mx-6" item-class="px-6"/>

        <div class="mb-2">
          <p class="text-gray">
            <span class="rounded-full w-4 h-4 inline-block bg-green mr-2"></span>
            {{ $t('general.active') }}
          </p>
          <p class="text-gray">
            <span class="rounded-full w-4 h-4 inline-block bg-orange mr-2"></span>
            {{ $t('general.pending') }}
          </p>
          <p class="text-gray">
            <span class="rounded-full w-4 h-4 inline-block bg-red mr-2"></span>
            {{ $t('general.suspended') }}
          </p>
        </div>
      </Overlay>
    </div>
  </div>
</template>

<script>
import Overlay from "@/components/Overlay";
import UserList from "@/components/users/UserList";

export default {
  components: {
    Overlay,
    UserList,
  },

  data: () => ({
    dataFetched: false,

    showCopiedUrl: false,
    showCopiedCode: false,

    emailAddress: process.env.VUE_APP_MYSHIFTS_MAILTO,
    overlay: {
      details: false,
    },
    subscribedUsers: [],
  }),

  computed: {
    userData() {
      return this.$store.getters['user/get_user'];
    }
  },

  methods: {
    shareCode() {
      if (this.hasWebApi()) {
        navigator.share({
          title: `Recruiter Code (${this.userData.firstName} ${this.userData.lastName})`,
          url: this.userData.recruiterCode,
        })
      } else {
        navigator.clipboard.writeText(this.userData.recruiterCode);
        this.showCopiedCode = true;
        setTimeout(()=>{
          this.showCopiedCode = false;
        }, 1500);
      }
    },

    shareUrl() {
      if (this.hasWebApi()) {
        navigator.share({
          title: `Recruiter Url (${this.userData.firstName} ${this.userData.lastName})`,
          url: this.userData.recruiterUrl,
        })
      } else {
        navigator.clipboard.writeText(this.userData.recruiterUrl);
        this.showCopiedUrl = true;
        setTimeout(()=>{
          this.showCopiedUrl = false;
        }, 1500);
      }
    },

    hasWebApi(){
      return navigator.share !== undefined;

    },

    logout() {
      this.$store.dispatch('auth/logout_action');
    },
  },

  async created() {
    this.subscribedUsers = await this.$store.dispatch('recruiter/fetch_all_subscribed_users_action');
    this.dataFetched = true;
  },
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
