import repository from "@/repositories/repository";
const recruiterRepository = repository.get("recruiter");

const state = () => ({
    subscribedUsers: []
})

const mutations = {
    SET_SUBSCRIBED_USERS(state, payload) {
        state.subscribedUsers = payload
    },
}

const actions = {
     fetch_all_subscribed_users_action: async ({commit}) => {
         const response = await recruiterRepository.getSubscribedUsers();
         commit('SET_SUBSCRIBED_USERS', response.data);
         return response.data;
    },
}

const getters = {
    get_subscribed_users: (state) => {
       return state.jobsList;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
