<template>

  <div class="App">
    <Loader :start="isLoading"/>

    <component :is="layout">
      <transition name="slide_effect" mode="out-in">
        <router-view :key="$route.fullPath"/>
      </transition>
    </component>
    <Whatsapp />
  </div>

</template>

<script>

import Loader from "@/components/Loader";
import Whatsapp from '@/components/buttons/Whatsapp.vue';


const defaultLayout = 'default'

export default {
  name: 'App',

  components: {
    Loader,
    Whatsapp
  },

  watch: {
    $route (to){
      // console.log('Route change from ', from, ' to ', to);
      window.fbq('track', 'pageView', {
        page: to.fullPath 
      });          
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['general/isLoading'];
    },

    layout() {
      return 'layout-' + (this.$route.meta.layout || defaultLayout);
    }
  },

  methods:{
    async csrfToken(){
      const CSRF_TOKEN = this.$store.getters["auth/get_csrf_token"];

      if(!CSRF_TOKEN){
        await this.$store.dispatch('auth/get_csrf_token_action');
      }
    }
  },

  async created() {
    await this.csrfToken();
  }

};
</script>

<style>


.slide_effect-enter-active {
  animation: slide-in 200ms ease-out forwards;
}

.slide_effect-leave-active {
  animation: slide-out 200ms ease-out forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-out {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-30px);
    opacity: 0;
  }
}
</style>
