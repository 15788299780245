var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{staticStyle:{"width":"320px","height":"192px"},attrs:{"center":{
          lat:_vm.shift.shiftLocation.addressCoordinates.lat,
          lng:_vm.shift.shiftLocation.addressCoordinates.lng
        },"zoom":14,"options":_vm.options,"map-type-id":"terrain"}},[_c('GmapMarker',{attrs:{"position":{
          lat:_vm.shift.shiftLocation.addressCoordinates.lat,
          lng:_vm.shift.shiftLocation.addressCoordinates.lng
          },"clickable":true,"draggable":true},on:{"click":function($event){_vm.center={
          lat:_vm.shift.shiftLocation.addressCoordinates.lat,
          lng:_vm.shift.shiftLocation.addressCoordinates.lng
          }}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }