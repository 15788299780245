import Vue from 'vue';
import {router} from './router/router'
// import {router} from './router/maintenance_router'
import store from './store/store'
import {registerGlobalComponents} from "./config/global_components/global_components";
import App from '@/App.vue'
import i18n from '@/config/i18n'
import '@/assets/css/index.css';
import 'flag-icon-css/css/flag-icon.css';

/*SETTINGS*/
import settings from "@/config/settings";
settings(Vue);

/*ERROR HANDLING && SENTRY*/
import  ErrorService  from "@/services/errors/ErrorService";
import sentry from "@/config/sentry";
if(process.env.VUE_APP_SENTRY_ENABLED === 'trueeee'){
  Vue.config.errorHandler = (error) => ErrorService.onError(error);
  sentry();
}

/*HELPERS*/
import helpers from "@/config/helpers";
helpers(Vue);

/*VUE MODALS PLUGIN*/
import vueJsModals from "@/config/vueJsModals";
vueJsModals(Vue);

/*VUE SWEETALERT2*/
import vueSweetalert2 from "@/config/vueSweetalert2";
vueSweetalert2(Vue);

/*VEE VALIDATE*/
import veeValidate from "@/config/veeValidate";
const localize = veeValidate(Vue);

/*VUE MOMENT*/
import vueMoment from "@/config/vueMoment";
vueMoment(Vue);

/*VUE TOASTIFICATION*/
import vueToastification from "@/config/vueToastification";
vueToastification(Vue);

/*VUE CLICK OUTSIDE*/
import vClickOutside from '@/config/vClickOutside';
vClickOutside(Vue);

/*VUE LODASH*/
import vueLodash from '@/config/vueLodash';
vueLodash(Vue);

/*VUE LOADERS*/
import vueLoaders from '@/config/vueLoaders';
vueLoaders(Vue);


/*VUE GOOGLE MAPS*/
import vueGoogleMaps from '@/config/vueGoogleMaps';
vueGoogleMaps(Vue);

registerGlobalComponents(Vue);

/***************/
/*VUE INSTANCE*/
new Vue({
  router,
  store,
  localize,
  i18n,
  render: h => h(App)
}).$mount('#app')



