const state = {
    kvk: '',
    establishmentNumber: '',
    companyName: '',
    zipCode: '',
    houseNumber: '',
    street: '',
    city: '',
    province: '',
    mainActivity: '',
    website: '',
    accountNumber1: '',
    accountNumber2: '',
    accountNumber3: '',
    firstname: '',
    lastname: '',
    phone: '',
}

const mutations = {

    SET_KVK(state, payload) {
        state.kvk = payload;
    },
    SET_ESTABLISHMENT_NUMBER(state, payload) {
        state.establishmentNumber = payload;
    },

    SET_COMPANY_NAME(state, payload) {
        state.companyName = payload;
    },
    SET_ZIPCODE(state, payload) {
        state.zipCode = payload;
    },
    SET_HOUSE_NUMBER(state, payload) {
        state.houseNumber = payload;
    },
    SET_STREET(state, payload) {
        state.street = payload;
    },
    SET_CITY(state, payload) {
        state.city = payload;
    },
    SET_PROVINCE(state, payload) {
        state.province = payload;
    },
    SET_MAIN_ACTIVITY(state, payload) {
        state.mainActivity = payload;
    },
    SET_WEBSITE(state, payload) {
        state.website = payload;
    },

    SET_ACCOUNT_NUMBER_1(state, payload) {
        state.accountNumber1 = payload;
    },
    SET_ACCOUNT_NUMBER_2(state, payload) {
        state.accountNumber2 = payload;
    },
    SET_ACCOUNT_NUMBER_3(state, payload) {
        state.accountNumber3 = payload;
    },

    SET_FIRST_NAME(state, payload) {
        state.firstname = payload;
    },
    SET_LAST_NAME(state, payload) {
        state.lastname = payload;
    },
    SET_PHONE(state, payload) {
        state.phone = payload;
    },

}

const actions = {

}


const getters = {

    get_all_data: (state) => {
        return state;
    },

    get_kvk: (state) => {
        return state.kvk;
    },
    get_establishment_number: (state) => {
        return state.establishmentNumber;
    },
    get_company_name: (state) => {
        return state.companyName;
    },
    get_zip_code: (state) => {
        return state.zipCode;
    },
    get_house_number: (state) => {
        return state.houseNumber;
    },
    get_street: (state) => {
        return state.street;
    },
    get_city: (state) => {
        return state.city;
    },
    get_province: (state) => {
        return state.province;
    },
    get_main_activity: (state) => {
        return state.mainActivity;
    },
    get_website: (state) => {
        return state.website;
    },

    get_account_number1: (state) => {
        return state.accountNumber1;
    },
    get_account_number2: (state) => {
        return state.accountNumber2;
    },
    get_account_number3: (state) => {
        return state.accountNumber3;
    },

    get_first_name: (state) => {
        return state.firstname;
    },
    get_last_name: (state) => {
        return state.lastname;
    },
    get_phone: (state) => {
        return state.phone;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}



