import AxiosClient from "@/config/axios/axios";
import helpers from "@/helpers/helpers";
import store from "@/store/store";
import ErrorService from "@/services/errors/ErrorService";

const resource = "/user";

export default {

    create(userData) {
        store.dispatch('general/startloading');
        return AxiosClient.post(`${resource}/create`, userData)
            .then((response) => {
                store.dispatch('general/endloading');
                return response;
            })
            .catch((error) => {
                store.dispatch('general/endloading');
                return error.response;
            })
    },

    update(userData) {
        return AxiosClient.post(`${resource}/update`, userData, helpers.setAxiosFileConfig())
            .then((response) => {
                return response;
            })
            .catch((error) => {
                ErrorService.onError(error)
            })
    },


    getById(userId) {
        return AxiosClient.get(`${resource}/get/by-id/?id=${userId}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                ErrorService.onError(error)
            })
    },


    fillProfile(userData) {
        return AxiosClient.post(`${resource}/profile/fill`, userData)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                ErrorService.onError(error)
            })
    },


    profilePhoto(userData) {
        return AxiosClient.post(`${resource}/update/profile-photo`, userData, helpers.setAxiosFileConfig())
            .then((response) => {
                return response;
            })
            .catch((error) => {
                ErrorService.onError(error)
            })
    },

    validateAddress(addressData) {
        return AxiosClient.post(`${resource}/validate/address`, addressData)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                ErrorService.onError(error)
            })
    }

}
