import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

export default (Vue)=>{
    const options = {
        // You can set your default options here
    };

    Vue.use(Toast, options);
}
