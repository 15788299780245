<template>
<div class="relative">
    <span class="absolute top-0 left-0 rounded-full w-4 h-4 inline-block mr-2" :class="`bg-${statusColor}`"></span>

    <img v-if="user.photoUrl" :src="user.photoUrl" class="rounded-full h-16 w-16">
    <img v-else src="@/assets/images/default_avatar.jpg" class="rounded-full h-16 w-16">
</div>
</template>

<script>
export default {
    components: {
    },

    props: {
        user: {
            type: Object,
            required: true,
        }
    },

    computed: {
        statusColor() {
            if (this.user.status === 'suspended') {
                return 'red';
            }

            if (this.user.status === 'active') {
                return 'green';
            }

            return 'orange';
        }
    }
}
</script>
