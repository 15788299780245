import ErrorService from "@/services/errors/ErrorService";
import AxiosClient from "@/config/axios/axios";
import helpers from "@/helpers/helpers";

const resource = "/shift";


export default {


    create(shift) {
        return AxiosClient.post(`${resource}/create`, shift, helpers.setAxiosFileConfig())
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },

    cancel(shiftId) {
        return AxiosClient.post(`${resource}/cancel/?id=${shiftId}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },

    unsubscribe(shiftId) {
        return AxiosClient.post(`${resource}/unsubscribe/?id=${shiftId}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },

    getPastForEmployee() {
        return AxiosClient.get(`${resource}/get/for-employee/past/`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },


    getRegisteredForEmployee() {
        return AxiosClient.get(`${resource}/get/for-employee/registered`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },

    getUnregisteredForEmployee() {
        return AxiosClient.get(`${resource}/get/for-employee/unregistered`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },

    getPaginatedForEmployee(pageNr) {
        return AxiosClient.get(`${resource}/get/for-employee/paginated/?pageNumber=${pageNr}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },

    getFilteredPaginatedForEmployee(pageNr, filters) {

        const queryParams = `?pageNumber=${pageNr}&distance=${filters.distance}&job=${filters.job}&organisation=${filters.organisation}&minDate=${filters.minDate}&maxDate=${filters.maxDate}`
        const url = `${resource}/get/for-employee/paginated/filtered/${queryParams}`

        return AxiosClient.get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },

    getFilteredPaginatedForEmployer(pageNr, filters) {

        const queryParams = `?pageNumber=${pageNr}&location=${filters.location}&minDate=${filters.minDate}&maxDate=${filters.maxDate}`
        const url = `${resource}/get/for-employer/paginated/filtered/${queryParams}`

        return AxiosClient.get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },

    getPaginatedPastForEmployee(pageNr) {
        return AxiosClient.get(`${resource}/get/for-employee/paginated/past/?pageNumber=${pageNr}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },


    getPaginatedPastForEmployer(pageNr) {
        return AxiosClient.get(`${resource}/get/for-employer/paginated/past/?pageNumber=${pageNr}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },

    getAllFromEmployerForEmployee(employerId) {
        return AxiosClient.get(`${resource}/get/for-employee/all/from-employer/?employerId=${employerId}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },


    getAllForEmployer() {
        return AxiosClient.get(`${resource}/get/for-employer/all`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },


    getByIdForEmployer(shiftId) {
        return AxiosClient.get(`${resource}/get/for-employer/by-id/?id=${shiftId}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },


    getByIdForEmployee(shiftId) {
        return AxiosClient.get(`${resource}/get/for-employee/by-id/?id=${shiftId}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error.response.data;
            })
    },
}
