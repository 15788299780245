<template>
  <div>
    <div class="flex">
      <InputZipCode class="w-1/3 mr-2" id="zipCode" :placeholder="$t('general.zip_code')" :required="true" v-model="zipCode"/>
      <InputText class="w-1/3 mr-2" id="houseNumber" :placeholder="$t('general.house_number')" :required="true" v-model="houseNumber"/>

      <button class="button button--primary w-1/3" @click="fetchAddress()" style="display: flex;align-items: center" :disabled="!isFilledZipCodeHouseNumber">
        <template v-if="isSearchingAddress">
          <vue-loaders name="ball-beat" color="white" scale="1"></vue-loaders>
        </template>
        <template v-else>
          Zoek adres
        </template>
      </button>
    </div>
  </div>

</template>

<script>

import userRepository from "@/repositories/userRepository";

export default {
  data: () => ({
    isSearchingAddress: false,
    zipCode: '',
    houseNumber: '',
  }),

  computed:{
    isFilledZipCodeHouseNumber(){
      return this.zipCode !== '' && this.houseNumber !== '';
    }
  },

  methods: {
    async fetchAddress() {
      const data = {
        zipCode: this.zipCode,
        houseNumber: this.houseNumber,
      }
      this.isSearchingAddress = true;
      await userRepository.validateAddress(data).then((response) => {
        this.handleFetchAddressResponse(response.data);
        this.isSearchingAddress = false;
      });
    },


    handleFetchAddressResponse(response) {
      console.log(response);
      switch (response.message) {
        case 'success':
          this.emitData(response);
          this.successToast('Adres bijgewerkt')
          break;
        case 'geolocation_failed':
          this.clearData();
          this.errorToast('Postcode of huisnummer onjuist, probeer opnieuw')
      }
    },

    emitData(response) {
      const data = {
        street: response.address.street,
        city: response.address.city,
        province: response.address.province,
        zipCode: this.zipCode,
        houseNumber: this.houseNumber,

        addressCoordinates: {
          lat: response.coordinates.lat,
          lng: response.coordinates.lng,
        },
      }
      this.$emit('successAddress', data)
    },

    clearData() {
      this.$emit('errorAddress')
    }
  }
}
</script>
