import en_views from '@/locales/en/en_views.json'
import en_general from '@/locales/en/en_general.json'
import en_components from '@/locales/en/en_components.json'
import en_onboarding from '@/locales/en/en_onboarding.json'

export default () => {
    return {
        ...en_views,
        ...en_general,
        ...en_components,
        ...en_onboarding,
    }
}
