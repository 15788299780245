<template>
  <router-link class="shift group cursor-pointer flex flex-col" :class="{ 'shift--responsive': !fixedWidth }" tag='div' :to="{ name: shiftDetailRouteName, params: {id: shift.id }}">
    <div class="shift__header flex-initial">
      <span v-if="!hasPassed" class="absolute right-0 text-sm text-red py-2 pr-12">
        <span v-if="isWithin24Hours">nog {{ shiftStartFromNow }} uur</span>
      </span>
      <span v-if="!hasPassed" class="shift__header__status" :class="`shift__header__status--${shiftStatusClass}`">
        <i v-if="isFilledShift" class="icon-checkmark_alt"/>
        <i v-if="!isFilledShift && !isWithin24Hours" class="icon-clock"/>
        <i v-if="!isFilledShift && isWithin24Hours" class="icon-warning"/>
      </span>

      <span class="rounded-sm inline-block bg-black py-1 px-2 text-white text-xs">
        {{ shift.jobGroup }}
      </span>
    </div>

    <div class="shift__body bg-noise-bottom flex-auto flex flex-col">
      <h2 class="shift__body__title mb-0">{{ shift.jobTitle }}</h2>

      <h3>{{ shift.title }}</h3>
      <p class="shift__body__time mb-0 text-sm capitalize">
        {{ shift.startDateTime | moment("dddd") }} {{ shift.startDateTime | moment("DD MMMM") }},
        <br>{{ shift.startDateTime | moment("HH:mm") }} - {{ shift.endDateTime | moment("HH:mm") }} ({{ shift.durationHoursMinutes.hours }}:{{ shift.durationHoursMinutes.minutes }} uur)
      </p>

      <p class="shift__body__location">
        <span class="icon">
          <svg viewBox="0 0 6 8" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.93483C0.00179714 1.31471 1.31471 0.00179714 2.93483 0C4.55495 0.00179714 5.86786 1.31471 5.86966 2.93483C5.86966 3.99072 4.98725 5.4503 3.17353 7.39642C3.11184 7.4627 3.02537 7.50035 2.93483 7.50035C2.84429 7.50035 2.75782 7.4627 2.69613 7.39642C0.882405 5.4503 0 3.99072 0 2.93483ZM1.95655 2.93482C1.95655 3.47511 2.39454 3.9131 2.93483 3.9131C3.47512 3.9131 3.91311 3.47511 3.91311 2.93482C3.91311 2.39453 3.47512 1.95654 2.93483 1.95654C2.39454 1.95654 1.95655 2.39453 1.95655 2.93482Z" fill="#686868"/>
          </svg>
        </span>
        <span v-if="shift.location && shift.location.title">{{ shift.location.title }}</span>
      </p>

      <h4>
        Aanmeldingen
        <span class="float-right text-gray-dark">{{ shift.amountEmployeesAccepted }} / {{ shift.amountEmployees }}</span>
      </h4>

      <div v-if="shift.registrars" class="shift__body__shiftees">
        <Shiftee v-for="(registrar, index) in registrars" :key="index"
                 :registrar="registrar"
                 :salary="registrar.salary"
        />
      </div>

      <router-link v-if="!hasPassed" class="button button--outline group-hover:bg-gray-lighter mt-auto" :to="{ name: shiftDetailRouteName, params: {id: shift.id }}" tag="button">Aanmeldingen beoordelen</router-link>
      <router-link v-if="hasPassed" class="button button--outline group-hover:bg-gray-lighter mt-auto" :to="{ name: shiftDetailRouteName, params: {id: shift.id }}" tag="button">Shift bekijken</router-link>
    </div>
  </router-link>
</template>


<script>
import Shiftee from '@/components/shifts/Shiftee';
import shiftCalculationsMixin from "@/mixins/shiftCalculationsMixin";
export default {
  mixins: [shiftCalculationsMixin],
  props: {
    shift: {
      type: Object,
      required: true
    },
    fixedWidth: {
      type: Boolean,
      default: true,
    },
    hasPassed: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    Shiftee,
  },

  data: () => ({}),




  computed: {

    registrars(){
      if(this.isFilledShift){
        return this.acceptedRegistrars;
      }
      return this.shift.registrars;
    },

    acceptedRegistrars() {
      return this.shift.registrars.filter((registrar) => {
        return registrar.status === 'accepted';
      });
    },


    isWithin24Hours() {
      const timeIn24Hours = this.$moment().add(24, 'h');
      return this.$moment(this.shift.startDateTime).isBefore(timeIn24Hours);
    },

    shiftStartFromNow() {
      const now = this.$moment();
      return this.$moment(this.shift.startDateTime).diff(now, 'h');
    },

    shiftStatusClass() {
      if (!this.isFilledShift && !this.isWithin24Hours) {
        return 'notfilled';
      }
      if (!this.isFilledShift && this.isWithin24Hours) {
        return 'warning';
      }
      return 'filled';
    },

    shiftDetailRouteName() {
      if (this.hasPassed) {
        return 'shifts_employer_detail_archive'
      }
      return 'shifts_employer_detail'
    }
  },

  methods: {
    padNumbers(value) {
      return String(value).padStart(2, '0');
    }
  }
};
</script>

<style scoped lang="scss">
.shift {
  @apply relative rounded mb-4 border border-gray-light p-6;
  width: 294px !important;
}

@screen sm {
  .shift {
    width: 320px !important;
  }
}

.shift::before {
  @apply absolute block inset-0 bg-no-repeat bg-left-bottom;

  background-image: url('~@/assets/images/noise_gray_left_@2x.png');
  background-size: 129px 20px;
  content: ' ';
  z-index: -1;
  pointer-events: none;
}

.shift::after {
  @apply absolute block inset-0 bg-no-repeat bg-right-top;

  background-image: url('~@/assets/images/noise_gray_right_@2x.png');
  background-size: 104px 50px;
  content: ' ';
  z-index: -1;
  pointer-events: none;
}

.shift--responsive {
  width: 100% !important;
}

.shift__header {
  @apply w-full rounded-lg relative mb-6;
}

.shift__header__status {
  @apply absolute top-0 right-0 rounded-full border-2 border-white text-white inline-block h-10 w-10 flex items-center justify-center;
}

.shift__header__status--filled {
  @apply bg-green text-xl;
}

.shift__header__status--notfilled {
  @apply bg-yellow text-2xl;
}

.shift__header__status--notfilled i {
  @apply relative;
  top: -1px;
}

.shift__header__status--warning {
  @apply bg-red text-lg;
}

.shift__header__status--warning i {
  @apply relative;
  top: -1px;
}

.shift__header__location {
  @apply absolute top-0 right-0 m-2 py-2 px-3 text-sm leading-tight rounded bg-white border border-gray-lighter;
}

.shift__header__location span {
  @apply text-gray-dark pl-2;
}

.shift__header__details {
  @apply absolute bottom-0 left-0;
}

.shift__header__rate {
  @apply relative inline-block bg-orange p-3 pt-2 pr-4;

  bottom: -.5rem;
}

.shift__header__rate > * {
  @apply leading-none align-top;
}

.shift__header__rate__symbol {
  @apply font-semibold text-sm;
}

.shift__header__rate__major {
  @apply relative font-semibold px-1 mb-2;

  font-size: 1.75rem;
}

.shift__header__rate__minor {
  @apply font-semibold text-sm pr-1;
}

.shift__header__rate__period {
  @apply absolute bottom-0 right-0 pb-2 pr-3 text-xs;
}

.shift__header__hours {
  @apply relative inline-block bg-black text-white py-2 px-3 pt-2;
}

.shift__header__hours > * {
  @apply leading-none align-top;
}

.shift__header__hours__amount {
  @apply relative font-semibold text-2xl px-1;
}

.shift__header__hours__unit {
  @apply text-sm pr-1;
}

.shift__body {
  @apply text-left;
}

.shift__body__title {
  font-size: 1.125rem;
}

.shift__body h3 {
  @apply text-2xl mb-2;
}

.shift__body__time {
  @apply text-orange text-sm;
}

.shift__body__location {
  margin: 0.6rem 0 1rem;
  font-size: 14px;
  color: #686868;
  span.icon {
    display: inline-block;
    margin-right: 4px;
    svg {
      width: 8px;
      fill: #686868;
    }
  }
  span.title {
    //
  }
}

.shift__body__shiftees {
  @apply flex flex-nowrap overflow-x-auto my-4;
}

.shift__body__shiftees > * {
  @apply relative border-4 border-white;

  flex: 0 0 auto;
}

.shift .icon-pin {
  @apply text-xs;
}

.shift button {
  @apply w-full;
}

</style>
