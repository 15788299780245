<template>
  <div>
    <div class="profile__header">
      <Slick ref="slider1" :options="sliderOptions_mixin">
        <template v-for="(photo,index) in employer.photos">
          <div class="profile__header__slide" :key="index" :style="`background-image: url(${photo.imageUrl});`"></div>
        </template>
      </Slick>
    </div>

    <div class="container mx-auto px-6 py-4 -mt-60 relative z-10 border-b border-gray-lighter mb-4">
      <div class="flex flex-col items-center md:items-end mx-10">
        <ImageUpload class="rounded-full h-40 w-40"
                     @fileChange="updateSelectedPhoto"
                     :initial_image="photoUrl"
                     :has-button="false"
                     :has-subtext="false"
        />
      </div>



      <div class="md:-mt-12">
        <h2 class="text-lg mb-1">{{ employer.companyName }}</h2>
        <div class="block text-gray-dark mb-6">
          <i class="icon-pin"/> {{ employer.street }} {{ employer.houseNumber }}, {{ employer.zipCode }} {{ employer.city }}<br/>
          CAO: {{ employer.cao }}
        </div>

        <ValidationObserver ref="form">
          <div class="block">
            <h3>Omschrijving bedrijf</h3>
            <InputTextarea id="description" class="text-gray-dark" :required="false" v-model="formData.description" placeholder="omschrijving"/>
          </div>
        </ValidationObserver>

        <div class="block">
          <h3>Contactgegevens</h3>

          <p class="text-gray-dark">
            Contactpersoon: {{ employer.firstName }} {{ employer.lastName }}<br/>
            Telefoonnummer: {{ employer.phone }}
          </p>
        </div>

        <div class="block">
          <h3>KvK gegevens</h3>

          <p class="text-gray-dark">
            KvK-nummer: {{ employer.kvk }}<br/>
            Vestigingsnummer: {{ employer.establishmentNumber }}
          </p>
        </div>

        <div v-if="employer.website" class="block">
          <h3>Meer info</h3>

          <p>
            <a :href="employer.website">Bezoek de website</a>
          </p>
        </div>
      </div>
    </div>

    <div class="container mx-auto p-4">
        <div class="block">
            <h3>Voeg een aantal foto’s van je bedrijf toe</h3>

            <Dropzone class="mt-2" ref="dropzone"></Dropzone>
        </div>

        <ButtonPrimary text="Werk profiel bij" @click.native="validateForm()"></ButtonPrimary>
    </div>

    <div class="container mx-auto p-4 flex flex-col items-center text-xs mb-8">
      <h3 class="text-xs mb-1">Profiel wijzigen?</h3>
      <p class="leading-loose text-gray text-center">
        Wil je gegevens in je account wijzigen, stuur My Shift team een mail met je wijzigen.<br/>
        <a :href="`mailto:${myShiftsMail}`">Wijzigingen doorgeven ({{ myShiftsMail }})</a>
      </p>

    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick';
import ImageUpload from "@/components/image/ImageUpload";
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
import Dropzone from "@/components/image/Dropzone";

export default {

  components: {
    ImageUpload,
    ButtonPrimary,
    Dropzone,
    Slick
  },

  computed: {
    employer() {
      return this.$store.getters['user/get_user']
    },
  },


  data: () => ({
    myShiftsMail: process.env.VUE_APP_MYSHIFTS_MAILTO,
    dataFetched: false,
    photoUrl: null,
    formData: {
      description: ''
    }
  }),

  methods: {
    validateForm() {
      this.$refs.form.validate().then((validated) => {
        if (validated) {
          let formData = this.$refs.dropzone.getFiles();
          formData.append('data', JSON.stringify(this.buildUserData()));

          this.$store.dispatch('general/startloading');
          this.$store.dispatch('user/user_update_action', formData).then((response) => {
            this.$store.dispatch('general/endloading');
            this.handleResponse(response);
          })
        }
      });
    },

    handleResponse(response) {
      switch (response.message) {
        case 'user_updated':
          this.successToast('Je profiel is bijgewerkt!','dashboard_employer')
          break;
        default:
          this.errorToast('Er ging iets mis, profiel kon niet worden bijgewerkt, probeer later opnieuw', 'dashboard_employer')
      }
    },




    buildUserData() {
      return {
        ...this.formData,
      }
    },

    updateSelectedPhoto(photo, photoName,) {
      let formData = new FormData();
      formData.append('photo', photo, photoName);
      formData.append('data', JSON.stringify(this.buildProfilePhotoData()));
      this.$store.dispatch('user/user_profile_photo_action', formData).then((response) => {
        this.$store.dispatch('user/user_update_profile_photo', response.data.photoUrl)
        this.photoUrl = response.data.photoUrl;
      });
    },

    buildProfilePhotoData() {
      return {
        userId: this.employer.id,
      }
    },

    async prefillUserData() {
      this.photoUrl = this.employer.imageUrl;
      this.formData.description = this.employer.description;
    },
  },

  async created() {
    this.$store.dispatch('general/startloading');
    await this.prefillUserData();
    this.dataFetched = true;
    this.$store.dispatch('general/endloading');
  },

}
</script>

<style>
.profile__header .slick-prev {
  @apply z-20;

  display: none !important;
  top: calc(50% - 1.25rem) !important;
  left: calc(50% - 480px - 1.25rem);
}

.profile__header .slick-next {
  @apply z-20;

  display: none !important;
  top: calc(50% - 1.25rem) !important;
  left: calc(50% + 480px - 1.25rem);
}

@media (min-width: 640px) {
  .profile__header .slick-prev,
  .profile__header .slick-next {
    display: block !important;
  }
}
</style>

<style scoped>
.block {
  @apply mb-8 text-xs md:text-sm leading-loose;
}

.block h3 {
  @apply mb-1 text-sm md:text-base font-semibold;
}

.profile__header {
  @apply -mt-20 mb-36 w-full relative;

  height: 260px;
}

.profile__header__slide {
  @apply w-full bg-cover bg-center rounded-lg relative mx-4;

  height: 260px;
  width: calc(100vw - 2rem) !important;
  max-width: calc(100vw - 2rem) !important;
}

@media (min-width: 640px) {
  .profile__header {
    height: 480px;
  }

  .profile__header__slide {
    height: 480px;
    width: 640px !important;
    max-width: calc(100vw - 3rem) !important;
  }
}

@media (min-width: 640px) {
  .profile__header__slide {
    height: 480px;
    width: 960px !important;
  }
}
</style>
