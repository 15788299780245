<template>
  <div class="mb-3" :class="{ 'overflow-auto': scrollable, 'max-h-32': scrollable }">
    <div :class="styleClasses" v-for="(job, index) in workexperience" :key="job.id">

      <span v-if="!job.hasNoExperience">
        {{ job.name }}, {{ job.city }}
        <span v-if="job.yearsExperience">, {{ job.yearsExperience }} {{ $t('general.years') }}</span>
        <span v-if="job.monthsExperience">, {{ job.monthsExperience }} {{ $t('general.months') }}</span>
      </span>

      <span v-if="job.hasNoExperience">{{ $t('general.no_experience') }}</span>

      <button @click="deleteWorkExperience(index)">
        <i class="icon-cross"/>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    styleHandle: {
      required: false,
      type: String,
      default: function () {
        return 'grey'
      }
    },
    jobId: {
      required: true,
      type: Number,
    },
    workexperience: {
      required: true,
      type: Array,
    },
    scrollable: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    styleClasses: ''
  }),

  methods: {
    deleteWorkExperience(index) {
      const workExperienceData = {
        jobId: this.jobId,
        index: index
      }

      this.$store.dispatch('onboardingExperience/delete_work_experience', workExperienceData);
    },

    setStyle() {
      if (this.styleHandle === 'grey') {
        this.styleClasses = 'bg-gray-lighter border border-gray-light py-3 px-4 mb-2 flex justify-between text-sm'
      }
      if (this.styleHandle === 'orange') {
        this.styleClasses = 'text-sm text-white text-left border border-white w-full my-2 flex justify-between py-3 px-4 opacity-75 hover:underline'
      }
    },
  },


  created() {
    this.setStyle();
  },


}
</script>

