import mixins from "@/config/global_components/mixins";
import LayoutComponents from "@/config/global_components/layout_components";
import UserFeedbackComponents from "@/config/global_components/user_feedback_components";
import OnboardingComponents from "@/config/global_components/onboarding_components";
import InputComponents from "@/config/global_components/input_components";
import SvgComponents from "@/config/global_components/svg_components";

export const registerGlobalComponents = (Vue) =>{
    LayoutComponents(Vue)
    OnboardingComponents(Vue)
    InputComponents(Vue)
    SvgComponents(Vue)
    UserFeedbackComponents(Vue)
    mixins(Vue)
}
