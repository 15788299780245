<template>
<button type="button" @click.prevent="submit();" class="absolute top-0 left-0 flex m-4 text-orange bg-white bg-opacity-20 rounded-full h-12 w-12 items-center justify-center">
    <i class="icon-chevron_left" />
</button>
</template>

<script>
export default {
  props: {
    prevRouteName: {
      type: String,
      required: true
    },
  },

  methods: {
    submit() {
      this.$emit('submit')
      this.navigate();
    },

    navigate() {
      this.$router.push({
        name: this.prevRouteName
      });
    }
  }
}
</script>

