<template>
  <div>
    <div class="bg-black bg-noise-top text-white">
      <div class="navigation">
        <div class="navigation__logo">
          <slot name="logo"></slot>
        </div>

        <div class="navigation__items">
          <slot name="navigation"></slot>
        </div>

        <div class="navigation__right">
          <slot name="navigation-right"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style>
body {
    @apply pb-24 md:pb-0;
}

.navigation {
    @apply flex justify-between md:border-b md:border-white md:border-opacity-20;
}

.navigation__logo {
    @apply flex-shrink mx-6 md:mx-8 py-5;
}

.navigation__items {
    @apply fixed md:relative bottom-0 bg-white md:bg-transparent w-full md:w-auto flex justify-around md:justify-start flex-grow items-center z-50 md:shadow-none;

    /* box-shadow: 0px -2px 6px 0px rgba(0,0,0,0.1); */
}

.navigation__right {
    @apply flex-shrink flex items-center;
}

</style>
