export default {

    methods: {
        successToast(text, redirectRouteName = null) {
            this.$toast(text, {
                toastClassName: 'custom-success',
            });
            this.$store.dispatch('general/endloading');
            if(redirectRouteName){
                this.$router.push({name: redirectRouteName})
            }
        },

        errorToast(text, redirectRouteName = null) {
            this.$toast(text, {
                toastClassName: 'custom-error',
            });
            this.$store.dispatch('general/endloading');
            if(redirectRouteName){
                this.$router.push({name: redirectRouteName})
            }
        }
    }
}
