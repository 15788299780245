import Vue from "vue";
import VueRouter from "vue-router";
import permissionGuard from "@/router/guards/permissionGuard"
import localisationGuard from "@/router/guards/localisationGuard"
import localStorageGuard from "@/router/guards/localStorageGuard"

import AuthenticationRouter from "./modules/authenticationRouter";
import OnboardingRouter from "./modules/onboardingRouter";
import EmployerRouter from "./modules/employerRouter";
import EmployeeRouter from "./modules/employeeRouter";
import RecruiterRouter from "./modules/recruiterRouter";
import allUsersRouter from "@/router/modules/allUsersRouter";

Vue.use(VueRouter);

const routes = [
        {path: '*', redirect: '/login'},
        ...AuthenticationRouter,
        ...OnboardingRouter,
        ...allUsersRouter,
        ...EmployerRouter,
        ...EmployeeRouter,
        ...RecruiterRouter,
    ];


export const router = new VueRouter({
    mode: 'history',
    routes,
});

localStorageGuard(router);
permissionGuard(router);
localisationGuard(router);

