<template>
<div>
    <div class="black-bar"></div>
    <div class="flex flex-col items-center text-center p-4">
        <a class="no-underline mt-2 mb-4" href="/">
            <LogoBlackSvg />
        </a>

        <div class="container md:w-1/2">
            <h1>{{ $t('password_reset.title') }}</h1>
            <p class="lead">{{ $t('password_reset.subtitle') }}</p>

            <InputPassword id="password" :placeholder="$t('password_reset.new_password_placeholder')" :required="true" v-model="newPassword" />
            <div class="alert" v-if="loginFeedback">{{ loginFeedback }}</div>
            <button type="button" class="button button--primary w-1/2" @click="resetPassword">
                {{ $t('password_reset.button') }}
            </button>
        </div>
    </div>

    <div class="px-4 text-sm md:text-center">
        <p>
            <router-link :to="{ name: 'login' }">{{ $t('general.login') }}</router-link>
        </p>
        <p>
            <router-link :to="{ name: 'onboarding_options' }">{{ $t('login.create_account') }}</router-link>
        </p>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        newPassword: '',
        code: '',
        id: '',

        loginFeedback: false
    }),

    methods: {
        resetPassword() {
            this.$store.dispatch('general/startloading');
            this.$store.dispatch('auth/password_reset_action', {
                'newPassword': this.newPassword,
                'code': this.code,
                'id': this.id,
            }).then((response) => {
                this.handleResponse(response)
            })
        },

        getUrlParams() {
            this.code = this.$helpers.getUrlParam('code');
            this.id = this.$helpers.getUrlParam('id');
        },

        handleResponse(response) {

            if (response.message === 'password_reset') {
                this.loginFeedback = this.$t('password_reset.feedback.password_reset');
                setTimeout(() => {
                    this.$router.push({
                        name: 'login'
                    });
                }, 3000)
            }

            if (response.message === 'password_not_reset') {
                this.loginFeedback = this.$t('password_reset.feedback.password_not_reset');
            }

            if (response.message === 'invalid_reset_code') {
                this.loginFeedback = this.$t('password_reset.feedback.invalid_reset_code');
            }

            this.$store.dispatch('general/endloading');
        },

    },

    created() {
        this.getUrlParams();
    }
};
</script>
