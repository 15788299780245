<template>
<div>
    <!-- Overlay background -->
    <div @click="closeOverlay()" class="fixed inset-0 bg-black opacity-25 z-30"></div>

    <!-- Overlay close icon -->
    <div @click="closeOverlay()" class="fixed top-0 left-0 right-0 p-4 text-white text-center z-40">
        <i class="icon-cross" />
    </div>

    <!-- Overlay container -->
    <div class="fixed inset-0 top-auto mt-32 rounded-t-2xl p-6 bg-white m-auto container max-w-xl overflow-auto z-40" style="max-height: 90%;z-index: 1000">
        <slot></slot>
    </div>
</div>
</template>

<script>
export default {
    mounted() {
        document.body.classList.add('no-scroll');
    },

    destroyed() {
        document.body.classList.remove('no-scroll');
    },

    methods: {
        closeOverlay() {
            this.$emit('closeOverlay');
        },
    },
}
</script>
