const getDefaultState = () => {
    return {
        userGroup: '',
        onboardingHash: '',
        recruiterCode: '',

        //info for calendly
        calendlyInfo:{
            province: '',
            jobGroup: ''
        }

    }
}

const state = getDefaultState();

const mutations = {
    RESET_STATE_ONBOARDING_GENERAL(state) {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(state, getDefaultState())
    },

    SET_INTAKE_USER_GROUP(state, userGroup) {
        state.userGroup = userGroup;
    },

    SET_ONBOARDING_HASH(state, onboardingHash) {
        state.onboardingHash = onboardingHash;
    },

    SET_RECRUITER_CODE(state, recruiterCode) {
        state.recruiterCode = recruiterCode;
    },

    SET_CALENDLY_INFO(state, calendlyInfo) {
        state.calendlyInfo = calendlyInfo;
    },
}

const actions = {
    reset_state_onboarding_general: ({commit}) => {
        commit('RESET_STATE_ONBOARDING_GENERAL')
    },

    set_intake_user_group: ({commit}, userGroup) => {
        commit('SET_INTAKE_USER_GROUP', userGroup)
    },

    set_onboarding_hash: ({commit}, onboardingHash) => {
        commit('SET_ONBOARDING_HASH', onboardingHash)
    },

    set_recruiter_code: ({commit}, recruiterCode) => {
        commit('SET_RECRUITER_CODE', recruiterCode)
    },

    set_calendly_info: ({commit}, calendlyInfo) => {
        commit('SET_CALENDLY_INFO', calendlyInfo)
    },

}


const getters = {

    get_intake_user_group: (state) => {
        return state.userGroup;
    },

    get_onboarding_hash: (state) => {
        return state.onboardingHash;
    },

    get_recruiter_code: (state) => {
        return state.recruiterCode;
    },

    get_calendly_info: (state) => {
        return state.calendlyInfo
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}



