<template>
  <div>
    <PageTitle
      title="Meet the team"
      subtitle="Neem contact op met My Shifts"
      :centered="true"
    />

    <div class="bg-white">
      <div class="container mx-auto px-4">
        <div class="mb-4">
          <div class="grid md:grid-cols-2 gap-x-8">
            <div class="p-4 bg-gray-lighter border-t-2 border-orange mb-4 md:mb-8">
              <p class="text-sm"><strong>Openingstijden</strong></p><ul>
                <li>Ma t/m vr van 09:00 tot 18:00</li>
                <li>Za en Zo gesloten</li>
              </ul>
            </div>
            <div class="p-4 bg-gray-lighter border-t-2 border-orange mb-4 md:mb-8">
              <p class="text-sm"><strong>Contactgegevens:</strong></p>
              <ul>
                <li>Algemene info: <a href="mailto:team@myshifts.nl">team@myshifts.nl</a></li>
                <li>Facturatie ZZP: <a href="mailto:crediteuren@myshifts.nl">crediteuren@myshifts.nl</a></li>
                <li>Facturatie bedrijven: <a href="mailto:debiteuren@myshifts.nl">debiteuren@myshifts.nl</a></li>
                <li>Telefoon kantoor: 077-2030060</li>
                <li>WhatsApp: +31643499625</li>
              </ul>
            </div>
          </div>
        </div>
        <Whatsapp :inline="true" />
      </div>
    </div>
  </div>
</template>

<script>
import Whatsapp from "../components/buttons/Whatsapp.vue";

export default {
  components: {
    Whatsapp,
  },
};
</script>

<style lang="scss" scoped>

ul.group li:before {
  display: none;
}

.contactOptions {
}
</style>