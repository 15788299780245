<template>
   <span class="flag-icon" :class="`flag-icon-${code} flag-icon--${size}`"></span>
</template>

<script>
export default {
    props: {
        code: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: 'normal',
        }
    }
}
</script>

<style scoped>
    .flag-icon {
        @apply w-8 h-8 rounded-full bg-cover;

        line-height: 2rem;
    }

    .flag-icon--small {
        @apply w-6 h-6;
    }
</style>