import constants from "@/settings/constants";
import DashboardRecruiter from "@/views/DashboardRecruiter";

const RECRUITER = constants.USERGROUP_RECRUITER;


export default [
    {
        path: "/dashboard/recruiter",
        name: "dashboard_recruiter",
        component: DashboardRecruiter,
        meta: {
            requiresAuth: true,
            hasPermission: [RECRUITER],
            layout: 'no-navigation'
        }
    },
]
