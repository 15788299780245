<template>

  <div v-if="dataFetched">
    <PageTitle title="Dashboard">
      <template v-slot:pageTitleRight>
        <button-primary bg-color="black" route-name="shift_create" text="+ nieuwe shift" class="md:ml-auto" ></button-primary>
      </template>
    </PageTitle>

    <div class="px-6">
      <h2 class="text-2xl font-normal">Openstaande shifts</h2>
      <p v-if="!shiftsOpen.length">Geen openstaande shifts beschikbaar..</p>

      <div class="w-full mt-4 text-left relative mb-8" v-if="shiftsOpen.length">
        <Slick ref="slick" :options="slickOptions_mixin" class="slick-slider--no-margin">
          <template v-for="(shift,index) in shiftsOpen">
            <div :key="index">
              <EmployerShiftItem :shift="shift"/>
            </div>
          </template>
        </Slick>
      </div>

      <div class="mt-8">
        <h2 class="text-2xl font-normal">Aankomende shifts (7 dagen)</h2>
        <p v-if="!shiftsInComingDays.length">Geen aankomende shifts beschikbaar..</p>
      </div>
      <div class="w-full mt-4 text-left relative" v-if="shiftsInComingDays.length">
        <Slick ref="slick" :options="slickOptions_mixin" class="slick-slider--no-margin">
          <template v-for="(shift,index) in shiftsInComingDays">
            <div :key="index">
              <EmployerShiftItem :shift="shift"/>
            </div>
          </template>
        </Slick>
      </div>

    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick';
import EmployerShiftItem from '@/components/shifts/EmployerShiftItem';
import ButtonPrimary from "@/components/buttons/ButtonPrimary";

export default {

  components: {
    Slick,
    EmployerShiftItem,
    ButtonPrimary,
  },

  data: () => ({
    dataFetched: false,
  }),

  computed: {
    shiftsOpen: function () {
      return this.$store.getters['shift/get_employer_open_shifts'];
    },

    shiftsInComingDays: function () {
      return this.$store.getters['shift/get_employer_shifts_coming_days'];
    },
  },

  methods: {
    checkIfFirstLogin() {
      const isFirstLogin = this.$helpers.getUrlParam('firstLogin');

      if (isFirstLogin){
        this.goToProfileOnFirstLoginAlert();
      }
    },

    goToProfileOnFirstLoginAlert() {
      return this.$swal({
        title: "Vul uw profiel aan",
        text: "Uw profiel is nog niet compleet, klik op onderstaande knop om naar uw profiel te gaan.",
        confirmButtonText: "Ga naar profiel",
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#FF5F00',
      }).then(() => {
        this.$router.push({name: 'profile_employer'});
      })
    },
  },

  async created() {
    this.$store.dispatch('general/startloading');
    await this.$store.dispatch('shift/fetch_employer_shifts_action');
    this.$store.dispatch('general/endloading');
    this.dataFetched = true;

    this.checkIfFirstLogin()
  },

}
</script>
