export default {

    methods: {
        userGroup_mixin(groupId) {
            switch (groupId) {
                case this.$constants.USERGROUP_SELF_EMPLOYED:
                    return "ZZP'er";
                case this.$constants.USERGROUP_TEMPORARY_EMPLOYED:
                    return 'Uitzendkracht';
                case this.$constants.USERGROUP_EMPLOYER:
                    return 'Employer';
            }
        },
    }
}
