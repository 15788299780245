export default {
    methods: {
        timeLeftToStart(shift) {
            const now = this.$moment();
            const hoursLeft = this.$moment(shift.startDateTime).diff(now, 'h');
            const daysLeft = this.$moment(shift.startDateTime).diff(now, 'd');

            if (hoursLeft < 0) {
                return  'in_the_past';
            }

            if (hoursLeft < 24) {
                return `${hoursLeft} uur`;
            }
            return `${daysLeft} dag(en)`;
        },
    },

    computed: {
        cancelDateHasPassed() {
            return this.$moment().isAfter(this.shift.maxCancelDate);
        },

        isFilledShift() {
            return this.shift.amountEmployeesAccepted >= this.shift.amountEmployees;
        },

        allowedToRegister() {
            const status = this.shift.registration.status;
            return status === 'unsigned';
        },

        shiftRedStatuses() {
            const status = this.shift.registration.status;
            return status === 'declined' || status === 'unsubscribed' || status === 'canceled' || status === 'unsubscribedByEmployer' || status === 'canceledByEmployer';
        },
    }
}
