<template>
  <div>
    <component :is="navigation"/>
    <slot></slot>
  </div>
</template>

<script>
import PlatformNavigationEmployer from "@/components/nav/PlatformNavigationEmployer";
import PlatformNavigationEmployees from "@/components/nav/PlatformNavigationEmployees";
import constants from "@/settings/constants";

export default {
  components: {
    PlatformNavigationEmployer,
    PlatformNavigationEmployees
  },

  computed: {
    navigation() {
      const userGroup = this.$store.getters['user/get_user'].group
      let userType;

      switch (userGroup) {
        case constants.USERGROUP_TEMPORARY_EMPLOYED:
          userType = 'employees'
          break
        case constants.USERGROUP_SELF_EMPLOYED:
          userType = 'employees'
          break
        case constants.USERGROUP_EMPLOYER:
          userType = 'employer'
          break
      }

      return 'platform-navigation-' + userType;
    }
  },
}
</script>
