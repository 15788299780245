<template>
  <div class="form-group">
    <label class="inline-flex items-center mt-3">
      <div class="bg-white border-2 rounded border-gray-light w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-gray">
        <input type="checkbox" class="opacity-0 absolute"
               :id="id"
               :checked="isChecked"
               @input="$emit('input', $event.target.checked)"
        >
        <!-- <svg class="fill-current hidden w-4 h-4 text-orange pointer-events-none" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg> -->
        <i class="icon-checkmark fill-current hidden text-sm text-orange pointer-events-none -ml-1"/>
      </div>
      <div class="select-none">
        <slot></slot>
      </div>
    </label>
  </div>
</template>

<style scoped>
input:checked + i {
  display: block;
}
</style>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },

    required: {
      required: false,
      type: Boolean,
    },

    checked: {
      required: false,
      type: String,
    },

    isChecked: {
      required: false,
      type: Boolean,
    },

  },
}
</script>
