import {router} from "@/router/router";

export default class ShiftService {
    shift;




    constructor(shift) {
        this.shift = shift;
    }




    archiveRedirectCheck(userType) {
        if (this.shift.hasPassed) {
            router.push({name: `shifts_${userType}_detail_archive`, params: {id: this.shift.id}})
        }
    }
}
