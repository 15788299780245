<template>
<div>
    <template v-if="label">
        <label class="font-bold text-center block mb-2 text-sm" :for="id">{{label}} <span v-if="required">*</span></label>
    </template>

    <ValidationProvider :rules="required ? 'required' : ''" v-slot="{ errors }">
        <div class="flex items-start mb-4">
            <button type="button" @click="$emit('reduce')" class="flex rounded border border-gray-light text-orange h-12 px-2 items-center text-center text-sm md:text-base">
                <i class="icon-minus" />
            </button>
            <input readonly type="number" class="form-field form-field--auto-size mx-1 text-center"
                 :id="id"
                 :value="numericValue"
                 :placeholder="placeholder"
                 @input="$emit('input', $event.target.value)"
            />
            <button type="button" @click="$emit('increment')"  class="flex rounded border border-gray-light text-orange h-12 px-2 items-center text-center text-sm md:text-base">
                <i class="icon-plus" />
            </button>
        </div>

        <div class="validate-error">{{ errors[0] }}</div>
    </ValidationProvider>

</div>
</template>

<script>
export default {
    props: {
        id: {
            required: true,
            type: String,
        },

        label: {
            required: false,
            type: String,
        },

        placeholder: {
            required: false,
            type: String,
        },

        required: {
            required: false,
            type: Boolean,
        },

        value: {
            required: false,
            type: [String, Number],
        },
    },

    computed: {
        numericValue() {
            const numeric = parseInt(this.value);

            return isNaN(numeric) ? 0 : numeric;
        }
    },
}
</script>

<style computed>
.form-field--auto-size {
    @apply w-16 md:w-full;
}
</style>
