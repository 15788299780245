import axios from "axios";
import {stringify} from "qs";
import tokenInterceptor from "@/config/axios/interceptors/tokenInterceptor";
import csrfTokenInterceptor from "@/config/axios/interceptors/csrfTokenInterceptor";





const baseDomain = process.env.VUE_APP_API_URL;
const baseURL = `${baseDomain}`;
// const csrfToken = store.getters["auth/get_csrf_token"];

// let variables = {
//     ...(csrfToken.csrf && { [csrfToken.csrf.name]: csrfToken.csrf.value }),
// };
// console.log('VARS', variables);

const axiosConfig = axios.create({
    withCredentials: true,
    baseURL,
    transformRequest: [
        function (data) {
            return stringify(data)
        },
    ],
});


csrfTokenInterceptor(axiosConfig);
tokenInterceptor(axiosConfig);

export default axiosConfig;
