<template>
  <div>

    <div class="top-0 right-0 flex flex-col justify-end m-6 w-1/2 md:w-1/4" :class="positioning">
      <button type="button" class="select-none text-sm text-gray flex items-center py-2 mb-2 self-end" @click="showOptions = !showOptions">
        <span class="mr-1">{{ languageCode.toUpperCase() }}</span>
        <i class="icon-chevron_down w-3"/>
      </button>

      <div v-click-outside="hideOptions" v-if="showOptions"
           class="flex flex-col rounded-xl border border-gray-light bg-white py-1 text-black"
           :class="positioning === 'relative' ? 'absolute right-0 top-full' : ''"
      >
        <button v-for="(language, languageCode) in languages" :key="languageCode" type="button"
                class="select-none text-sm text-left leading-none p-3 hover:bg-gray-lighter"
                @click="selectLanguage(languageCode)"
        >
          {{ language }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LanguageSwitch",

  props: {
      positioning: {
          type: String,
          default: 'absolute',
      },
  },

  data: () => ({
    languageCode: 'en',
    showOptions: false,
  }),

  computed: {
    languages: function (){
      return {
        'en': this.$t('general.languages.english'),
        'nl': this.$t('general.languages.dutch'),
      }
    },


  },

  methods: {
    selectLanguage(languageCode) {
      // Set language code for the view
      this.languageCode = languageCode;

      // Set locale for i18n plugin
      this.$i18n.locale = this.languageCode;

      // Hide show
      this.showOptions = false;
    },

    hideOptions() {
      this.showOptions = false;
    }
  },

  created() {
    this.languageCode = this.$i18n.locale;
  }
}
</script>

<style scoped>
.icon-chevron_down {
  font-size: .4rem;
}
</style>
