<template>
<div>
    <div class="bg-black bg-noise-top text-white py-4 pb-8">
        <div class="flex flex-col items-center text-center p-4">
            <a class="no-underline mt-2 mb-4" href="/">
              <LogoBlackSvg/>
            </a>

            <h1 v-html="$t('onboarding.experience.header.title', {
                firstName: '<span class=\'text-orange\'>' + firstName + '</span>',
            })"></h1>

            <p class="lead">{{ $t('onboarding.experience.header.subtitle') }}</p>
        </div>
    </div>
    <div class="black-bar mb-4"></div>

    <div class="container mx-auto p-4 -mt-16">
        <div class="grid grid-cols-4 gap-4 cursor-pointer">
            
            <template v-for="(jobGroup, index) in jobGroups">            
                <button :key="index" type="button" class="paper col-span-4 md:col-span-2" @click="navigate(jobGroup.id)">
                    <div class="relative w-full">

                        <img v-if="jobGroup.image" class="w-full rounded inline-block" :src="jobGroup.image" />

                        <h1 class="absolute text-4xl text-center m-0 inset-0 flex items-center justify-center text-white uppercase">
                            {{ jobGroup.title }}
                        </h1>

                        <span class="rounded-full bg-black absolute bottom-0 left-0 right-0 mx-auto h-12 w-12 items-center justify-center flex -mb-6 text-white">
                            <i class="icon-chevron_right" />
                        </span>
                    </div>

                    <p class="text-sm p-4 pt-8 text-center text-gray">
                        <span class="text-gray-darker">{{ $t('onboarding.experience.get_started_as') }}</span>
                        {{ jobGroup.description }}
                    </p>
                </button>
            </template>
        </div>

        <div class="text-center text-gray mb-8">
          {{ $t('onboarding.experience.select_jobgroup') }}
        </div>
    </div>
</div>
</template>

<script>
export default {
  computed: {
    firstName() {
        return this.$store.getters['onboardingExperience/get_intake_user_info'].firstName || '...';
    },
    jobGroups() {
        return this.$store.state.jobGroup.jobGroupsList;
    }
  },

  methods: {
    navigate(jobGroup) {
      this.$store.dispatch('onboardingExperience/set_job_group', jobGroup).then(() => {
        this.$router.push({name: 'onboarding_experience_education'});
      })
    },
    getAllJobGroups() {
        this.$store.dispatch('jobGroup/fetch_all_jobGroups_action');
    }
  },
  created() {
      this.getAllJobGroups();
  }
}
</script>

<style scoped>
    .paper {
        @apply z-10 bg-white border border-gray-light mb-2 rounded p-2 hover:bg-gray-lighter flex-shrink-0;
    }
</style>

