<template>
   <div class="border-b border-gray-light">
       <div class="py-4 px-1 flex items-center cursor-pointer" @click="isOpen = !isOpen">
           <i class="icon text-xs text-orange mr-3" :class="{ 'icon-plus': !isOpen, 'icon-minus': isOpen }" />
           <h3 class="mb-0">{{ title }}</h3>
       </div>

       <div v-if="isOpen" class="ml-8 mb-4 text-sm text-gray" @click="isOpen = false">
           <slot></slot>
       </div>
   </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
    },

    data: () => ({
        isOpen: false,
    }),
}
</script>

<style scoped>

</style>