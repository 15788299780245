import helpers from '@/helpers/helpers'

export default (Vue) => {
    const plugin = {
        install() {
            Vue.helpers = helpers
            Vue.prototype.$helpers = helpers
        }
    }

    Vue.use(plugin)
}
