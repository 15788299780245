const getDefaultState = () => {
    return {
        profile: {
            photoUrl: null,
            description: '',

            street: '',
            houseNumber: '',
            zipCode: '',
            province: '',
            city: '',

            driversLicence: '',
            selectedLanguages: [],
            otherLanguages: ''
        },
    }
}

const state = getDefaultState();

const mutations = {
    RESET_STATE_ONBOARDING_PROFILE(state) {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(state, getDefaultState())
    },

    SET_PROFILE_PHOTO(state, photoUrl) {
        state.profile.photoUrl = photoUrl;
    },

    SET_PROFILE_DESCRIPTION(state, description) {
        state.profile.description = description;
    },

    SET_PROFILE_STREET(state, street) {
        state.profile.street = street;
    },

    SET_PROFILE_HOUSENUMBER(state, houseNumber) {
        state.profile.houseNumber = houseNumber;
    },

    SET_PROFILE_CITY(state, city) {
        state.profile.city = city;
    },

    SET_PROFILE_ZIPCODE(state, zipCode) {
        state.profile.zipCode = zipCode;
    },

    SET_PROFILE_PROVINCE(state, province) {
        state.profile.province = province;
    },


    SET_PROFILE_SELECTED_LANGUAGES(state, selectedLanguages) {
        state.profile.selectedLanguages = selectedLanguages;
    },

    SET_PROFILE_OTHER_LANGUAGES(state, otherLanguages) {
        state.profile.otherLanguages = otherLanguages;
    },

    SET_PROFILE_DRIVERS_LICENCE(state, driversLicence) {
        state.profile.driversLicence = driversLicence;
    },
}

const actions = {
    reset_state_onboarding_profile: ({commit}) => {
        commit('RESET_STATE_ONBOARDING_PROFILE')
    },

    set_profile_photo: ({commit}, photoUrl) => {
        commit('SET_PROFILE_PHOTO', photoUrl)
    },

    set_profile_description: ({commit}, description) => {
        commit('SET_PROFILE_DESCRIPTION', description)
    },

    set_profile_street: ({commit}, street) => {
        commit('SET_PROFILE_STREET', street)
    },

    set_profile_houseNumber: ({commit}, houseNumber) => {
        commit('SET_PROFILE_HOUSENUMBER', houseNumber)
    },

    set_profile_city: ({commit}, city) => {
        commit('SET_PROFILE_CITY', city)
    },

    set_profile_zipCode: ({commit}, zipCode) => {
        commit('SET_PROFILE_ZIPCODE', zipCode)
    },

    set_profile_province: ({commit}, province) => {
        commit('SET_PROFILE_PROVINCE', province)
    },

    set_profile_selectedLanguages: ({commit}, selectedLanguages) => {
        commit('SET_PROFILE_SELECTED_LANGUAGES', selectedLanguages)
    },

    set_profile_otherLanguages: ({commit}, otherLanguages) => {

        commit('SET_PROFILE_OTHER_LANGUAGES', otherLanguages)
    },

    set_profile_driversLicence: ({commit}, driversLicence) => {
        commit('SET_PROFILE_DRIVERS_LICENCE', driversLicence)
    },
}


const getters = {

    get_full_profile: (state) => {
        return state.profile;
    },

    get_profile_photo: (state) => {
        return state.profile.photoUrl;
    },

    get_profile_description: (state) => {
        return state.profile.description;
    },

    get_profile_street: (state) => {
        return state.profile.street;
    },

    get_profile_houseNumber: (state) => {
        return state.profile.houseNumber;
    },

    get_profile_city: (state) => {
        return state.profile.city;
    },

    get_profile_zipCode: (state) => {
        return state.profile.zipCode;
    },

    get_profile_province: (state) => {
        return state.profile.province;
    },

    get_profile_selectedLanguages: (state) => {
        return state.profile.selectedLanguages;
    },

    get_profile_otherLanguages: (state) => {
        return state.profile.otherLanguages;
    },

    get_profile_driversLicence: (state) => {
        return state.profile.driversLicence;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}



