import repository from "@/repositories/repository";
const userRepository = repository.get("user");

const state = () => ({
    user: null
})

const mutations = {
    SET_USER(state, user) {
        state.user = user;
    },
    SET_PROFILE_IMAGE_URL(state, imageUrl) {
        state.user.imageUrl = imageUrl;
    },
    SET_AGREED_TO_CONTRACTS(state, payload) {
        state.user.agreed_to_contracts = payload;
    },
}

const actions = {
    user_create_action: async (state, userData) => {
        return await userRepository.create(userData)
    },


    user_update_action: async ({commit}, userData) => {
       return await userRepository.update(userData).then((response) => {
            commit('SET_USER', response.data.user_info);
            return response.data;
        })
    },

    user_agreed_to_contracts_action(context, payload) {
        context.commit("SET_AGREED_TO_CONTRACTS", payload);
    },

    user_get_by_id_action: async (state, userId) => {
        return await userRepository.getById(userId).then((response) => {
            return response.data;
        })
    },

    user_fill_profile_action: async (state, userData) => {
        return userRepository.fillProfile(userData)
    },

    user_profile_photo_action: async (state, userData) => {
        return userRepository.profilePhoto(userData)
    },

    user_update_profile_photo: ({commit}, imageUrl) => {
        commit('SET_PROFILE_IMAGE_URL', imageUrl);
    },
}

const getters = {
    get_user: (state) => {
        return state.user;
    },
};


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
