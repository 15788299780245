import {redirectHomeForUserType} from "@/services/authentication/authService";
import store from "@/store/store";
import helpers from "@/helpers/helpers";


export default (router) => {
    router.beforeEach((to, from, next) => {
        const loggedIn = localStorage.getItem('user');

        //IF USER IS LOGGED IN
        if (loggedIn) {
            const userGroup = store.getters['user/get_user'].group;
            const redirectRoute = redirectHomeForUserType(userGroup);

            //IF LOGGED AND GOING TO LOGIN PAGE REDIRECT TO HOME
            if (to.name === 'login') {
                next({name: redirectRoute});
            }

            //IF REQUIRES AUTH
            if (to.meta.requiresAuth) {
                loggedAuthRequired(userGroup, redirectRoute, to, next)
            }

            //IF DOESNT REQUIRES AUTH
            if (!to.meta.requiresAuth) {
                next();
            }

            /* CONTRACT AGREEMENT START - CONTRACT AGREEMENT START - CONTRACT AGREEMENT START */
            const user = store.getters['user/get_user'];

            // 1 - UITZENDKRACHT
            if (user.group == 1 && !user.agreed_to_contracts) {
                console.log(`%c${user.email} (UZK) has not yet agreed to contract`, 'color: red; font-weight: bold;');
                next({ path: '/contract_agreements/uitzendkracht'});
            }

            // 6 - EMPLOYER
            if (user.group == 6 && !user.agreed_to_contracts) {
                console.log(`%c${user.email} (EMP) has not yet agreed to contract`, 'color: red; font-weight: bold;');
                next({ path: '/contract_agreements/werkgever'});
            }

            // 2 - ZZP
            if (user.group == 2 && !user.agreed_to_contracts) {
                console.log(`%c${user.email} (ZZP) has not yet agreed to contract`, 'color: red; font-weight: bold;');
                next({ path: '/contract_agreements/zzp'});
            }
            /* CONTRACT AGREEMENT END - CONTRACT AGREEMENT END - CONTRACT AGREEMENT END */
        }

        //IF USER IS NOT LOGGED IN
        if (!loggedIn) {

            //IF REQUIRES AUTH
            if (to.meta.requiresAuth){
                const hasRedirect = helpers.getUrlParam('redirectFromLogin');

                if( hasRedirect && to.name !== 'login' ){
                    next({ path: `/login?redirectFromLogin=${hasRedirect}`});
                }else{
                    next({name: 'login'});
                }
            }

            //IF DOESNT REQUIRES AUTH
            if (!to.meta.requiresAuth){
                next();
            }
        }
    })
}

const loggedAuthRequired = (userGroup, redirectRoute, to, next) =>{
     (to.meta.hasPermission.includes(userGroup)) ?  next() : next({name: redirectRoute});
};

