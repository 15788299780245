
<template>
  <div>
    <div class="filters relative pr-6 mb-6">
      <div class="flex flex-row alignt-top justify-between">
        <div v-if="activeFilters" class="filters__container mr-3">
          <div class="inner-container">
            <template v-for="(value, propertyName, index) in activeFilters">
              <template v-if="value">
                <div class="filters__filter inline-block px-3 py-2 cursor-pointer hover:bg-gray-lighter text-gray-dark border border-gray-light rounded-full text-xs mr-2 mb-2" :key="index" @click="removeFilter(propertyName)">
                  {{ displayFilterTitle(propertyName, value) }}<i class="icon-cross text-orange align-middle"></i>
                </div>
              </template>
            </template>
          </div>
        </div>
        <div>
          <button type="button" class="filters__button flex z-40 bg-black text-orange cursor-pointer justify-center rounded-full py-2 px-4" @click="overlay = true;">
            <i class="icon-filter"></i>
            <span class="hidden md:block">Filteren</span>
          </button>
        </div>
      </div>
    </div>
    
    <!-- Overlay -->
    <div v-if="overlay">
      <Overlay @closeOverlay="overlay = false">
        <template v-if="filterOptions.distance.length > 1 && (user.group == 1 || user.group == 2)">
          <h3>Afstand</h3>
          <div class="form-group mb-4">
            <div class="form-select">
              <select class="form-field" v-model="activeFilters.distance">
                <option v-for="(filter, index) in filterOptions.distance" :key="index" :value="filter.value">{{ filter.title }}</option>
              </select>
            </div>
          </div>
        </template>

        <template v-if="filterOptions.job.length > 1">
          <h3>Functie</h3>
          <div class="form-group mb-4">
            <div class="form-select">
              <select class="form-field" v-model="activeFilters.job">
                <option v-for="(filter, index) in filterOptions.job" :key="index" :value="filter.id">{{ filter.title }}</option>
              </select>
            </div>
          </div>
        </template>

        <!-- <template v-if="filterOptions.organisation.length > 1">
          <h3>Organisatie</h3>
          <div class="form-group mb-4">
            <div class="form-select">
              <select class="form-field" v-model="activeFilters.organisation">
                <option v-for="(filter, index) in filterOptions.organisation" :key="index" :value="filter.id">{{ filter.title }}</option>
              </select>
            </div>
          </div>
        </template> -->

        <template v-if="filterOptions.location.length > 1">
          <h3>Locatie</h3>
          <div class="form-group mb-4">
            <div class="form-select">
              <select class="form-field" v-model="activeFilters.location">
                <option v-for="(filter, index) in filterOptions.location" :key="index" :value="filter.id">{{ filter.title }}</option>
              </select>
            </div>
          </div>
        </template>

        <h3>Periode</h3>
        <DateTimeField :time-enabled="false" :initialDate="activeFilters.minDate" :disabledDatesUpTo="$helpers.currentDateInIso()" @dateChanged="activeFilters.minDate = $event;" />
        <DateTimeField :time-enabled="false" :initialDate="activeFilters.maxDate" :disabledDatesUpTo="$helpers.currentDateInIso()" @dateChanged="activeFilters.maxDate = $event;" />

        <div class="my-6 w-full">
          <ButtonPrimary text="Filteren" @click.native="sendFilterRequest()" extraClasses="w-full"></ButtonPrimary>
        </div>
      </Overlay>
    </div>
  </div>
</template>


<script>
import Overlay from '@/components/Overlay';
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
import DateTimeField from "@/components/date-time/DateTimeField";
import organisationRepository from "@/repositories/organisationRepository";
import moment from 'moment';
import _ from 'lodash';

export default {
  components: {
    ButtonPrimary,
    Overlay,
    DateTimeField
  },

  data() {
    return {
      filterOptions: {
        distance: [
          { title: 'Kies een afstand', value: null },
          { title: 'tot 25km', value: 25 },
          { title: 'tot 50km', value: 50 },
          { title: 'tot 75km', value: 75 },
          { title: 'tot 100km', value: 100 }
        ],
        job: [
          { title: 'Kies een functie', value: null, id: null }
        ],
        organisation: [
          { title: 'Kies een organisatie', value: null, id: null }
        ],
        location: [
          { title: 'Alle locaties', value: null, id: null }
        ]
      },
      minDate: moment().toDate().toISOString(),
      maxDate: moment().add(4, 'weeks').toDate().toISOString(),
      overlay: false,
    };
  },

  computed: {
    user: function() {
      return this.$store.state.user.user;
    },
    activeFilters: function() {
      return this.$store.state.shift.filters
    }
  },

  watch: {
    activeFilters: function(newValue) {
      this.$store.dispatch('shift/set_filters', newValue);
      this.activeFilters = this.$store.getters['shift/get_filters'];
    }
  },

  methods: {
    async setInitialFilterOptions() {     
      // Filter options for Employees
      if (this.user.group == 1 || this.user.group == 2) {
        // Jobs
        let initialJobs = this.filterOptions.job;
        const additionalJobs = this.user.jobsMain;
        const totalJobs = initialJobs.concat(additionalJobs);
        this.filterOptions.job = totalJobs;

        // Organisations
        let initialOrganisations = this.filterOptions.organisation;
        const additionalOrganisations = await organisationRepository.getAll();
        const totalOrganisations = initialOrganisations.concat(additionalOrganisations);
        this.filterOptions.organisation = totalOrganisations;
      }

      // Filter options for Employers
      if (this.user.group == 6) {
        let initialLocations = this.filterOptions.location;
        const additionalLocations = await organisationRepository.get();
        const totalLocations = initialLocations.concat(additionalLocations.locations);
        this.filterOptions.location = totalLocations;
      }
    },

    removeFilter: function(propertyName) {
      this.setFilter(propertyName, null);
      this.sendFilterRequest();
    },

    setFilter: function(propertyName, value) {
      this.activeFilters[propertyName] = value;
    },

    displayFilterTitle(propertyName, value) {
      let text = `${propertyName} = ${value}`;
      if (propertyName == 'distance') {
        text = `afstand tot ${value}km`;
      }
      if (propertyName == 'job') {
        const obj = _.find(this.filterOptions.job, function(o) { return o.id == value; });
        if (obj) { text = `${obj.title}` }
      }
      if (propertyName == 'organisation') {
        const obj = _.find(this.filterOptions.organisation, function(o) { return o.id == value; });
        if (obj) { text = `${obj.title}` }
      }
      if (propertyName == 'location') {
        const obj = _.find(this.filterOptions.location, function(o) { return o.id == value; });
        if (obj) { text = `${obj.title}` }
      }
      if (propertyName == 'minDate') {
        text = `van ${this.toNiceDate(value)}`
      }
      if (propertyName == 'maxDate') {
        text = `tot ${this.toNiceDate(value)}`
      }
      return text;
    },

    capitalizeFirst(s) {
      return s[0].toUpperCase() + s.slice(1);
    },

    sendFilterRequest() {
      this.$emit('filterRequest');
      this.overlay = false;
    },

    toNiceDate: function(date) {
      return moment(date).format('DD MMMM YYYY')
    }
  },
  
  async mounted() {
    await this.setInitialFilterOptions();
  }
};
</script>

<style lang="scss" scoped>
.filters {
  position: relative;
}

.filters__container {
  position: relative;
  overflow: hidden;
  width: 100%;
  .inner-container {
    white-space: nowrap;
    overflow-x: auto;
    cursor: grab;
    padding-right: 64px;
  }
  &:after {
    content: '';
    width: 64px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  }
}

.filters__filter {
  line-height: 1;
  display: inline-block;
  // font-size: 10px;
  margin-right: 4px;
  padding-left: 1em;
  padding-right: 1em;
  i {
    margin-left: 0.5em;
    vertical-align: middle;
  }
}

.filters__filter:first-child {
  @apply ml-6;
}

.filters__filter i {
  font-size: .5rem;
}

.inner-container::-webkit-scrollbar { height: 0 !important; }
.inner-container { overflow: -moz-scrollbars-none; }
.inner-container { -ms-overflow-style: none; }
</style>

