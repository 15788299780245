import repository from "@/repositories/repository";
const jobRepository = repository.get("job");

const state = () => ({
    jobsList: [], //deprecated

    jobs:[],
    jobsFromJobGroup:[],
})

const mutations = {
    SET_JOBS_LIST(state, jobsList) {
        state.jobsList = jobsList
    },

    SET_JOBS_FROM_JOBGROUP(state, jobsFromJobGroup) {
        state.jobsFromJobGroup = jobsFromJobGroup
    },
}

const actions = {
    fetch_all_jobs_action: async ({commit}, version = 2) => {
        const response = await jobRepository.getAll(version);
        if(response){
            commit('SET_JOBS_LIST', response.data);
            return response.data;
        }
    },

    fetch_jobs_by_jobgroup_action: async ({commit},jobGroupId) => {
        const response = await jobRepository.getByJobGroup(jobGroupId);
        if(response){
            commit('SET_JOBS_FROM_JOBGROUP', response.data);
        }
    },



}

const getters = {
    get_job_list: (state) => {
       return state.jobsList;
    },

    get_jobs_from_jobgroup: (state) => {
        return state.jobsList;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
