<template>
  <div class="pr-4">
    <select v-model="selected" class="bg-transparent mb-1">
        <option :value="null">Alle locaties</option>
        <option v-for="(location, index) in options" :key="index" :value="location.id">{{ location.title }}</option>
    </select>
    <p class="text-gray text-sm">Alle shifts van alle locaties</p>
  </div>
</template>

<script>
export default {
  name: 'LocationSelect',
  data() {
    return {
      selected: null
    }
  },
  props: {
    options: {
      type: Array,
      required: true
    }
  },
  computed: {
    location: function() {
      return this.$store.state.shift.filters.location
    }
  },
  watch: {
    selected: function(newValue) {
      const payload = { propertyName: 'location', value: newValue }
      this.$store.dispatch('shift/set_specific_filter', payload);
      this.$emit('locationChange');
    },
    location: function(newValue) {
      this.selected = newValue;
    }
  },
}
</script>

<style lang="scss" scoped="true">
select {
  width: 100%;
  max-width: 320px;
}
</style>