<template>
  <div>
    <PageTitle title="Nieuwe werkwijze My Shifts" subtitle="" :centered="true" />
    <div class="bg-white">
      <div class="container mx-auto px-4">
        <p>
          Om veilig te werken met zowel zzp’ers als uitzendkrachten moeten de onderstaande documenten getekend worden.
          Pas als alle documenten getekend zijn wordt je account weer vrij geschakeld.
          </p>
        <p>
          Bedankt voor het vertrouwen in My Shifts
        </p>
        <!-- <InputCheckbox id="raamovereenkomst" v-model="contract_1">
          <div class="text-gray-dark text-sm md:text-center">
            Ja, ik ga akkoord met de <a :href="`/webservice/api/v1/contract/download?contract=raamovereenkomst&user=${$store.state.user.user.id}`" target="_blank">Raamovereenkomst van Payoffice</a>
          </div>
        </InputCheckbox> -->
        <InputCheckbox id="algemene-voorwaarden" v-model="contract_2">
          <div class="text-gray-dark text-sm md:text-center">
            Ja, ik ga akkoord met de <a :href="`/webservice/api/v1/contract/download?contract=algemene-voorwaarden`" target="_blank">Algemene voorwaarden van My Shifts</a>
          </div>
        </InputCheckbox>
        <InputCheckbox id="bemiddelingsovereenkomst" v-model="contract_3">
          <div class="text-gray-dark text-sm md:text-center">
            Ja, ik ga akkoord met de <a :href="`/webservice/api/v1/contract/download?contract=bemiddelingsovereenkomst-opdrachtgever&user=${$store.state.user.user.id}`" target="_blank">Bemiddelingsovereenkomst</a>
          </div>
        </InputCheckbox>
        <InputCheckbox id="tekenbevoegd" v-model="contract_4">
          <div class="text-gray-dark text-sm md:text-center">
            Ik ben tekenbevoegd voor het tekenen van deze contracten
          </div>
        </InputCheckbox>
        <ButtonPrimary class="my-4" text="Accepteren" extraClasses="!inline-block" @click.native="acceptToAgreement()" :disabled="!termsAccepted"></ButtonPrimary>
        <Whatsapp :inline="true" />
      </div>
    </div>
  </div>
</template>

<script>
import Whatsapp from "@/components/buttons/Whatsapp.vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";

import ErrorService from "@/services/errors/ErrorService";
import AxiosClient from "@/config/axios/axios";

export default {
  components: {
    Whatsapp,
    ButtonPrimary,
  },
  data() {
    return {
      contract_1: false,
      contract_2: false,
      contract_3: false,
      contract_4: false,
    };
  },
  computed: {
    termsAccepted: function() {
      if (this.contract_2 && this.contract_3 && this.contract_4) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    acceptToAgreement: function() {
      const data = { userId: this.$store.state.user.user.id };
      return AxiosClient.post("contract/agree", data)
        .then((response) => {
          this.$store.dispatch('user/user_agreed_to_contracts_action', true);
          this.$router.push({name: 'dashboard_employer'});
          return response;
        })
        .catch((error) => {
          ErrorService.onError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group a {
  text-decoration: underline;
}
</style>
