<template>
<div v-if="registrar.status !== 'declined' && registrar.status !== 'unsubscribed' && registrar.status !== 'canceled'" class="shiftee" :class=" { 'shiftee--with-salary': salary }">
    <img :src="registrar.user.imageUrl" />
    <span v-if="registrar.status !== 'unsigned'" class="shiftee__status" :class="`shiftee__status--${registrar.status}`">
        <i v-if="registrar.status === 'accepted'" class="icon-checkmark_alt" />
        <i v-if="registrar.status === 'pending'" class="icon-clock" />
    </span>

    <span v-if="salary" class="shiftee__salary">
        <span class="shiftee__salary__major">{{(salary+"").split(".")[0] }}</span>
        <span class="shiftee__salary__minor">{{
            padNumbers(salary+"").split(".")[1] ? padNumbers(salary+"").split(".")[1] : '00' }}
        </span>
    </span>
</div>
</template>

<style scoped>
    .shiftee {
        @apply relative text-center rounded-full w-14 h-14;
    }

    .shiftee--with-salary {
        @apply h-16 pb-2;
        /*@apply relative rounded-full w-14 h-14;*/
    }

    .shiftee img {
        @apply w-full h-full rounded-full;
    }

    .shiftee__status {
        @apply absolute rounded-full border-2 border-white text-white inline-block h-6 w-6 flex items-center justify-center;

        top: -.25rem;
        right: -.25rem;
    }

    .shiftee__status--accepted {
        @apply bg-green text-xs;
    }

    .shiftee__status--accepted i {
        @apply relative;
        left: -2px;
    }

    .shiftee__status--pending {
        @apply bg-yellow text-sm;
    }

    .shiftee__status--pending i {
        @apply relative;
        top: -1px;
    }

    .shiftee__salary {
        @apply absolute flex items-center bg-orange text-black rounded-lg bottom-0 leading-tight px-1 font-semibold;

        left: 50%;
        transform: translate(-50%, 0);
    }

    .shiftee__salary__major {
        @apply text-sm;
    }

    .shiftee__salary__minor {
        font-size: .6rem;
        margin-top: -.2rem;
    }
</style>

<script>
export default {

    props: {
        registrar: {
            type: Object,
            required: true
        },
        salary: {
            type: Number,
        }
    },

    components: {

    },

    data: () => ({

    }),

    methods: {
        padNumbers(value) {
            return String(value).padStart(2, '0');
        }
    }
};
</script>
