import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import user from '@/store/userStore';
import job from "@/store/jobStore";
import cao from "@/store/caoStore";
import shift from "@/store/shiftStore";
import general from '@/store/generalStore';
import auth from '@/store/authenticationStore';
import jobGroup from '@/store/jobGroupStore';
import language from '@/store/languageStore';
import recruiter from '@/store/recruiterStore';
import education from "@/store/educationStore";
import certificate from '@/store/certificateStore';
import onboardingProfile from '@/store/onboarding/onboardingProfileStore';
import onboardingGeneral from '@/store/onboarding/onboardingGeneralStore';
import onboardingExperience from '@/store/onboarding/onboardingExperienceStore';
import onboardingEmployer from '@/store/onboarding/onboardingEmployerStore';
import onboardingRecruiter from '@/store/onboarding/onboardingRecruiterStore';


export default new Vuex.Store({
    plugins: [createPersistedState({
        paths: [
            'user',
            'shift',
            'onboardingProfile',
            'onboardingGeneral',
            'onboardingRecruiter',
            'onboardingEmployer',
            'onboardingExperience',
        ],
    })],
    modules: {
        cao:cao,
        job:job,
        auth: auth,
        user: user,
        shift: shift,
        general:general,
        language:language,
        jobGroup:jobGroup,
        recruiter:recruiter,
        education:education,
        certificate: certificate,
        onboardingProfile: onboardingProfile,
        onboardingGeneral: onboardingGeneral,
        onboardingRecruiter: onboardingRecruiter,
        onboardingEmployer: onboardingEmployer,
        onboardingExperience: onboardingExperience,
    }
});
