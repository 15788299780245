import {ValidationObserver, ValidationProvider, extend, localize} from 'vee-validate';
import {required, min, email} from "vee-validate/dist/rules";

export default (Vue) => {

    extend("required", required);
    extend("min", min);
    extend("email", email);

    extend("dutchZip", {
        message:
            "Postcode moet bestaan uit 4 cijfers en 2 letters",
        validate: value => {
            const dutchZipCode = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
            return dutchZipCode.test(value);
        }
    });


    extend("phoneNumber", {
        message:
            "Telefoonnummer moet 10 cijfers lang zijn",
        validate: value => {
            const tenDigits =/^[0-9]{10}$/;
            return tenDigits.test(value);
        }
    });

    extend("kvk", {
        message:
            "KVK moet 8 cijfers lang zijn",
        validate: value => {
            const eightDigits =/^[0-9]{8}$/;
            return eightDigits.test(value);
        }
    });

    extend("establishmentNumber", {
        message:
            "Vestigingsnummer moet 12 cijfers lang zijn",
        validate: value => {
            const twelveDigits =/^[0-9]{12}$/;
            return twelveDigits.test(value);
        }
    });


    extend("iban1", {
        message:
            "2 letters & 2 cijfers (NL99)",
        validate: value => {
            const twoLettersTwoDigits = /^[a-z]{2}\d{2}$/i;
            return twoLettersTwoDigits.test(value);
        }
    });

    extend("iban2", {
        message:
            "4 letters (RABO)",
        validate: value => {
            const fourLetters = /^[a-z]{4}$/i;
            return fourLetters.test(value);
        }
    });

    extend("iban3", {
        message:
            "10 cijfers (0000 0000 00)",
        validate: value => {
            const tenDigitsWithOptionalSpaces = /^[0-9][0-9][0-9][0-9] ?[0-9][0-9][0-9][0-9] ?[0-9][0-9]$/;
            return tenDigitsWithOptionalSpaces.test(value);
        }
    });

    Vue.component('ValidationProvider', ValidationProvider)
    Vue.component('ValidationObserver', ValidationObserver)

    return localize({
        en: {
            messages: {
                required: "Dit veld is vereist",
            },
            fields: {
                password: {
                    required: "Kies een wachtwoord",
                    min: "Minimaal 8 tekens"
                },
                email: {
                    email: "Voer een geldig emailadres in",
                }
            }
        }
    });
}
