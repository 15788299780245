<template>
  <div v-if="dataFetched">
    <PageTitle>
      <template v-slot:pageTitleLeft>
        <WeekPicker
            :min-date="dateFirstTimeSheet"
            @weekChange="getTimeSheets($event)"
            ref="weekPicker"
        />
        <p class="text-gray text-sm mb-0">Uren boeken deze week</p>
      </template>
    </PageTitle>


    <div class="mx-auto px-6">
      <div class="md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          <TimeSheetItemEmployee
              v-for="(timeSheet) in timeSheets" :key="timeSheet.id"
              :time-sheet="timeSheet"
              @saveTimeSheet="saveTimeSheet($event)"
              @changeStatus="changeStatus($event)"
          />
      </div>


      <div v-if="timeSheets.length < 1">
        <p>Geen gewerkte shifts deze week</p>
      </div>
    </div>



    <div class="mx-auto px-6 mt-8 text-center flex justify-between">
      <button v-if="selectedWeek.weekNumber > firstWeekNumber"
          type="button" class="button button--outline button--auto"
              @click="changeWeekWithButton('previous')">
        Vorige week
      </button>

      <button v-if="selectedWeek.weekNumber <  currentWeekNumber"
          type="button" class="button button--outline button--auto"
              @click="changeWeekWithButton('next')">
        Volgende week
      </button>
    </div>

    <div class="mx-auto px-6 mt-8 mb-12 text-center">
      <h4 class="text-sm font-bold">Heb je een fout gemaakt?
        <router-link :to="{name: 'teams'}" tag="a">
        Neem contact op
      </router-link></h4>
    </div>

    <!--MSS-502-->
     <div v-if="new_hour_system_modal">
      <Overlay @closeOverlay="new_hour_system_modal = false;">
        <div class="relative">
            <div class="contract__container mb-32">
              <template>
                <p><strong>Klok je uren direct na je shift.</strong> Uren die niet binnen 24 uur worden doorgegeven kunnen door bedrijven niet op tijd gecontroleerd worden en gaan naar de volgende week ter controle en schuiven dus ook 1 week op ter uitbetaling.</p>
                <p>
                  Met vriendelijke groet,<br />
                  My Shifts team.
                </p>
              </template>
            </div>
            <div class="fixed bottom-0 left-0 right-0 mx-auto max-w-lg p-6 pt-0 bg-white bg-opacity-90">
                <ButtonPrimary class="mt-4" text="Accepteren" @click.native="new_hour_system_modal = false;" />
            </div>
        </div>
      </Overlay>
    </div>
    <!--MSS-502-->


  </div>
</template>
<script>

import TimeSheetItemEmployee from "@/components/timesheets/TimeSheetItemEmployee";
import WeekPicker from "@/components/date-time/WeekPicker";
import timeSheetMixin from "@/mixins/timeSheetMixin";
import Overlay from '@/components/Overlay';
import ButtonPrimary from '@/components/buttons/ButtonPrimary';

export default {
  mixins: [timeSheetMixin],

  data() {
    return {
      new_hour_system_modal: false
    };
  },

  watch: {
    dataFetched: function() {
      if (this.dataFetched == true) {
        this.new_hour_system_modal = true;
      }
    }
  },

  components: {
    TimeSheetItemEmployee,
    WeekPicker,
    Overlay,
    ButtonPrimary
  },

  methods: {
    changeWeekWithButton(action) {
      this.$refs.weekPicker.changeWeekFromOutside(action);
    }
  }

}
</script>

