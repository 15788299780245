<template>
  <div>
    <ul>
      <li>Als uitzendkracht bij My Shifts wordt je wekelijks uitbetaald.</li>
      <li>Wij betalen altijd op donderdag uit.</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    JEXdata: {
      type: Object,
      required: true,
    }
  },
}
</script>
