<template>
  <div>
    <div class="bg-black bg-noise-top text-white py-4">
      <div class="flex flex-col items-center text-center p-4">
        <a class="no-underline mt-2 mb-4" href="/">
          <LogoBlackSvg/>
        </a>

        <h1 v-html="$t('onboarding.recruiter.personal.header.title', {
                userGroup: '<span class=\'text-orange\'>' + $t('general.recruiter') + '</span>'
            })"></h1>

        <p class="lead">{{ $t('onboarding.recruiter.personal.header.subtitle') }}</p>
      </div>
    </div>
    <div class="black-bar mb-4"></div>

    <div class="container mx-auto p-4">
      <div class="flex flex-col">

        <ValidationObserver ref="form" class="mb-4">

          <div class="panel">
            <h3>{{ $t('onboarding.recruiter.personal.titles.coc') }}</h3>
            <InputText id="kvk" :placeholder="$t('general.coc_number')" :required="true" rules="kvk" v-model="kvk"/>
            <InputText id="establishment-number" :placeholder="$t('general.establishment_number')" rules="establishmentNumber" :required="true" v-model="establishmentNumber"/>
            <p class="text-gray text-sm">{{ $t('onboarding.recruiter.personal.info.establishment_number') }}</p>
          </div>

          <div class="panel">
            <h3>{{ $t('onboarding.recruiter.personal.titles.company') }}</h3>
            <InputText id="companyName" :placeholder="$t('general.company_name')" :required="true" v-model="companyName"/>

            <AddressAutoComplete
                @successAddress="successAddress"
                @errorAddress="errorAddress"
            />

            <InputText id="street" :placeholder="$t('general.street')" :required="true" v-model="street" :disabled="true"/>
            <InputText id="city" :placeholder="$t('general.city')" :required="true" v-model="city" :disabled="true"/>
            <InputText id="province" :placeholder="$t('general.province')" :required="true" v-model="province" :disabled="true"/>
            <InputText id="mainActivity" :placeholder="$t('general.main_activity')" :required="true" v-model="mainActivity"/>
            <InputText id="website" :placeholder="$t('general.website')" :required="false" v-model="website"/>
          </div>

          <div class="panel">
            <h3>{{ $t('onboarding.recruiter.personal.titles.bank') }}</h3>
            <div class="flex">
              <InputText class="w-1/4 mr-2" id="accountnumber1" :placeholder="`NL99`" :required="true" rules="iban1" v-model="accountNumber1"/>
              <InputText class="w-1/4 mr-2" id="accountnumber2" :placeholder="`BANK`" :required="true" rules="iban2" v-model="accountNumber2"/>
              <InputText class="w-2/4" id="accountnumber3" :placeholder="`0000 0000 00`" :required="true" rules="iban3" v-model="accountNumber3"/>
            </div>
            <p class="text-gray text-sm">{{ $t('onboarding.recruiter.personal.info.account_number') }}</p>
          </div>

          <div class="panel">
            <h3>{{ $t('onboarding.recruiter.personal.titles.contact') }}</h3>
            <InputText id="firstname" :placeholder="$t('general.firstname')" :required="true" v-model="firstname"/>
            <InputText id="lastname" :placeholder="$t('general.lastname')" :required="true" v-model="lastname"/>
            <InputText id="phone" :placeholder="$t('general.phone')" :required="true" v-model="phone"/>
          </div>

          <div class="panel">
            <h3>{{ $t('onboarding.personal.titles.inlog_data') }}</h3>
            <InputEmail id="email" :placeholder="$t('general.email')" autocomplete="off" :required="true" v-model="email"/>
            <p class="text-gray text-sm">{{ $t('onboarding.recruiter.personal.info.already_recruiter') }}</p>
            <InputEmail id="email" :allowPaste="false" :placeholder="$t('general.email_valid')" autocomplete="off" :required="true" v-model="emailToValidate"/>
            <InputPassword id="password" :placeholder="$t('general.password')" autocomplete="off" :required="true" v-model="password"/>
          </div>

          <InputCheckbox id="privacyCheck" v-model="privacyChecked">
            <div class="text-gray text-sm">
              {{ $t('onboarding.personal.requirements.privacy_check') }}
              <a :href="termsAnConditionsUrl" class="text-gray-darker" target="_blank">{{ $t('general.terms_and_conditions') }}</a>
              {{ $t('general.and') }}
              <a :href="privacyStatementUrl" class="text-gray-darker" target="_blank">{{ $t('general.privacy_statement') }}</a>
            </div>
          </InputCheckbox>
        </ValidationObserver>

        <div class="alert" v-if="!emailValidated">{{ $t('onboarding.personal.requirements_feedback.email_validate') }}</div>
        <div class="alert" v-if="!privacyChecked">{{ $t('onboarding.personal.requirements_feedback.privacy_check') }}</div>
        <div class="alert" v-if="loginFeedback">{{ loginFeedback }}</div>

        <NavNext nextRouteName="onboarding_appointment" :buttonText="$t('general.next')" @submit="validateForm" ref="NavNext"/>

      </div>
    </div>
  </div>
</template>

<script>
import AddressAutoComplete from "@/components/input_fields/groups/AddressAutoComplete";

export default {
  components: {
    AddressAutoComplete,
  },
  data: () => ({
    email: '',
    emailToValidate: '',
    password: '',
    phoneCountry: '',

    addressCoordinates: {},

    privacyChecked: false,
    loginFeedback: null,

    termsAnConditionsUrl: process.env.VUE_APP_PUBLIC_BACKEND_URL + 'data/gebruikersvoorwaarden-my-shifts.pdf',
    privacyStatementUrl: process.env.VUE_APP_PUBLIC_BACKEND_URL + 'data/privacyverklaring-my-shifts.pdf',
  }),


  methods: {
    validateForm() {
      this.$refs.form.validate().then((validated) => {
        if (validated && this.privacyChecked && this.emailValidated) {
          const data = this.buildUserData();
          this.$store.dispatch('user/user_create_action', data).then((response) => {
            this.handleResponse(response.data)
          });
        }
      });
    },

    buildUserData() {
      let data = this.$store.getters['onboardingRecruiter/get_all_data'];
      return {
        ...data,
        email: this.email,
        password: this.password,
        phone: this.phoneCountry + data.phone,
        accountNumber: `${data.accountNumber1} ${data.accountNumber2} ${data.accountNumber3}`,
        usergroups: this.$constants.USERGROUP_RECRUITER,
        addressCoordinates: this.addressCoordinates,
      }
    },

    handleResponse(response) {
      if (response.message === 'username_taken') {
        this.loginFeedback = this.$t('onboarding.personal.feedback.username_taken');
      }

      if (response.message === 'establishment_number_invalid') {
        this.loginFeedback = this.$t('onboarding.personal.feedback.establishment_number_invalid');
      }

      if (response.message === 'user_not_created') {
        this.loginFeedback = this.$t('onboarding.personal.feedback.user_not_created');
      }

      if (response.message === 'user_created') {
        this.loginFeedback = this.$t('onboarding.personal.feedback.user_created');
        this.successToast('Account aangemaakt. Je ontvangt via e-mail verdere instructies.', 'login')
      }
    },

    successAddress(data) {
      this.street = data.street;
      this.houseNumber = data.houseNumber;
      this.city = data.city;
      this.province = data.province;
      this.zipCode = data.zipCode;
      this.addressCoordinates = data.addressCoordinates
    },

    errorAddress() {
      this.street = '';
      this.city = '';
    },

  },


  computed: {

    emailValidated: function () {
      return this.email === this.emailToValidate;
    },

    kvk: {
      set(kvk) {
        this.$store.commit('onboardingRecruiter/SET_KVK', kvk)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_kvk'];
      }
    },
    establishmentNumber: {
      set(establishmentNumber) {
        this.$store.commit('onboardingRecruiter/SET_ESTABLISHMENT_NUMBER', establishmentNumber)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_establishment_number'];
      }
    },
    companyName: {
      set(companyName) {
        this.$store.commit('onboardingRecruiter/SET_COMPANY_NAME', companyName)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_company_name'];
      }
    },
    zipCode: {
      set(zipCode) {
        this.$store.commit('onboardingRecruiter/SET_ZIPCODE', zipCode)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_zip_code'];
      }
    },
    houseNumber: {
      set(houseNumber) {
        this.$store.commit('onboardingRecruiter/SET_HOUSE_NUMBER', houseNumber)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_house_number'];
      }
    },
    street: {
      set(street) {
        this.$store.commit('onboardingRecruiter/SET_STREET', street)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_street'];
      }
    },
    city: {
      set(city) {
        this.$store.commit('onboardingRecruiter/SET_CITY', city)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_city'];
      }
    },
    province: {
      set(province) {
        this.$store.commit('onboardingRecruiter/SET_PROVINCE', province)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_province'];
      }
    },
    mainActivity: {
      set(mainActivity) {
        this.$store.commit('onboardingRecruiter/SET_MAIN_ACTIVITY', mainActivity)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_main_activity'];
      }
    },
    website: {
      set(website) {
        this.$store.commit('onboardingRecruiter/SET_WEBSITE', website)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_website'];
      }
    },


    accountNumber1: {
      set(accountNumber1) {
        this.$store.commit('onboardingRecruiter/SET_ACCOUNT_NUMBER_1', accountNumber1)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_account_number1'];
      }
    },
    accountNumber2: {
      set(accountNumber2) {
        this.$store.commit('onboardingRecruiter/SET_ACCOUNT_NUMBER_2', accountNumber2)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_account_number2'];
      }
    },
    accountNumber3: {
      set(accountNumber3) {
        this.$store.commit('onboardingRecruiter/SET_ACCOUNT_NUMBER_3', accountNumber3)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_account_number3'];
      }
    },


    firstname: {
      set(firstname) {
        this.$store.commit('onboardingRecruiter/SET_FIRST_NAME', firstname)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_first_name'];
      }
    },
    lastname: {
      set(lastname) {
        this.$store.commit('onboardingRecruiter/SET_LAST_NAME', lastname)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_last_name'];
      }
    },
    phone: {
      set(phone) {
        this.$store.commit('onboardingRecruiter/SET_PHONE', phone)
      },
      get() {
        return this.$store.getters['onboardingRecruiter/get_phone'];
      }
    },

  },


};
</script>
