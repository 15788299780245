import repository from "@/repositories/repository";

const moment = require('moment');

const shiftRepository = repository.get("shift");

const state = () => ({
    employeeShifts: [],
    employeeRegisteredShifts: [],
    employeeUnregisteredShifts: [],
    employerShifts: [],
    filters: {
        location: null
    }
})

const mutations = {
    FETCH_EMPLOYEE_REGISTERED_SHIFTS(state, employeeRegisteredShifts) {
        state.employeeRegisteredShifts = employeeRegisteredShifts
    },

    FETCH_EMPLOYEE_UNREGISTERED_SHIFTS(state, employeeUnregisteredShifts) {
        state.employeeUnregisteredShifts = employeeUnregisteredShifts
    },

    FETCH_EMPLOYER_SHIFTS(state, employerShifts) {
        state.employerShifts = employerShifts
    },

    SET_FILTERS(state, filters) {
        state.filters = filters
    },

    SET_SPECIFIC_FILTER(state, payload) {
        state.filters[payload.propertyName] = payload.value
    },

    SET_DEFAULT_FILTERS(state, userGroup) {
        console.log('Setting default active filters for user group', userGroup);
        if (userGroup == 1 || userGroup == 2) {
            console.log('Employee');
            state.filters = {
                distance: null,
                job: null,
                organisation: null,
                minDate: null,
                maxDate: null
            }
        }
        if (userGroup == 6) {
            console.log('Employer');
            state.filters = {
                location: null,
                minDate: null,
                maxDate: null
            }
        }
    },
    
    RESET_FILTERS(state) {
        console.log('Clearing shift filters');
        state.filters = {};
    },
}

const actions = {
    // eslint-disable-next-line no-unused-vars
    create_shift_action: async ({state}, shift) => {
        return await shiftRepository.create(shift);
    },

    fetch_employee_registered_shifts_action: async ({commit}) => {
        const response = await shiftRepository.getRegisteredForEmployee();
        if (response) {
            commit('FETCH_EMPLOYEE_REGISTERED_SHIFTS', response);
        }
    },

    fetch_employee_unregistered_shifts_action: async ({commit}) => {
        const response = await shiftRepository.getUnregisteredForEmployee();
        if (response) {
            commit('FETCH_EMPLOYEE_UNREGISTERED_SHIFTS', response);
        }
    },

    fetch_employer_shifts_action: async ({commit}) => {
        const response = await shiftRepository.getAllForEmployer();
        if (response) {
            commit('FETCH_EMPLOYER_SHIFTS', response);
        }
    },
    
    set_filters: async ({commit}, payload) => {
        commit('SET_FILTERS', payload);
    },
    
    set_specific_filter: async ({ commit }, payload) => {
        commit('SET_SPECIFIC_FILTER', payload);
    },
    
    set_default_filters: async ({commit, rootState}) => {
        commit('SET_DEFAULT_FILTERS', rootState.user.user.group);
    },

    reset_filters: (context) => {
        context.commit('RESET_FILTERS');
    }
}

const getters = {

    /*EMPLOYEE ALL SHIFTS*/
    get_employee_shifts: (state) => {
        return state.employeeShifts;
    },

    /*EMPLOYEE UNREGISTERED*/
    get_employee_unsigned_shifts: (state) => {
        return state.employeeUnregisteredShifts;
    },

    /*EMPLOYEE REGISTERED*/
    get_employee_pending_shifts: (state) => {
        return state.employeeRegisteredShifts.filter((shift) => {
            return shift.registration.status === 'pending';
        });
    },

    get_employee_accepted_shifts: (state) => {
        return state.employeeRegisteredShifts.filter((shift) => {
            return shift.registration.status === 'accepted';
        });
    },

    get_employee_declined_shifts: (state) => {
        return state.employeeRegisteredShifts.filter((shift) => {
            return shift.registration.status === 'declined';
        });
    },

    get_employee_unsubscribed_shifts: (state) => {
        return state.employeeRegisteredShifts.filter((shift) => {
            const status = shift.registration.status;
            return status === 'unsubscribed' || status === 'canceled' || status === 'unsubscribedByEmployer' || status === 'canceledByEmployer';
        });
    },

    /*EMPLOYER*/
    get_employer_shifts: (state) => {
        return state.employerShifts;
    },

    get_employer_open_shifts: (state) => {
        return state.employerShifts.filter((shift) => {
            return !isFilledShift(shift);
        });
    },

    get_employer_shifts_coming_days: (state) => {
        const timeIn7Days = moment().add(7, 'd');
        return state.employerShifts.filter((shift) => {
            return moment(shift.startDateTime).isBefore(timeIn7Days);
        });
    },

    /*FILTERS*/
    get_filters: async (state) => {
        return state.filters;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}




function isFilledShift(shift) {
    return shift.amountEmployeesAccepted >= shift.amountEmployees;
}
