<template>
  <div v-if="dataFetched">

    <ShiftDetailEmployeeHeader :shift="shift"></ShiftDetailEmployeeHeader>


    <div class="container mx-auto px-6 py-4 -mt-32">
      <ShiftDetailEmployeeBaseInfo :shift="shift"></ShiftDetailEmployeeBaseInfo>


      <div class="block">
        <h3>Wat wordt er van je verwacht?</h3>
        <div v-html="shift.descriptionWork"></div>
      </div>

      <div class="block" v-if="shift.registration.status === 'accepted'">
        <ShiftDetailEmployeeTeam :shift="shift">
          Het team voor deze shift
        </ShiftDetailEmployeeTeam>
      </div>

      <div class="block">
        <h3>Vereiste skills</h3>
        <div v-html="shift.requiredSkills"></div>
      </div>

      <div class="block">
        <h3>Vereiste talen</h3>
        <Flag class="mr-2" v-for="(language, index) in shift.requiredLanguages" :code="language.code" :key="index"/>
      </div>

      <div class="block">
        <h3>Kledingvoorschriften</h3>
        <div v-html="shift.dressCode"></div>
      </div>

      <div class="border-t border-gray-light">
        <Accordion title="Waar moet je je melden?">
          <div v-html="shift.placeToReport"></div>
        </Accordion>
        <Accordion title="Waar kun je parkeren?">
          <div v-html="shift.parkingInstructions"></div>
        </Accordion>
        <Accordion title="Aanvullende eisen of opmerkingen?">
          <div v-html="shift.additionalDescription"></div>
        </Accordion>
      </div>
    </div>

    <!-- INSTRUCTIONS -->
    <div class="bg-orange bg-opacity-10">
      <div class="container mx-auto px-6 py-4 mb-8">
        <h3 class="mb-3">Hoe werkt aanmelden?</h3>
        <ul>
          <li>Door te accepteren meld je je aan voor de shift.</li>
          <li>Accepteert het bedrijf je aanmelding dan kun je aan de slag!.</li>
        </ul>
      </div>
    </div>

    <div class="mb-8 bg-orange bg-opacity-10">
      <GoogleMaps :shift="shift"></GoogleMaps>
    </div>

    <!-- COMPANY INFO -->
    <div class="container mx-auto p-4 mb-12">
      <h3>{{ shift.employer.companyName }}, {{ shift.shiftLocation.city }}</h3>
      <p class="text-xs font-semibold mb-4">
        <i class="icon-pin"/>
        {{ shift.shiftLocation.street }} {{ shift.shiftLocation.houseNumber }}, {{ shift.shiftLocation.zipCode }} {{ shift.shiftLocation.city }}
        <span class="ml-2 text-gray font-normal">{{ shift.distance }}km</span>
      </p>
      <div class="text-sm text-gray" v-html="shift.employer.description"></div>
    </div>

    <!-- COMPANY SHIFTS -->
    <ShiftsCarousel :shifts="shiftsOfThisEmployer" v-if="shiftsOfThisEmployer.length" class="px-6 mb-16">
      <h2 class="text-2xl font-normal mb-8"> Shifts van dit bedrijf</h2>
      <template v-slot:shiftItem="shifts">
        <ShiftItem
            v-for="(shift,index) in shifts.data"
            :shift="shift"
            :key="index"
            :has-passed="false"
        />
      </template>
    </ShiftsCarousel>

    <!-- STICKY FOOTER -->
    <div class="fixed bottom-0 left-0 right-0 bg-gray-lighter border-t border-gray-light">
      <div v-if="allowedToRegister" class="p-4" @click="overlay.accept_contract = true">
        <button class="button button--primary">
          <span class="text-sm">Aanmelden</span>
        </button>
      </div>

      <div v-if="shift.registration.status === 'pending'" class="p-4 bg-yellow-light cursor-pointer" @click="overlay.pending = true">
        <div class="flex items-center justify-center mb-2 text-brown">
          <i class="icon-clock text-yellow mr-2"/>
          <span class="text-sm font-bold">In afwachting op goedkeuring</span>
        </div>

        <p class="text-xs text-gray-dark text-center">Je bent aangemeld voor deze shift. Wil je {{ subscribeCancel }}? Klik dan hier.</p>
      </div>

      <div v-if="shift.registration.status === 'accepted'" class="p-4 cursor-pointer" @click="overlay.accepted = true">
        <div class="flex items-center justify-center mb-2 text-green">
          <i class="icon-checkmark text-green mr-2"/>
          <span class="text-sm font-bold">Aanmelding bevestigd</span>
        </div>

        <p class="text-xs text-orange text-center">Je werkt deze shift, start over {{ timeLeftToStart(shift) }}, je kunt hier klikken om te {{ subscribeCancel }}</p>
      </div>

      <div v-if="shift.registration.status === 'declined'" class="p-4">
        <div class="flex items-center justify-center mb-2 text-brown">
          <span class="rounded-full w-6 h-6 bg-red flex items-center justify-center mr-2">
            <i class="icon-cross text-white relative" style="font-size: .6rem"/>
          </span>
          <span class="text-sm font-bold">Helaas, je bent niet geselecteerd</span>
        </div>

        <p class="text-xs text-gray-dark text-center">De werkgever heeft een andere kandidaat gekozen</p>
      </div>


      <div v-if="shift.registration.status === 'unsubscribed'" class="p-4">
        <div class="flex items-center justify-center mb-2 text-brown">
          <span class="text-sm font-bold">Je hebt je afgemeld voor deze shift</span>
        </div>

        <p class="text-xs text-gray-dark text-center">Omdat je je hebt afgemeld kun je niet meer reageren op deze shift</p>
      </div>
    </div>


    <!-- OVERLAYS -->
    <!--  overlay: accept the contract  -->
    <div v-if="overlay.accept_contract">
      <Overlay @closeOverlay="overlay.accept_contract = false; termsAccepted = false">
        <div class="relative">
            <div class="contract__container" v-html="shift.contract" :style="'margin-bottom:' + (termsAccepted ? 150 : 240) + 'px;'"></div>

            <div class="fixed bottom-0 left-0 right-0 mx-auto max-w-lg p-6 pt-0 bg-white bg-opacity-90">
                <InputCheckbox id="privacyCheck" v-model="termsAccepted">
                  <div class="text-gray-dark text-sm md:text-center">
                    Ja, ik ga akkoord met de overeenkomst van opdracht
                  </div>
                </InputCheckbox>
                <FormAlert :condition="!termsAccepted">Je kunt je pas inschrijven als je akkoord gaat met de overeenkomst van opdracht.</FormAlert>
                <ButtonPrimary class="mt-4" text="Accepteren" @click.native="overlay.accept_contract = false; subscribe(shift)" :disabled="!termsAccepted"></ButtonPrimary>
            </div>
        </div>
      </Overlay>
    </div>


    <!-- overlay: pending  -->
    <div v-if="overlay.pending">
      <Overlay @closeOverlay="overlay.pending = false">
        <div class="flex items-center justify-center mb-2 text-brown">
          <i class="icon-clock text-yellow mr-2"/>
          <span class="text-sm font-bold">In afwachting op goedkeuring.</span>
        </div>

        <button class="button button--outline" @click="overlay.cancel = true" style="width: 100%">
          <span class="text-gray-dark text-sm font-bold">Aanmelding annuleren</span>
        </button>
      </Overlay>
    </div>


    <!-- overlay: accepted  -->
    <div v-if="overlay.accepted">
      <Overlay @closeOverlay="overlay.accepted = false">
        <div class="flex items-center justify-center mb-2 text-brown">
          <i class="icon-checkmark text-green mr-2"/>
          <span class="text-sm font-bold">Aanmelding bevestigd</span>
        </div>

        <p class="text-xs text-orange text-center">Je werkt deze shift, start over {{ timeLeftToStart(shift) }}. Wil je {{ subscribeCancel }}? Klik dan hier. </p>

        <!--   cancel (with legal time)    -->
        <div v-if="!cancelDateHasPassed && shift.registration.status !== 'unsigned'">
          <button class="button button--outline" @click="overlay.cancel = true; overlay.accepted = false" style="width: 100%">
            <span class="text-gray-dark font-bold text-sm">Shift Annuleren</span>
          </button>
        </div>
        <!--   unsubscribe (outside legal time)    -->
        <div v-if="cancelDateHasPassed && shift.registration.status !== 'unsigned'">
          <button class="button button--outline" @click="overlay.unsubscribe = true; overlay.accepted = false" style="width: 100%">
            <span class="text-gray-dark font-bold text-sm">Afmelden voor shift</span>
          </button>
        </div>
      </Overlay>
    </div>


    <!-- overlay: cancel  -->
    <div v-if="overlay.cancel">
      <Overlay @closeOverlay="overlay.cancel = false">
        <div class="flex items-center justify-center mb-2">
          <span class="text-sm font-bold">Weet je zeker dat je de Shift wilt annuleren?</span>
        </div>

        <button class="button button--outline" @click="overlay.cancel = false; cancelRegistration(shift.registration.id)" style="width: 100%">
          <span class="font-bold text-sm">Annuleer</span>
        </button>
      </Overlay>
    </div>

    <!-- overlay: unsubscribe  -->
    <div v-if="overlay.unsubscribe">
      <Overlay @closeOverlay="overlay.unsubscribe = false">
        <div class="flex items-center justify-center mb-2">
          <span class="text-sm font-bold">Wat is de reden voor het afmelden?</span>
        </div>

        <InputSelect id="jobGroups"
                     class="mt-6"
                     :options="[
                            { id: 'Ik ben ziek', title: 'Ik ben ziek' },
                            { id: 'Ik heb ander werk', title: 'Ik heb ander werk' },
                            { id: 'Problemen met vervoer', title: 'Problemen met vervoer' },
                            { id: 'Andere reden', title: 'Andere reden' },
                         ]"
                     placeholder="Wat is de reden"
                     :required="true"
                     @onChange="cancelReason = $event"/>

        <button class="button button--outline" @click="overlay.unsubscribe = false; overlay.unsubscribe_confirm = true;" style="width: 100%">
          <span class="font-bold text-sm">Shift afmelden</span>
        </button>
      </Overlay>
    </div>

    <!-- overlay: unsubscribe  -->
    <div v-if="overlay.unsubscribe_confirm">
      <Overlay @closeOverlay="overlay.unsubscribe_confirm = false">
        <div class="flex items-center justify-center mb-2 text-center">
          <span class="text-sm font-bold">Om de afmelding te bevestigen moet je met {{ shift.employer.companyName }} bellen</span>
        </div>
        <div class="text-2xl text-center py-4 mb-4">{{ shift.employer.phone }}</div>
        <a :href="`tel:${shift.employer.phone}`" class="button button--outline text-center mb-4" style="width: 100%">
          <span class="font-bold text-sm">Bellen</span>
        </a>
        <button class="button button--outline" @click="overlay.unsubscribe_confirm = false; unsubscribeRegistration(shift.registration.id)" style="width: 100%">
          <span class="font-bold text-sm">Ik heb het bedrijf gebeld</span>
        </button>
      </Overlay>
    </div>


  </div>
</template>

<script>
import ShiftsCarousel from "@/components/shifts/ShiftsCarousel";
import ShiftDetailEmployeeHeader from "@/components/shifts/ShiftDetailEmployeeHeader";
import ShiftDetailEmployeeBaseInfo from "@/components/shifts/ShiftDetailEmployeeBaseInfo";
import ShiftDetailEmployeeTeam from "@/components/shifts/ShiftDetailEmployeeTeam";
import ShiftItem from "@/components/shifts/ShiftItem";
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
import Accordion from '@/components/Accordion';
import Flag from '@/components/Flag';
import Overlay from '@/components/Overlay';
import GoogleMaps from "@/components/GoogleMaps";
import shiftCalculationsMixin from "@/mixins/shiftCalculationsMixin";

import repository from "@/repositories/repository";
import ShiftService from "@/services/ShiftService";


const shiftRepository = repository.get("shift");
const shiftRegistrationRepository = repository.get("shiftRegistration");
export default {
  mixins: [shiftCalculationsMixin],

  components: {
    ButtonPrimary,
    ShiftDetailEmployeeHeader,
    ShiftDetailEmployeeBaseInfo,
    ShiftDetailEmployeeTeam,
    Accordion,
    Flag,
    Overlay,
    ShiftsCarousel,
    GoogleMaps,
    ShiftItem
  },

  data: () => ({
    dataFetched: false,
    cancelReason: null,
    shift: null,
    shiftsOfThisEmployer: [],
    termsAccepted: false,

    overlay: {
      pending: false,
      accepted: false,
      cancel: false,
      accept_contract: false,
      unsubscribe: false,
      unsubscribe_confirm: false,
      unsubscribe_confirm_accept: false,
      declined: false,
    },
  }),

  computed: {
    subscribeCancel() {
      const isPassedCancelDate = this.$moment().isAfter(this.shift.maxCancelDate);
      if (isPassedCancelDate) {
        return 'afmelden';
      }
      return 'annuleren'
    },
  },

  methods: {
    async subscribe(shift) {
      const data = this.buildSubscribeData(shift)

      this.$store.dispatch('general/startloading');
      await shiftRegistrationRepository.create(data).then((response) => {
        this.$store.dispatch('general/endloading');
        this.handleResponseSubscribed(response);
      });
    },

    cancelRegistration(registrationId) {
      this.$store.dispatch('general/startloading');
      shiftRegistrationRepository.cancel(registrationId).then((response) => {
        this.$store.dispatch('general/endloading');
        this.handleResponseCanceled(response)
      });
    },

    unsubscribeRegistration(registrationId) {
      const data = this.buildUnsubscribeData(registrationId)

      this.$store.dispatch('general/startloading');
      shiftRegistrationRepository.unsubscribe(data).then((response) => {
        this.$store.dispatch('general/endloading');
        this.handleResponseUnsubscribed(response)
      });
    },

    buildSubscribeData(shift) {
      return {
        shiftId: shift.id,
        contract: shift.contract,
      }
    },

    buildUnsubscribeData(registrationId) {
      return {
        registrationId: registrationId,
        reasonUnsubscribe: this.cancelReason,
      }
    },

    handleResponseGetShift(response) {
      switch (response.message) {
        case 'shift_not_found':
          this.errorToast('De shift hebben we niet kunnen vinden, het kan zijn dat deze al heeft plaatsgevonden.', 'dashboard_employee')
          break;
      }
    },

    handleResponseSubscribed(response) {
      switch (response.message) {
        case 'shift_registration_created':
          this.successToast('Je hebt je aangemeld!')
          this.shift.registration.status = 'pending';
          break;
        default:
          this.errorToast('Er ging iets mis met registreren, probeer het later opnieuw.', 'dashboard_employee')
      }
    },

    handleResponseCanceled(response) {
      switch (response.message) {
        case 'shift_registration_canceled':
          this.successToast('Je hebt je registratie succesvol geannuleerd!', 'dashboard_employee')
          break;
        default:
          this.errorToast('Er ging iets mis met annuleren, probeer het later opnieuw.', 'dashboard_employee')
      }
    },


    handleResponseUnsubscribed(response) {
      switch (response.message) {
        case 'shift_registration_unsubscribed':
          this.successToast('Je hebt je registratie succesvol afgemeld!', 'dashboard_employee')
          break;
        default:
          this.errorToast('Er ging iets mis met annuleren, probeer het later opnieuw.', 'dashboard_employee')
      }
    },
  },

  async created() {
    this.$store.dispatch('general/startloading');
    this.shift = await shiftRepository.getByIdForEmployee(this.$route.params.id).then((response) => {
      this.handleResponseGetShift(response);
      return response;
    });

    (new ShiftService(this.shift)).archiveRedirectCheck('employee');

    this.shiftsOfThisEmployer = await shiftRepository.getAllFromEmployerForEmployee(this.shift.employer.id);
    this.dataFetched = true;
    this.$store.dispatch('general/endloading');
  }
}
</script>

<style>

.contract__container {
  @apply leading-relaxed text-sm p-0 text-black;
}

.contract__container ol {
    @apply ml-4;
}

.contract__container li {
  margin-bottom: .5rem !important;
  color: black;
}

.contract__container h1 {
  @apply leading-snug;

  text-underline: black;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.contract__container h2 {
  margin-top: 1.5rem;
}

.contract__container h3 {
  margin-top: 1.5rem;
}

.contract__container hr {
  margin-bottom: 1rem;
}

</style>

<style scoped>
.container {
  max-width: 1014px; 
}
.block {
  @apply mb-8 text-xs md:text-sm;
}

.block h3 {
  @apply mb-3 text-sm md:text-base;
}
</style>
