import constants from "@/settings/constants";
import ShiftCreate from "@/views/ShiftCreate";
import DashboardEmployer from "@/views/DashboardEmployer";
import ShiftDetailEmployer from "@/views/ShiftDetailEmployer";
import ShiftDetailEmployerArchive from "@/views/ShiftDetailEmployerArchive";
import ShiftOverviewEmployer from "@/views/ShiftOverviewEmployer";
import ShiftOverviewEmployerArchive from "@/views/ShiftOverviewEmployerArchive";
import ProfileEmployer from "@/views/ProfileEmployer";
import TimeSheetsEmployer from "@/views/TimeSheetsEmployer";

const EMPLOYER = constants.USERGROUP_EMPLOYER;
export default [

    {
        path: "/employer/dashboard",
        name: "dashboard_employer",
        component: DashboardEmployer,
        meta: {
            requiresAuth: true,
            hasPermission: [EMPLOYER],
            layout: 'navigation'
        }
    },

    {
        path: "/employer/shifts/create",
        name: "shift_create",
        component: ShiftCreate,
        meta: {
            requiresAuth: true,
            hasPermission: [EMPLOYER],
            layout: 'navigation'
        }
    },

    {
        path: "/employer/shifts/detail/:id",
        name: "shifts_employer_detail",
        component: ShiftDetailEmployer,
        meta: {
            requiresAuth: true,
            hasPermission: [EMPLOYER],
            layout: 'navigation'
        }
    },
    {
        path: "/employer/shifts/detail/archive/:id",
        name: "shifts_employer_detail_archive",
        component: ShiftDetailEmployerArchive,
        meta: {
            requiresAuth: true,
            hasPermission: [EMPLOYER],
            layout: 'navigation'
        }
    },

    {
        path: "/employer/shifts/overview",
        name: "shifts_employer_overview",
        component: ShiftOverviewEmployer,
        meta: {
            requiresAuth: true,
            hasPermission: [EMPLOYER],
            layout: 'navigation'
        }
    },

    {
        path: "/employer/shifts/overview/archive",
        name: "shifts_employer_overview_archive",
        component: ShiftOverviewEmployerArchive,
        meta: {
            requiresAuth: true,
            hasPermission: [EMPLOYER],
            layout: 'navigation'
        }
    },

    {
        path: "/employer/profile",
        name: "profile_employer",
        component: ProfileEmployer,
        meta: {
            requiresAuth: true,
            hasPermission: [EMPLOYER],
            layout: 'navigation-back'
        }
    },
    {
        path: "/employer/time-sheets",
        name: "time_sheets_employer",
        component: TimeSheetsEmployer,
        meta: {
            requiresAuth: true,
            hasPermission: [EMPLOYER],
            layout: 'navigation'
        }
    },



]
