<template v-if="acceptedRegistrars.length > 0">
  <div>
    <h3><slot></slot></h3>

    <div class="flex items-center my-4" v-for="(registrar, index) in acceptedRegistrars" :key="index">
      <Shiftee :registrar="registrar"/>
      <span class="ml-4 font-bold">{{ registrar.user.firstName }}</span>
    </div>

    <p class="text-sm text-gray-dark mt-6">
      {{ acceptedRegistrars.length }}/{{ shift.amountEmployees }} shiftys geaccepteerd
    </p>
  </div>
</template>

<script>
import Shiftee from "@/components/shifts/Shiftee";

export default {
  props: {
    shift: {
      type: Object,
      required: true
    },
  },

  components:{
    Shiftee
  },

  computed: {
    acceptedRegistrars() {
      return this.shift.registrars.filter((registrar) => {
        return registrar.status === 'accepted';
      });
    },
  },
}
</script>
