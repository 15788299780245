<template>
  <div v-if="dataFetched">
    <div class="bg-black bg-noise-top text-white py-4">
      <div class="flex flex-col items-center text-center p-4">
        <a class="no-underline mt-2 mb-4" href="/">
          <LogoBlackSvg/>
        </a>

        <h1 v-html="$t('onboarding.company.personal.header.title', {
                employer: '<span class=\'text-orange\'>' + $t('general.employer') + '</span>'
            })"></h1>

        <p class="lead">{{ $t('onboarding.company.personal.header.subtitle') }}</p>
      </div>
    </div>
    <div class="black-bar mb-4"></div>

    <div class="container mx-auto p-4">
      <div class="flex flex-col">

        <ValidationObserver ref="form" class="mb-4">

          <!--    COMPANY      -->
          <div class="panel">
            <h3>Bedrijfsgegevens</h3>
            <InputText id="companyName" :placeholder="$t('general.company_name')" :required="true" v-model="company.companyName"/>
            <InputText id="phone" :placeholder="$t('general.phone')" :required="true" v-model="company.companyPhone"/>
            <InputText id="website" :placeholder="$t('general.website')" :required="false" v-model="company.website"/>
            <InputText id="workingHours" :placeholder="`gebruikelijke arbeidsduur uur per week (36 tot 40 uur)`" :required="true" v-model="company.workingHours"/>

            <InputText id="kvk" :placeholder="$t('general.coc_number')" :required="true" rules="kvk" v-model="company.kvk"/>
            <InputText id="establishment-number" :placeholder="$t('general.establishment_number')" rules="establishmentNumber" :required="true" v-model="company.establishmentNumber"/>
            <p class="text-gray text-sm">{{ $t('onboarding.recruiter.personal.info.establishment_number') }}</p>
          </div>


          <!--    ADDRESS      -->
          <div class="panel">
            <h3>Adresgegevens</h3>

            <AddressAutoComplete
                @successAddress="successAddress"
                @errorAddress="errorAddress"
            />

            <InputText id="street" :placeholder="$t('general.street')" :required="true" v-model="address.street" :disabled="true"/>
            <InputText id="city" :placeholder="$t('general.city_name')" :required="true" v-model="address.city" :disabled="true"/>
            <InputText id="province" :placeholder="$t('general.province')" :required="true" v-model="address.province" :disabled="true"/>
          </div>


          <!--    CONTACT      -->
          <div class="panel">
            <h3>{{ $t('onboarding.recruiter.personal.titles.contact') }}</h3>
            <InputText id="firstname" :placeholder="$t('general.firstname')" :required="true" v-model="contact.firstname"/>
            <InputText id="lastname" :placeholder="$t('general.lastname')" :required="true" v-model="contact.lastname"/>
            <InputText id="phone" :placeholder="$t('general.phone')" :required="true" v-model="contact.phone"/>
          </div>


          <!--    INVOICE     -->
          <div class="panel">
            <h3>Facturatie</h3>

            <InputText id="withRegardTo" :placeholder="`T.A.V`" :required="true" v-model="invoice.withRegardTo"/>
            <InputEmail id="email" :placeholder="$t('general.email')" :required="true" v-model="invoice.emailInvoice"/>
            <InputSelect id="paymentTerm" :selected="invoice.paymentTerm" :options="paymentTermsOptions" :placeholder="`betaalvoorwaarden`" :required="true" @onChange="invoice.paymentTerm = $event"/>

            <hr class="my-6">

            <InputCheckbox id="useGAccount" v-model="invoice.useGAccount">
              <div class="text-gray text-sm">
                gedeelte op G-rekening betalen
              </div>
            </InputCheckbox>
            <div v-if="invoice.useGAccount" class="mt-2">
              <InputText id="gAccountPercentage" :placeholder="`Percentage (max 40%)`" :required="true" v-model="invoice.gAccountPercentage"/>
            </div>

            <hr class="my-6">

            <InputSelect id="cao" :selected="invoice.cao" :options="caoList" :placeholder="$t('general.cao')" :required="!invoice.hasOtherCao" @onChange="invoice.cao = $event"/>
            <InputCheckbox id="otherCao" v-model="invoice.hasOtherCao">
              <div class="text-gray text-sm">
                {{ $t('onboarding.company.personal.other_cao') }}
              </div>
            </InputCheckbox>
            <div v-if="invoice.hasOtherCao">
              <InputTextarea id="otherCao" :required="true" v-model="invoice.otherCao"/>
            </div>
          </div>


          <!--    ACCOUNT      -->
          <div class="panel">
            <h3>{{ $t('onboarding.personal.titles.inlog_data') }}</h3>
            <InputEmail id="email" :placeholder="$t('general.email')" autocomplete="off" :required="true" v-model="contact.email"/>
            <InputEmail id="email" :allowPaste="false" :placeholder="$t('general.email_valid')" autocomplete="off" :required="true" v-model="contact.emailToValidate"/>
            <InputPassword id="password" :placeholder="$t('general.password')" autocomplete="off" :required="true" v-model="contact.password"/>
          </div>


          <!--    CHECKS & BALANCES      -->
          <InputCheckbox id="privacyCheck" v-model="privacyChecked">
            <div class="text-gray text-sm">
              {{ $t('onboarding.personal.requirements.privacy_check') }}
              <a :href="termsAnConditionsUrl" class="text-gray-darker" target="_blank">{{ $t('general.terms_and_conditions') }}</a>
              {{ $t('general.and') }}
              <a :href="privacyStatementUrl" class="text-gray-darker" target="_blank">{{ $t('general.privacy_statement') }}</a>
            </div>
          </InputCheckbox>
        </ValidationObserver>
        <p class="text-center text-sm my-8" v-html="$t('onboarding.company.personal.info.quality_disclaimer', {
                days: '<span class=\'text-orange\'>' + $t('onboarding.company.personal.info.quality_disclaimer_days') + '</span>'
            })"></p>
        <div class="alert" v-if="!emailValidated">{{ $t('onboarding.personal.requirements_feedback.email_validate') }}</div>
        <div class="alert" v-if="!privacyChecked">{{ $t('onboarding.personal.requirements_feedback.privacy_check') }}</div>
        <div class="alert" v-if="loginFeedback">{{ loginFeedback }}</div>
        <NavNext nextRouteName="onboarding_appointment" :buttonText="$t('general.next')" @submit="validateForm" ref="NavNext"/>

      </div>
    </div>
  </div>
</template>

<script>
import AddressAutoComplete from "@/components/input_fields/groups/AddressAutoComplete";
import constants from "@/settings/constants";

export default {
  components: {
    AddressAutoComplete
  },

  data: () => ({
    dataFetched: false,
    phoneCountry: '',
    coaList: [],
    hasOtherCao: false,
    paymentTermsOptions: [
      {id: '7', value: '7', title: '7 dagen'},
      {id: '14', value: '14', title: '14 dagen'},
      {id: '21', value: '21', title: '21 dagen'},
      {id: '30', value: '30', title: '30 dagen'},
      {id: '45', value: '45', title: '45 dagen'},
      {id: '60', value: '60', title: '60 dagen'},
      {id: '90', value: '90', title: '90 dagen'},
    ],

    company: {
      kvk: '',
      establishmentNumber: '',
      companyName: '',
      website: '',
      companyPhone: '',
      workingHours: ''
    },

    invoice: {
      cao: '',
      otherCao: '',
      withRegardTo: '',
      emailInvoice: '',
      useGAccount: false,
      gAccountPercentage: '',
      paymentTerm: ''
    },

    address: {
      zipCode: '',
      houseNumber: '',
      street: '',
      city: '',
      province: '',
      addressCoordinates: {},
    },

    contact: {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      emailToValidate: '',
      password: '',
    },

    privacyChecked: false,
    loginFeedback: null,

    termsAnConditionsUrl: process.env.VUE_APP_PUBLIC_BACKEND_URL + 'data/gebruikersvoorwaarden-my-shifts.pdf',
    privacyStatementUrl: process.env.VUE_APP_PUBLIC_BACKEND_URL + 'data/privacyverklaring-my-shifts.pdf',
  }),


  computed: {
    caoList: function () {
      return this.$store.getters['cao/get_cao_list'];
    },

    emailValidated: function () {
      return this.contact.email === this.contact.emailToValidate;
    },
  },


  methods: {
    validateForm() {
      this.$refs.form.validate().then((validated) => {
        if (validated && this.privacyChecked && this.emailValidated) {
          const data = this.buildUserData();
          this.$store.dispatch('onboardingGeneral/set_intake_user_group', {userGroup: constants.USERGROUP_EMPLOYER});

          this.$store.dispatch('user/user_create_action', data).then((response) => {
            this.handleResponse(response.data)
          });
        }
      });
    },

    buildUserData() {
      return {
        ...this.company,
        ...this.contact,
        ...this.address,
        ...this.invoice,

        usergroups: this.$constants.USERGROUP_EMPLOYER,
        addressCoordinates: this.address.addressCoordinates,
      }
    },

    handleResponse(response) {
      if (response.message === 'username_taken') {
        this.loginFeedback = this.$t('onboarding.personal.feedback.username_taken');
      }

      if (response.message === 'establishment_number_invalid') {
        this.loginFeedback = this.$t('onboarding.personal.feedback.establishment_number_invalid');
      }

      if (response.message === 'user_not_created') {
        this.loginFeedback = this.$t('onboarding.personal.feedback.user_not_created');
      }

      if (response.message === 'user_created') {
        this.$store.dispatch('onboardingGeneral/set_calendly_info', {province: this.address.province, jobGroup: ''});
        this.successToast('Account aangemaakt. Je ontvangt via e-mail verdere instructies.', 'onboarding_appointment')
      }
    },

    successAddress(data) {
      this.address.street = data.street;
      this.address.houseNumber = data.houseNumber;
      this.address.city = data.city;
      this.address.zipCode = data.zipCode;
      this.address.province = data.province;
      this.address.addressCoordinates = data.addressCoordinates;
    },

    errorAddress() {
      this.address.street = '';
      this.address.city = '';
      this.address.province = '';
    },
  },

  async created() {
    await this.$store.dispatch('cao/fetch_all_cao_action');
    this.dataFetched = true;
  },


};
</script>
