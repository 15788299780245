<template>
  <div class="form-group">
    <div class="border border-gray-light rounded mt-2" :class="{ 'bg-orange': checked, 'bg-noise-top': checked, 'text-white': checked }">
      <label class="flex px-4 h-12 block items-center cursor-pointer"
      >
        <div class="flex w-full">
          <span v-if="checked" class="mr-3 text-black">
            <i class="icon-checkmark"/>
          </span>
          <input type="checkbox" class="opacity-0 absolute"
                 :id="id"
                 :checked="checked"
                 @input="$emit('input', $event.target.checked)"
          >
         <slot></slot>
        </div>
      </label>

    </div>
  </div>
</template>

<style scoped>
    input:checked + i {
        display: block;
    }
</style>

<script>
export default {
    props: {
        id: {
            required: true,
            type: String,
        },

        required: {
            required: false,
            type: Boolean,
        },

        checked: {
            required: false,
            type: Boolean,
        },

    },
}
</script>
