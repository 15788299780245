<template>
  <div class="flex w-full space-x-3 items-center">
    <TimeField

        @timeChanged="changeStartTime($event)"
        :placeholder="'Van'"
    />
    <span class="">-</span>
    <TimeField
        @timeChanged="changeEndTime($event)"
        :placeholder="'Tot'"
        ref="endTime"
    />

<!--    <TimeField-->
<!--        @timeChanged="changeStartTime($event)"-->
<!--        :placeholder="'Van'"-->
<!--    />-->
  </div>
</template>

<script>
import TimeField from "@/components/date-time/TimeField";

export default {


  components: {
    TimeField,
  },

  data: () => ({
    startTime: '',
    endTime: '',
  }),

  props: {
    minShiftDuration: {
      type: Number,
      required: true,
    },
    maxShiftDuration: {
      type: Number,
      required: true,
    }
  },

  methods: {
    changeStartTime(time) {
      this.$refs.endTime.time = '';
      this.startTime = time;
      this.$emit('startTimeChanged', this.startTime);
    },

    changeEndTime(time) {
      this.endTime = time;
      this.shiftDurationCheck();
      this.$emit('endTimeChanged', this.endTime);
    },

    shiftDurationCheck() {
      let hoursDiff = this.$moment(this.endTime).diff(this.startTime, 'h');
      const totalHoursInDay = 24;

      if (hoursDiff < 0) {
        hoursDiff = hoursDiff + totalHoursInDay;
      }

      if (hoursDiff < this.minShiftDuration || hoursDiff > this.maxShiftDuration) {
        this.shiftDurationIncorrectAlert()

        setTimeout(() => {
          this.$refs.endTime.time = '';
        }, 500)
      }
    },

    shiftDurationIncorrectAlert() {
      return this.$swal({
        title: 'Shiftduur onjuist',
        text: `Let op! De shift moet minimaal ${this.minShiftDuration}uur en mag maximaal ${this.maxShiftDuration}uur duren.`,
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
        focusConfirm: false,
      });
    },
  },


}
</script>
