<template>
  <div>
    <PageTitle title="Financieel" :centered="true" />

    <div class="container mx-auto px-4">
      <component :is="componentByUserType" :JEXdata="JEXdata"></component>
    </div>

  </div>
</template>

<script>
import constants from "@/settings/constants";

import JEXemployer from "@/components/JEX/JEXemployer";
import JEXselfEmployed from "@/components/JEX/JEXselfEmployed";
import JEXtemporaryEmployed from "@/components/JEX/JEXtemporaryEmployed";

export default {
  components: {
    JEXselfEmployed,
    JEXtemporaryEmployed,
    JEXemployer,
  },

  data: () => ({
    JEXdata: {
      JEXphone: process.env.VUE_APP_JEX_PHONE,
      JEXphoneVisual: process.env.VUE_APP_JEX_PHONE_VISUAL,
      JEXemailSalary: process.env.VUE_APP_JEX_MAIL_SALARY,
      JEXemailSelfEmployed: process.env.VUE_APP_JEX_MAIL_SELF_EMPLOYED,
      JEXkvk: process.env.VUE_APP_JEX_KVK,

      JEXstreet: process.env.VUE_APP_JEX_STREET,
      JEXhouseNumber: process.env.VUE_APP_JEX_HOUSE_NUMBER,
      JEXcity: process.env.VUE_APP_JEX_CITY,
      JEXzip: process.env.VUE_APP_JEX_ZIP,
    }
  }),

  computed: {
    componentByUserType: function () {
      const user = this.$store.getters['user/get_user'];

      switch (user.group) {
        case constants.USERGROUP_SELF_EMPLOYED:
          return 'JEXselfEmployed';
        case constants.USERGROUP_TEMPORARY_EMPLOYED:
          return 'JEXtemporaryEmployed';
        case constants.USERGROUP_EMPLOYER:
          return 'JEXemployer';
        default:
          return 'JEXemployer';
      }
    }
  },


}
</script>
