<template>
  <div>    
    <ul class="mb-8">
      <li>Zorg altijd voor een geregistreerde shift in het systeem. Zonder shift kun je niet klokken en krijg je ook niet betaalt. (Geen discussie over mogelijk)</li>
      <li>Klok je uren binnen 24 uur na je gewerkte shift, anders komen deze niet op je factuur.</li>
      <li>Stuur eventuele declaraties of uitzondering uiterlijk maandag ochtend voor 12:00 uur door naar <a href="mailto:crediteuren@myshifts.nl">crediteuren@myshifts.nl</a></li>
      <li>Op woensdag genereert ons systeem automatisch de factuur voor de voorafgaande week.</li>
      <li>Binnen 48 uur controleren wij of alles klopt en krijg je de factuur van ons toegestuurd.</li>
      <li>Als je de factuur hebt ontvangen doen wij ons best om deze binnen 21 dagen uit te betalen. (30 dagen is de uiterlijke betaaltermijn).</li>
      <li>Als je vragen hebt over facturatie en betalingen lopen deze via onze finance afdeling, deze is alleen bereikbaar via het onderstaande mailadres</li>
    </ul>
    <p class="text-sm text-gray-dark">My Shifts finance contact mail: <a href="mailto:crediteuren@myshifts.nl">crediteuren@myshifts.nl</a></p>
    <hr class="my-8" />
    <ul class="mb-8">
      <li>Always have a registered shift in the system. Without a shift you can't clock and you won't get paid. (No discussion possible)</li>
      <li>Book your hours within 24 hours of your shift worked, otherwise they will not show up on your invoice.</li>
      <li>Send any claims or exceptions to <a href="mailto:crediteuren@myshifts.nl">crediteuren@myshifts.nl</a> by Monday morning before 12:00.</li>
      <li>On Wednesday, our system automatically generates the invoice for the previous week.</li>
      <li>Within 48 hours we will check if everything is correct and we will send you the invoice.  </li>
      <li>Once you receive the invoice, we do our best to pay it within 21 days. (30 days is the final payment term).</li>
      <li>If you have any questions about invoicing and payments, they go through our finance department, which can only be reached at the email address below.</li>
    </ul>
    <p class="text-sm text-gray-dark">My Shifts finance contact mail: <a href="mailto:crediteuren@myshifts.nl">crediteuren@myshifts.nl</a></p>
  </div>
</template>

<script>
export default {
  props:{
    JEXdata:{
      type: Object,
      required:true,
    }
  },
}
</script>
