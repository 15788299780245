import AxiosClient from "@/config/axios/axios";
import ErrorService from "@/services/errors/ErrorService";
const resource = "/job-group";

export default {
    getAll() {
        return AxiosClient.get(`${resource}/get/all`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                ErrorService.onError(error)
            })
    },
}
