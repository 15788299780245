<template>
  <div v-if="dataFetched">
    <div class="bg-black bg-noise-top text-white py-4">
      <div class="flex flex-col items-center text-center p-4">
        <a class="no-underline mt-2 mb-4" href="/">
          <LogoBlackSvg/>
        </a>

        <h1 v-html="$t('onboarding.education.header.title', {
                jobGroup: jobGroup,
            })"></h1>

        <p class="lead">{{ $t('onboarding.education.header.subtitle') }}</p>
      </div>
    </div>
    <div class="black-bar mb-4"></div>

    <ValidationObserver ref="form">
      <div class="container mx-auto p-4">
        <div class="flex flex-col">
          <div class="panel">
            <h3>{{ $t('onboarding.education.titles.completed_educations') }}</h3>
            <div v-for="(education, index) in educations" :key="index">
              <CheckboxGroup
                  :id="education.id"
                  :label="education.title"
                  :value="education.id"
                  :checked="selectedEducations"
                  :none-option="true"
                  @emitCheckedId="updateSelectedArray(selectedEducations, $event)"/>
            </div>

            <InputCheckbox2 :id="`2`" :checked="hasOtherEducations" v-model="hasOtherEducations">{{ $t('general.other') }}</InputCheckbox2>
            <div v-if="hasOtherEducations">
              <InputTextarea id="extraEducations" :required="true" v-model="extraEducations" :placeholder="$t('onboarding.education.placeholders.other_educations')"/>
            </div>

          </div>


          <div class="panel">
            <h3>{{ $t('onboarding.education.titles.completed_certificates') }}</h3>
            <div v-for="(certificate, index) in certificates" :key="index">
              <CheckboxGroup :id="certificate.id"
                             :label="certificate.title"
                             :value="certificate.id"
                             :checked="selectedCertificates"
                             @emitCheckedId="updateSelectedArray(selectedCertificates, $event)"/>
            </div>

            <InputCheckbox2 :id="`2`" :checked="hasOtherCertificates" v-model="hasOtherCertificates">{{$t('general.other')}}</InputCheckbox2>
            <div v-if="hasOtherCertificates">
              <InputTextarea id="extraCertificates" :required="true" v-model="extraCertificates" :placeholder="$t('onboarding.education.placeholders.other_certificates')"/>
            </div>

          </div>


          <div class="panel">
            <h3>{{ $t('onboarding.education.titles.specialisations') }}</h3>
            <SpecialisationList :specialisations="specialisations"/>

            <!-- @todo On enter add specialisation when not empty and keep focus on input -->
            <InputText id="specialisation" :placeholder="`Specialisatie`" :required="false" v-model="specialisation" @onEnter="addSpecialisation()"/>
            <button type="button" class="text-orange text-left -mt-1 mb-4 hover:underline" @click="addSpecialisation()">
              + {{ $t('onboarding.education.add_specialisation') }}
            </button>
          </div>
        </div>

        <div class="alert" v-if="!educationChosen && !this.hasOtherEducations">{{ $t('onboarding.education.alert.education') }}</div>
        <div class="alert" v-if="!certificateChosen">{{ $t('onboarding.education.alert.certificate') }}</div>

        <NavNext
            nextRouteName="onboarding_experience_jobs"
            :buttonText="$t('general.next')"
            @submit="validateForm"
            ref="NavNext"
        />
      </div>
    </ValidationObserver>

    <NavPrevious @submit="setStoreData" prevRouteName="onboarding_experience_job_group_options"/>

  </div>
</template>

<script>
import SpecialisationList from "@/components/onboarding/SpecialisationList";

export default {

  components: {
    SpecialisationList,
  },

  data: () => ({
    dataFetched: false,

    educations: {},
    certificates: {},
    specialisation: '',
    extraEducations: '',
    extraCertificates: '',

    hasOtherEducations: false,
    hasOtherCertificates: false,

    selectedEducations: [],
    selectedCertificates: [],
  }),

  computed: {
    specialisations: function () {
      return this.$store.getters['onboardingExperience/get_specialisations_list'];
    },
    jobGroup() {
      switch (this.$store.getters['onboardingExperience/get_job_group']) {
        case this.$constants.JOB_GROUP_KITCHEN_CREW:
          return 'Kitchen Crew';
        case this.$constants.JOB_GROUP_HOSPITALITY:
          return 'Hospitality';
        case this.$constants.JOB_GROUP_FACILITY:
          return 'Facilitair';
        default:
          return '...';
      }
    },
    educationChosen: function () {
      return this.selectedEducations.length > 0;
    },
    certificateChosen: function () {
      return this.selectedCertificates.length > 0;
    },
  },

  methods: {
    validateForm() {
      this.$refs.form.validate().then(async (validated) => {
        if (validated && (this.educationChosen || this.hasOtherEducations) && (this.certificateChosen || this.hasOtherCertificates)) {
          this.setStoreData();
          this.$refs.NavNext.navigate();
        }
      });
    },

    addSpecialisation() {
      if (!this.specialisation) {
        return;
      }

      this.$store.dispatch('onboardingExperience/set_specialisation', this.specialisation);
      this.specialisation = '';
    },

    setStoreData() {
      this.$store.dispatch('onboardingExperience/set_educations', this.selectedEducations);
      this.$store.dispatch('onboardingExperience/set_certificates', this.selectedCertificates);
      this.$store.dispatch('onboardingExperience/set_extra_educations', this.extraEducations);
      this.$store.dispatch('onboardingExperience/set_extra_certificates', this.extraCertificates);
    },

    getStoreData() {
      this.selectedEducations = this.$store.getters['onboardingExperience/get_educations'];
      this.selectedCertificates = this.$store.getters['onboardingExperience/get_certificates'];
      this.extraEducations = this.$store.getters['onboardingExperience/get_extra_educations'];
      this.extraCertificates = this.$store.getters['onboardingExperience/get_extra_certificates'];
    },

    getEducationsList() {
      return this.$store.dispatch('education/fetch_all_educations_action').then((educationsList) => {
        this.educations = educationsList[this.$store.getters['onboardingExperience/get_job_group']];
      });
    },

    getCertificatesList() {
      return this.$store.dispatch('certificate/fetch_all_certificates_action').then((certificatesList) => {
        this.certificates = certificatesList[this.$store.getters['onboardingExperience/get_job_group']]
      });
    },

    updateSelectedArray(array, id) {
      // if doesnt exist add to array, else remove from array
      if (array.indexOf(id) !== -1) {
        array.splice(array.indexOf(id), 1);
      } else {
        array.push(id);
      }
    },

  },

  async created() {
    this.$store.dispatch('general/startloading');
    this.getStoreData();
    await this.getCertificatesList();
    await this.getEducationsList();
    this.dataFetched = true;
    this.$store.dispatch('general/endloading');
  }
}
</script>

