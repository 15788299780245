import store from "@/store/store";


export default (axiosConfig) => {

    axiosConfig.interceptors.request.use(async (config) => {
        const CSRF_TOKEN = store.getters["auth/get_csrf_token"];
        config.headers['X-CSRF-Token'] = CSRF_TOKEN.value;

        return config
        }, (error) => {
                console.log('EEE', error);
        });

}
