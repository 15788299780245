import onboardingService from '@/services/onboarding/onboardingService'
import LocalStorageVersioningService from "@/services/localstorage/LocalStorageVersioningService";
import i18n from "@/config/i18n";
import Vue from 'vue';


export default (router) => {
    router.beforeEach((to, from, next) => {

        if (!LocalStorageVersioningService.getUserVersion()) {
            LocalStorageVersioningService.setVersion();
        }

        if (!LocalStorageVersioningService.isLatestVersion()) {
            LocalStorageVersioningService.clearLocalStorage();
            LocalStorageVersioningService.setVersion();

            if(onboardingService.isOnboarding()){
                Vue.swal({
                    title: i18n.t('onboarding.back_to_onboarding_login.alert.title'),
                    text: i18n.t('onboarding.back_to_onboarding_login.alert.text'),
                    showCloseButton: true,
                    showCancelButton: false,
                    focusConfirm: false,
                    confirmButtonColor: '#FF5F00',
                })
                next({name: 'login'});
            }else {
                next();
            }
        }
        next();
    })
}

