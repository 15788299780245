<template>
  <div>
    <PageTitle>
      <template v-slot:pageTitleLeft>
        <LocationSelect v-if="organisation.locations && dataFetched" :options="organisation.locations" @locationChange="sendFilterRequest()" />
      </template>
      <template v-slot:pageTitleRight>
        <button-primary bg-color="black" route-name="shift_create" text="+ nieuwe shift"></button-primary>
      </template>
    </PageTitle>
    
    <ShiftOverviewPaginated v-if="dataFetched"
        :pagination-results="paginationResults"
        ref="ShiftOverviewPaginated"
        @getNextPageResults="getPaginationResults($event)"
        @filterRequest="sendFilterRequest()"
    >    
      <template v-slot:shiftItem="shifts">
        <EmployerShiftItem v-for="(shift, index) in shifts.data"
            :key="index"
            :shift="shift"
            :fixed-width="false"/>
      </template>
    </ShiftOverviewPaginated>

    <div v-if="shift_requirements_employers">
      <Overlay @closeOverlay="shift_requirements_employers = false;">
        <div class="relative">
            <div class="contract__container mb-32">
              <template>
                <ul>
                  <li>Minimale shiftduur:
                    <ul class="leading-none mt-2">
                      <li>Keuken: 6 uur</li>
                      <li>Hospitality: 4 uur</li>
                      <li>Facility: 3 uur</li>
                    </ul>
                  </li>
                  <li>Annuleren kan tot 24 uur van tevoren bij ZZP'ers</li>
                  <!-- <li>Annuleren uitzendkrachten kan tot 96 uur van tevoren (uitzondering horeca cao)</li> -->
                  <li>Wijzigingen van shifts kun je tot 24 uur voor aanvang van de shift bij ons doorgeven</li>
                  <li>Wij verstrekken geen contactgegevens van gebruikers</li>
                  <li>Als er geen geaccepteerde shift in het systeem staat mag er ook niemand werken! Mensen zijn niet verzekerd en het is een risico voor ons als platform. Met ingang van 21-10-22 staat er een direct opeisbare boete van €100,- per shift tegenover. DOE HET DUS NIET!</li>
                </ul>                
                <p>
                  Met vriendelijke groet,<br />
                  My Shifts team.
                </p>
              </template>
            </div>
            <div class="fixed bottom-0 left-0 right-0 mx-auto max-w-lg p-6 pt-0 bg-white bg-opacity-90">
                <ButtonPrimary class="mt-4" text="Accepteren" @click.native="shift_requirements_employers = false;" />
            </div>
        </div>
      </Overlay>
    </div>

  </div>
</template>

<script>
import shiftRepository from "@/repositories/shiftRepository";
import EmployerShiftItem from "@/components/shifts/EmployerShiftItem";
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
import ShiftOverviewPaginated from "@/components/shifts/ShiftOverviewPaginated";
import LocationSelect from '@/components/shifts/LocationSelect';
import organisationRepository from "@/repositories/organisationRepository";
import Overlay from "@/components/Overlay";

export default {
  name: 'ShiftOverviewEmployer',

  components: {
    EmployerShiftItem,
    ButtonPrimary,
    ShiftOverviewPaginated,
    LocationSelect,
    Overlay
  },

  data: () => ({
    dataFetched: false,
    shift_requirements_employers: false,
    paginationResults: {},
    organisation: {} // Contains organisation name of current user and the sub locations
  }),

  watch: {
    dataFetched: function() {
      if (this.dataFetched == true) {
        this.shift_requirements_employers = true;
      }
    }
  },

  methods: {
    async checkForFilters() {
      let filters = await this.$store.getters['shift/get_filters'];
      return filters;
    },

    async setDefaultFilters() {
      await this.$store.dispatch('shift/set_default_filters');
    },

    async getPaginationResults(pageNr) {
      const filters = await this.checkForFilters();
      this.paginationResults = await shiftRepository.getFilteredPaginatedForEmployer(pageNr, filters);
    },

    clearShifts: function() {
      this.$refs.ShiftOverviewPaginated.clearShifts();
      this.$refs.ShiftOverviewPaginated.clearPaginationInfo();
    },

    async sendFilterRequest() {
      this.$store.dispatch('general/startloading');
      const filters = await this.checkForFilters();
      if (this.dataFetched) {
        this.clearShifts();
      }
      this.paginationResults = await shiftRepository.getFilteredPaginatedForEmployer(1, filters);
      this.$store.dispatch('general/endloading');
    }
  },

  async created() {
    await this.setDefaultFilters();
    this.$store.dispatch('general/startloading');
    await this.sendFilterRequest();
    this.$store.dispatch('general/endloading');
    this.organisation = await organisationRepository.get();
    this.dataFetched = true;
  },
}
</script>
