<template>
  <div v-if="dataFetched">
    <ShiftOverviewPaginated
        :pagination-results="paginationResults"
        :text="{
          title: 'Gewerkte shifts',
          subtitle: 'Alle shifts die al gewerkt en gesloten zijn',
          noneAvailable: 'Geen gewerkte shifts beschikbaar..',
          button:'Laad meer shifts'
        }"
        :hasPassed="true"
        @getNextPageResults="getPaginationResults($event)"
    >


      <template v-slot:shiftItem="shifts">
        <ShiftItem v-for="(shift, index) in shifts.data"
                   :key="index"
                   :shift="shift"
                   :fixed-width="false"
                   :hasPassed="true"
        />
      </template>


    </ShiftOverviewPaginated>
  </div>
</template>

<script>
import shiftRepository from "@/repositories/shiftRepository";
import ShiftOverviewPaginated from "@/components/shifts/ShiftOverviewPaginated";
import ShiftItem from "@/components/shifts/ShiftItem";

export default {
  components: {
    ShiftOverviewPaginated,
    ShiftItem
  },

  data: () => ({
    dataFetched: false,
    paginationResults: {},
  }),

  methods: {
    async getPaginationResults(pageNr) {
      this.paginationResults = await shiftRepository.getPaginatedPastForEmployee(pageNr);
    }
  },

  async created() {
    this.$store.dispatch('general/startloading');
    await this.getPaginationResults(1)
    this.$store.dispatch('general/endloading');
    this.dataFetched = true;
  },
}
</script>
