<template>
<div class="border-t border-gray-light mt-6 mb-6">
    <div v-for="(user, index) in users" :key="index" class="border-b border-gray-light p-4" :class="itemClass">
        <div class="flex items-center ">
            <ProfileImage class="mr-4" :user="user" />
            <div>
                <div class="mb-2">{{ user.firstName }} {{ user.lastName }}</div>
                <span class="text-sm text-gray">Aangemeld: {{ user.dateCreated | moment("LL") }}</span>
            </div>
        </div>

        <div v-if="user.notes" class="mt-3 text-gray-darker text-sm">
            <strong>Opmerking:</strong>
              <p v-html="user.notes"></p>
        </div>
    </div>
</div>
</template>

<script>
import ProfileImage from "@/components/users/ProfileImage";

export default {
    components: {
        ProfileImage,
    },

    props: {
        itemClass: {
            type: String,
            default: ''
        },
        users: {
            type: Array,
            required: true,
        }
    }
}
</script>
