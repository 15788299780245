<template>
  <button v-if="isOnboarding()" class="button button--primary" @click="redirectToOnboarding">{{$t('onboarding.back_to_onboarding.button_text')}}</button>
</template>

<script>
import onboardingService from '@/services/onboarding/onboardingService'

export default {
  data: () => ({}),

  methods: {
    alertBackToOnboarding() {
      return this.$swal({
        title: this.$t('onboarding.back_to_onboarding.alert.title'),
        text: this.$t('onboarding.back_to_onboarding.alert.text'),
        confirmButtonText: this.$t('onboarding.back_to_onboarding.button_text'),
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#FF5F00',
      }).then((result) => {
        return result.isConfirmed;
      })
    },

    handleBackToOnboarding: async function () {
      let isConfirmed = false;
      isConfirmed = await this.alertBackToOnboarding()
      if (isConfirmed) {
       this.redirectToOnboarding();
      }
    },

    redirectToOnboarding(){
      this.$router.push({name: 'onboarding_experience_job_group_options'});
    },

    isOnboarding(){
      return onboardingService.isOnboarding()
    },

  },

  created() {
    if (this.isOnboarding()) {
      this.handleBackToOnboarding();
    }
  }


}
</script>

