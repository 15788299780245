import repository from "@/repositories/repository";
import * as authService from "@/services/authentication/authService.js";
import {router} from "@/router/router";
const authRepository = repository.get("auth");


const state = () => ({
    csrfToken: ''
})

const mutations = {
    SET_CSRF_TOKEN(state, csrfToken) {
        state.csrfToken = csrfToken
    },
}

const actions = {

    login_action: async ({commit}, {username, password}) => {
        const response = await authRepository.login(username, password)
        const {status, data} = response

        if (status === 200) {
            if(response.data.message === 'login_success' || response.data.message === 'login_success_first_login'){
                commit('user/SET_USER', data.user, { root: true })
                authService.saveUserToLocalStorage(data.user);
            }
            return response.data;
        } else {
            return response.data;
        }
    },

    login_as_user_action: async ({commit}, {uid}) => {
        const response = await authRepository.loginAsUser(uid)
        const {status, data} = response

        if (status === 200) {
            if(response.data.message === 'login_success' || response.data.message === 'login_success_first_login'){
                commit('user/SET_USER', data.user, { root: true })
                authService.saveUserToLocalStorage(data.user);
            }
            return response.data;
        } else {
            return response.data;
        }
    },


    async logout_action() {



        await authRepository.logout()



        authService.removeUserFromLocalStorage();
        router.push({name: 'login'});
    },


    async password_reset_init_action(state, {pwrUsername}) {
        const response = await authRepository.passwordResetInit(pwrUsername)
        return response.data;
    },


    async password_reset_action(state, {newPassword, code, id}) {
        const response = await authRepository.passwordReset(newPassword, code, id)
        return response.data;
    },


    async reset_refresh_token() {
        return await authRepository.resetRefreshToken()
    },


    async get_csrf_token_action({commit}) {
        const response = await authRepository.getCsrf();
        commit('SET_CSRF_TOKEN', response);
    },

}


const getters = {
    /*EMPLOYEE ALL SHIFTS*/
    get_csrf_token: (state) => {
        return state.csrfToken;
    },
};


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
