<template>
  <div class="-mt-6" v-if="dataFetched">
    <ShiftDetailEmployerHeader :shift="shift"></ShiftDetailEmployerHeader>

    <div class="flex flex-col items-center text-center px-6 py-4">
      <div class="w-full mt-4 text-left relative">
        <h2 class="text-2xl font-normal mb-2">{{ shift.amountEmployeesAccepted }} / {{ shift.amountEmployees }} aanmeldingen</h2>
        <p class="text-gray-dark">Maak een keuze</p>


        <Slick ref="slick" :options="slickOptions_mixin" class="mb-8 slick-slider--no-margin">
          <template v-for="(registrar,index) in shift.registrars">
            <div :key="index">
              <ShiftRegistrar
                  :registrar="registrar"
                  :shift="shift"
                  @accept="accept"
                  @decline="decline"
                  @refreshData="getShift()"
              />
            </div>
          </template>
        </Slick>

        <div class="block">
          <h3>Wat wordt er van je verwacht?</h3>
          <div v-html="shift.descriptionWork"></div>
        </div>

        <div class="block">
          <h3>Vereiste skills</h3>
          <div v-html="shift.requiredSkills"></div>
        </div>

        <div class="block">
          <h3>Vereiste talen</h3>
          <Flag class="mr-2" v-for="(language, index) in shift.requiredLanguages" :code="language.code" :key="index"/>
        </div>

        <div class="block">
          <h3>Waar moet je je melden?</h3>
          <div v-html="shift.placeToReport"></div>
        </div>

        <div class="block">
          <h3>Waar kun je parkeren?</h3>
          <div v-html="shift.parkingInstructions"></div>
        </div>

        <div class="block">
          <h3>Aanvullende eisen en opmerkingen</h3>
          <div v-html="shift.additionalDescription"></div>
        </div>
      </div>


      <!-- CANCEL / UNSUBSCRIBE SHIFT -->
      <div class="w-full mt-4 text-left relative ">
        <template v-if="!cancelDateHasPassed">
          <button class="button button--outline" @click="cancelShift(shift.id)">Shift Annuleren</button>
        </template>
        <template v-if="cancelDateHasPassed">
          <button class="button button--outline" @click="unsubscribeShift(shift.id)">Shift Afmelden</button>
        </template>
      </div>


      <!-- OVERLAYS -->
      <!--  overlay: accept initial situation   -->
      <div v-if="overlay.accept">
        <Overlay @closeOverlay="overlay.accept = false">
          <div class="flex items-center mb-4">
            <img class="rounded-full w-20 h-20 block mr-4" :src="registrar.user.imageUrl"/>
            <div class="text-left">
              <h3>{{ registrar.user.firstName }}</h3>
              <span class="text-sm rounded bg-black text-white py-1 px-2 inline-block">{{ userGroup_mixin(registrar.user.group) }}</span>
            </div>
          </div>

          <div class="flex justify-between mb-1 text-sm">
            <span>Uurtarief</span>
            <span>&euro; {{ parseFloat(registrar.labourCost).toFixed(2) }}</span>
          </div>
          <div class="flex justify-between mb-3 text-sm">
            <span>Reiskosten ({{ registrar.distance }} km)</span>
            <i v-if="registrar.distance > 180" class="text-xs text-grey">* maximale reisafstand die vergoed wordt is 180km</i>
            <span>&euro; {{ registrar.travelAllowance.total }}</span>
          </div>
          <div class="flex justify-between mb-3 text-sm border-t border-gray-light pt-3">
            <span>Totale kosten shift</span>
            <span>&euro; {{ shiftCosts }}</span>
          </div>

          <div class="rounded mb-3 py-2 px-4 border border-gray-light bg-yellow bg-opacity-10 text-brown text-xs text-left">
            <strong>Let op:</strong> toeslagen, overuren, inlenersbeloning en andere extra’s zijn hier niet in meegenomen
          </div>

          <button class="button button--primary" @click="overlay.accept = false; acceptRegistration(registrar.registrationId)">Bevestig keuze</button>
        </Overlay>
      </div>

      <!-- overlay: accept contract between employer and zzper -->
      <div v-if="overlay.accept_contract">
        <Overlay @closeOverlay="overlay.accept_contract = false">
          <div class="flex items-center mb-4">
            <img class="rounded-full w-20 h-20 block mr-4" :src="registrar.user.imageUrl"/>
            <div class="text-left">
              <h3>{{ registrar.user.firstName }}</h3>
              <span class="text-sm rounded bg-black text-white py-1 px-2 inline-block">{{ userGroup_mixin(registrar.user.group) }}</span>
            </div>
          </div>

          <div class="flex justify-between mb-1 text-sm">
            <span>Uurtarief</span>
            <span>&euro; {{ parseFloat(registrar.labourCost).toFixed(2) }}</span>
          </div>
          <div class="flex justify-between mb-3 text-sm">
            <span>Reiskosten ({{ registrar.distance }} km)</span>
            <i v-if="registrar.distance > 180" class="text-xs text-grey">* maximale reisafstand die vergoed wordt is 180km</i>
            <span>&euro; {{ registrar.travelAllowance.total }}</span>
          </div>
          <div class="flex justify-between mb-3 text-sm border-t border-gray-light pt-3">
            <span>Totale kosten shift</span>
            <span>&euro; {{ shiftCosts }}</span>
          </div>

          <div class="rounded my-3 py-2 px-4 border border-gray-light bg-yellow bg-opacity-10 text-brown text-xs text-left">
            <strong>Let op:</strong> toeslagen, overuren, inlenersbeloning en andere extra’s zijn hier niet in meegenomen
          </div>


            <div class="mx-auto max-w-lg p-6 pt-0 bg-white bg-opacity-90 text-left ">
                <InputCheckbox id="privacyCheck" v-model="termsAccepted">
                  <div class="text-gray-dark text-sm">
                    Ja, ik ga akkoord met de <a :href="'/webservice/api/v1/contract/download?contract=overeenkomst-van-opdracht&user=' + registrar.user.id + '&shift=' + shift.id" target="_blank">Overeenkomst van opdracht</a>
                  </div>
                </InputCheckbox>
                <FormAlert :condition="!termsAccepted">Je kunt je pas inschrijven als je akkoord gaat met de Overeenkomst van opdracht</FormAlert>
                <ButtonPrimary class="mt-4" text="Accepteren" @click.native="overlay.accept_contract = false; acceptRegistration(registrar.registrationId)" :disabled="!termsAccepted"></ButtonPrimary>
            </div>
        </Overlay>
      </div>

      <!--   overlay: decline   -->
      <div v-if="overlay.decline">
        <Overlay @closeOverlay="overlay.decline = false">
          <div class="text-left">
            <h3>Weet je zeker dat je deze persoon wilt afwijzen?</h3>

            <p class="text-sm mb-8">
              Dit is niet meer ongedaan te maken en de persoon kan ook niet meer alsnog gekozen worden.
            </p>

            <InputSelect id="jobGroups"
                         class="mt-6"
                         :options="reasonsDecline"
                         placeholder="Wat is de reden"
                         :required="true"
                         @onChange="reasonDecline = $event"/>

            <button class="button button--primary" @click="overlay.decline = false; declineRegistration(registrar.registrationId)">Bevestig afwijzing</button>
          </div>
        </Overlay>
      </div>
    </div>
  </div>


</template>


<script>
import repository from "@/repositories/repository";
import Overlay from '@/components/Overlay';
import Slick from 'vue-slick';
import ShiftRegistrar from '@/components/shifts/ShiftRegistrar';
import Flag from '@/components/Flag';
import shiftCalculationsMixin from "@/mixins/shiftCalculationsMixin";
import ShiftService from "@/services/ShiftService";
import ShiftDetailEmployerHeader from "@/components/shifts/ShiftDetailEmployerHeader";

import ButtonPrimary from '@/components/buttons/ButtonPrimary';

const shiftRepository = repository.get("shift");
const shiftRegistrationRepository = repository.get("shiftRegistration");


export default {
  mixins: [shiftCalculationsMixin],

  components: {
    Overlay,
    Slick,
    ShiftRegistrar,
    Flag,
    ShiftDetailEmployerHeader,
    ButtonPrimary
  },


  data: () => ({
    dataFetched: false,
    shift: null,
    shiftCosts: null,
    overlay: {
      accept: false,
      accept_contract: false, // Added may 20: employer and zzp'er need to agree to contract
      decline: false,
    },
    registrar: null,
    reasonDecline: null,
    reasonsDecline: [
      {id: 'Niet geschikt', title: 'Niet geschikt'},
      {id: 'Slechte ervaring', title: 'Slechte ervaring'},
      {id: 'Profiel incompleet', title: 'Profiel incompleet'},
      {id: 'Dienst vervallen', title: 'Dienst vervallen'},
      {id: 'Niet meer nodig', title: 'Niet meer nodig'},
      {id: 'Overig', title: 'Overig'},
    ],
    termsAccepted: false
  }),


  methods: {
    accept(registrar) {
      this.registrar = registrar;
      this.calculateShiftCosts(registrar);      
      console.log('The user about to be chosen is ', this.userGroup_mixin(registrar.user.group));
      if (registrar.user.group == 2) {
        console.log('Showing special contract overlay for ZZP');
        this.overlay.accept_contract = true;
      } else {
        this.overlay.accept = true;
      }
    },

    decline(registrar) {
      this.registrar = registrar;
      this.overlay.decline = true;
    },

    acceptRegistration(registrationId) {
      this.$store.dispatch('general/startloading');
      const data = {
        registrationId: registrationId,
      }
      shiftRegistrationRepository.accept(data).then((response) => {
        this.$store.dispatch('general/endloading');
        this.handleAcceptResponse(response);
      });
    },

    declineRegistration(registrationId) {
      this.$modal.hide('decline_registration_modal')
      const data = this.buildDeclineData(registrationId)

      this.$store.dispatch('general/startloading');
      shiftRegistrationRepository.decline(data).then((response) => {
        this.$store.dispatch('general/endloading');
        this.handleDeclineResponse(response);
      });
    },

    buildDeclineData(registrationId) {
      return {
        registrationId: registrationId,
        reasonDecline: this.reasonDecline ?? 'Geen reden',
      }
    },

    //within legal period
    async cancelShift(shiftId) {
      let isConfirmed = await this.alertBeforeCancelShift();
      if (isConfirmed) {
        shiftRepository.cancel(shiftId).then((response) => {
          this.handleCancelResponse(response);
        });
      }
    },

    //outside legal period (has to be paid)
    async unsubscribeShift(shiftId) {
      let isConfirmed = await this.alertBeforeUnsubscribeShift();
      if (isConfirmed) {
        shiftRepository.unsubscribe(shiftId).then((response) => {
          this.handleUnsubscribeResponse(response);
        });
      }
    },

    alertBeforeCancelShift() {
      return this.$swal({
        title: "Annuleer shift",
        text: "Weet je zeker dat je deze shift wilt annuleren? Dit kan niet meer ongedaan worden gemaakt.",
        confirmButtonText: "Annuleer Shift",
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#FF5F00',
      }).then((result) => {
        return result.isConfirmed;
      })
    },

    alertBeforeUnsubscribeShift() {
      return this.$swal({
        title: "Meld shift af",
        text: "Weet je zeker dat je deze shift wilt afmelden? Dit kan niet meer ongedaan worden gemaakt, en de shift zal gewoon uitbetaald moeten worden.",
        confirmButtonText: "Meld shift af",
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#FF5F00',
      }).then((result) => {
        return result.isConfirmed;
      })
    },


    async handleAcceptResponse(response) {
      switch (response.message) {
        case 'shift_registration_updated':
          this.successToast('De registratie van de werknemer is geaccepteerd')
          this.getShift();
          break;
        default:
          this.errorToast('Er ging iets mis met registreren, probeer het later opnieuw.')
      }
    },


    async handleDeclineResponse(response) {
      switch (response.message) {
        case 'shift_registration_updated':
          this.successToast('De registratie van de werknemer is afgewezen')
          this.getShift();
          break;
        default:
          this.errorToast('Er ging iets mis, probeer het later opnieuw.')
      }
    },


    handleCancelResponse(response) {
      switch (response.message) {
        case 'shift_canceled':
          this.successToast('De shift is geannuleerd', 'dashboard_employer')
          break;
        default:
          this.errorToast('Er ging iets mis met annuleren, probeer het later opnieuw.')
      }
    },

    handleUnsubscribeResponse(response) {
      switch (response.message) {
        case 'shift_unsubscribed':
          this.successToast('De shift is afgemeld', 'dashboard_employer')
          break;
        default:
          this.errorToast('Er ging iets mis met afmelden, probeer het later opnieuw.')
      }
    },

    copyShift() {
      const shiftId = this.$route.params.id;
      this.$router.push({name: 'shift_create', query: {shiftId: shiftId}})
    },


    calculateShiftCosts(registrar) {
      this.shiftCosts = (registrar.travelAllowance.total + (this.shift.duration * registrar.labourCost)).toFixed(2);
    },

    async getShift() {
      this.shift = await shiftRepository.getByIdForEmployer(this.$route.params.id);
    }
  },


  async created() {
    this.$store.dispatch('general/startloading');
    await this.getShift();
    (new ShiftService(this.shift)).archiveRedirectCheck('employer');
    this.dataFetched = true;
    this.$store.dispatch('general/endloading');
  }
}
</script>

<style scoped>
.block {
  @apply mb-8 text-xs md:text-sm leading-loose;
}

.block h3 {
  @apply mb-1 text-sm md:text-base font-semibold;
}

</style>
