import InputText from "@/components/input_fields/InputText";
import InputTextarea from "@/components/input_fields/InputTextarea";
import InputNumber from "@/components/input_fields/InputNumber";
import InputRadio from "@/components/input_fields/InputRadio";
import InputEmail from "@/components/input_fields/InputEmail";
import InputPassword from "@/components/input_fields/InputPassword";
import InputDate from "@/components/input_fields/InputDate";
import InputDateTime from "@/components/input_fields/InputDateTime";
import CheckboxGroup from "@/components/input_fields/CheckboxGroup";
import InputCheckbox from "@/components/input_fields/InputCheckbox";
import InputCheckbox2 from "@/components/input_fields/InputCheckbox2";
import CheckboxOther from "@/components/input_fields/CheckboxOther";
import InputRadioGroup from "@/components/input_fields/InputRadioGroup";
import InputSelect from "@/components/input_fields/InputSelect";
import InputSelectWithParsedOptions from "@/components/input_fields/InputSelectWithParsedOptions";
import NewInputSelect from "@/components/input_fields/NewInputSelect";
import InputPhone from "@/components/input_fields/InputPhone";
import InputZipCode from "@/components/input_fields/InputZipCode";

export default (Vue) => {
    Vue.component('InputText', InputText),
    Vue.component('InputTextarea', InputTextarea),
    Vue.component('InputNumber', InputNumber),
    Vue.component('InputEmail', InputEmail),
    Vue.component('InputPassword', InputPassword),
    Vue.component('InputDate', InputDate),
    Vue.component('InputDateTime', InputDateTime),
    Vue.component('InputRadio', InputRadio)
    Vue.component('InputRadioGroup', InputRadioGroup)
    Vue.component('InputCheckbox', InputCheckbox),
    Vue.component('InputCheckbox2', InputCheckbox2),
    Vue.component('CheckboxGroup', CheckboxGroup),
    Vue.component('CheckboxOther', CheckboxOther),
    Vue.component('InputSelect', InputSelect),
    Vue.component('InputSelectWithParsedOptions', InputSelectWithParsedOptions),
    Vue.component('NewInputSelect', NewInputSelect),
    Vue.component('InputPhone', InputPhone)
    Vue.component('InputZipCode', InputZipCode)
};
