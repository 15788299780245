import repository from "@/repositories/repository";
const educationRepository = repository.get("education");

const state = () => ({
    educationsList: []
})

const mutations = {
    FETCH_ALL_EDUCATIONS(state, educationsList) {
        state.educationsList = educationsList
    },
}

const actions = {
     fetch_all_educations_action: async ({commit}) => {
         const response = await educationRepository.getAll();
         commit('FETCH_ALL_EDUCATIONS', response.data);
         return response.data;
    },
}

const getters = {
    get_education_list: (state) => {
       return state.educationsList;
   },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
