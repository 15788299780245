<template>
<div class="form-group">
    <template v-if="label">
        <label>{{label}} <span v-if="required">*</span></label>
    </template>

    <ValidationProvider :rules="required ? 'required' : ''" v-slot="{ errors, classes }">
        <datetime v-model="date"
            :input-class="formatInputClass(classes)"
            :type="(timeEnabled) ? 'datetime' : ''"
            :min-datetime="disabledDatesUpTo"
            :max-datetime="disabledDatesFrom"
            :placeholder="placeholder"
        ></datetime>
        <div class="validate-error">{{ errors[0] }}</div>
    </ValidationProvider>
</div>
</template>

<script>
import {
    Datetime
} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import {
    Settings
} from 'luxon'
Settings.defaultLocale = 'nl'

export default {

    props: {
        placeholder: {
            type: String,
            required: false,
        },
        disabledDatesUpTo: {
            type: String,
            required: false,
        },
        disabledDatesFrom: {
            type: String,
            required: false,
        },
        timeEnabled: {
            type: Boolean,
            required: true
        },
        initialDate: {
            type: String,
            required: false
        },
        label: {
            required: false,
            type: String,
        },
        required: {
            required: false,
            type: Boolean,
        },
    },

    components: {
        Datetime,
    },

    data: () => ({
        date: '',
    }),

    watch: {
        date: function(value) {
            this.$emit('dateChanged', value)
        }
    },

    created() {
        if (this.initialDate) {
            this.date = this.initialDate;
        }
    },

    methods: {
        formatInputClass(classes) {
            let classNames = ['form-field'];

            for (const [className, active] of Object.entries(classes)) {
                if (active) {
                    classNames.push(className);
                }
            }

            return classNames.join(' ');
        }
    }
}
</script>

<style scoped>

</style>
