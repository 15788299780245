<template>
  <div v-if="dataFetched">
    <div class="bg-black bg-noise-top text-white py-4">
      <div class="flex flex-col items-center text-center p-4">
        <a class="no-underline mt-2 mb-4" href="/">
          <LogoBlackSvg/>
        </a>

        <h1>{{ $t('onboarding.jobs.header.title') }}</h1>
        <p class="lead">{{ $t('onboarding.jobs.header.subtitle') }}</p>
      </div>

    </div>
    <div class="black-bar mb-4"></div>

    <div class="container mx-auto p-4" v-if="jobs">

      <div class="flex flex-col mb-3">
        <h3>{{ $t('onboarding.jobs.titles.select_job') }}</h3>
        <p class="text-gray-darker">{{ $t('onboarding.jobs.tip') }}</p>

        <div v-for="(job, index) in jobs" :key="index">
          <CheckboxGroup :id="job.id" :label="job.title" :value="job.id" :checked="selectedJobs" @emitCheckedId="updateSelectedArray(selectedJobs, $event)">
            <button class="text-sm text-white text-left border border-white w-full p-2 opacity-75 hover:underline" @click="addWorkExperience(job)">
              <i class="icon-plus"/> {{ $t('onboarding.jobs.add_work_experience') }}
            </button>
            <WorkExperienceList styleHandle="orange" :workexperience="getWorkexperience(job.id)" :jobId="job.id" :scrollable="false"></WorkExperienceList>
          </CheckboxGroup>
          <div class="alert no-workexperience" v-if="jobIsChecked(job.id) && !workexperienceChoosen(job.id)">{{ $t('onboarding.jobs.alert.work_experience') }}</div>
        </div>
      </div>

      <div class="alert" v-if="!jobChosen">{{ $t('onboarding.jobs.alert.job') }}</div>
      <NavNext nextRouteName="onboarding_profile" :buttonText="$t('general.next')" @submit="validateForm" ref="NavNext"/>
    </div>


    <NavPrevious @submit="setStoreData" prevRouteName="onboarding_experience_education"/>
    <div v-if="modalOpen">
      <WorkExperienceAdd :job="job" @closeModal="closeModal"></WorkExperienceAdd>
    </div>

  </div>
</template>

<script>
import WorkExperienceAdd from "@/components/onboarding/WorkExperienceAdd";
import WorkExperienceList from "@/components/onboarding/WorkExperienceList";

export default {

  components: {
    WorkExperienceAdd,
    WorkExperienceList
  },

  data: () => ({
    dataFetched: false,
    modalOpen: false,
    jobs: [],
    selectedJobs: [],
    allWorkexperienceIsFilled: true,
    job: {},
  }),

  watch: {
    //when an option is selected or deselected update store
    selectedJobs: function () {
      this.setStoreData();
    },

    modalOpen: function (value) {
      document.body.classList.toggle('fixed', value);
      document.body.classList.toggle('overflow-y-hidden', value);
      document.body.classList.toggle('h-screen', value);
      document.body.classList.toggle('w-full', value);
    },
  },
  computed: {
    jobChosen: function () {
      return this.selectedJobs.length > 0;
    },
  },

  methods: {
    validateForm() {
      this.allWorkexperienceFilled();
      if (this.jobChosen && this.allWorkexperienceIsFilled) {
        this.$refs.NavNext.navigate();
      }
    },

    addWorkExperience(job) {
      this.job = job;
      this.modalOpen = true;
    },

    closeModal() {
      this.modalOpen = false;
    },

    setStoreData() {
      this.$store.dispatch('onboardingExperience/set_jobs', this.selectedJobs);
    },

    getStoreData() {
      this.selectedJobs = this.$store.getters['onboardingExperience/get_jobs'];
    },

    getJobsList() {
      return this.$store.dispatch('job/fetch_all_jobs_action', 1).then((jobsList) => {
        this.jobs = this.filterJobListForMinimumAge(jobsList[this.$store.getters['onboardingExperience/get_job_group']]);
      });
    },

    getWorkexperience(jobId) {
      return this.$store.getters['onboardingExperience/get_work_experience'](jobId);
    },



    workexperienceChoosen(jobId) {
      const workexperience = this.$store.getters['onboardingExperience/get_work_experience'](jobId)
      return workexperience.length > 0;
    },

    jobIsChecked(jobId) {
      return this.$helpers.checkIfValueInArray(this.selectedJobs, jobId);
    },

    allWorkexperienceFilled(){
     this.allWorkexperienceIsFilled = document.getElementsByClassName("no-workexperience").length < 1;
    },

    updateSelectedArray(array, id) {
      // if doesnt exist add to array, else remove from array
      if (array.indexOf(id) !== -1) {
        array.splice(array.indexOf(id), 1);
      } else {
        array.push(id);
        const job = this.findClickedJobById(id);
        this.addWorkExperience(job) //open add work experience component when checkbox is checked
      }
    },

    findClickedJobById(id) {
      let clickedJob = '';
      this.jobs.forEach((job) => {
        if (job.id == id) {
          clickedJob = job;
        }
      });
      return clickedJob;
    },

    filterJobListForMinimumAge(jobs) {
      const newArray = [];
      jobs.forEach((element) => {
        if (this.meetsJobMinimumAge(element.ageLimit)) {
          newArray.push(element);
        }
      });
      return newArray;
    },

    meetsJobMinimumAge(ageLimit) {
      return this.$helpers.isOfAge(this.$store.getters['onboardingExperience/get_intake_user_info'].dateOfBirth, ageLimit);
    },

  },

  async created() {
    this.$store.dispatch('general/startloading');

    await this.getJobsList();
    this.dataFetched = true;
    this.getStoreData();
    this.$store.dispatch('general/endloading');
  }
}
</script>


