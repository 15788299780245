<template>
  <div>
    <div class="mb-4">
      <p class="text-sm text-gray-dark mb-2">
        <i class="icon-pin"/>
        {{ shift.shiftLocation.city }}, {{ shift.shiftLocation.street }} {{ shift.shiftLocation.houseNumber }} ({{ shift.distance }} km)
      </p>
      <h2 class="mb-2">{{ shift.jobTitle }}</h2>
      <p class="text-sm text-orange mb-4">
        Start: {{ shift.startDateTime | moment("dddd") }} {{ shift.startDateTime | moment("DD MMMM") }} {{ shift.startDateTime | moment("HH:mm") }} uur <br/>
        Eind: {{ shift.endDateTime | moment("dddd") }} {{ shift.endDateTime | moment("DD MMMM") }} {{ shift.endDateTime | moment("HH:mm") }} uur <br/>
      </p>
      <p class="mb-4 text-sm text-gray" v-if="shift.flexibleEndTime">
        Eindtijd flexibel
      </p>
    </div>
    <div class="block mb-4" v-if="!shift.shift_hasNoTravelExpenses">
      <h3>Reiskosten vergoedingen</h3>
      <p class="text-sm text-orange mb-1">&euro; {{ shift.travelAllowance.perKilometer }} per km = &euro; {{ shift.travelAllowance.total }} voor deze shift</p>
      <i v-if="shift.distance > 180" class="text-xs text-grey">* * maximale reisafstand die vergoed wordt is 180km</i>
    </div>
  </div>
</template>

<script>


export default {
  props: {
    shift: {
      type: Object,
      required: true
    },
  },
}
</script>

<style scoped>
.block {
  @apply mb-8 text-xs md:text-sm;
}

.block h3 {
  @apply mb-3 text-sm md:text-base;
}
</style>
