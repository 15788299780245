<template>
  <div v-if="dataFetched">

    <ShiftDetailEmployeeHeader :shift="shift"></ShiftDetailEmployeeHeader>


    <div class="container mx-auto px-6 py-4 -mt-32">
      <ShiftDetailEmployeeBaseInfo :shift="shift"></ShiftDetailEmployeeBaseInfo>

      <ShiftDetailEmployeeTeam :shift="shift">
        Het team voor deze shift
      </ShiftDetailEmployeeTeam>
    </div>

    <div class="mb-8 bg-orange bg-opacity-10">
      <GoogleMaps :shift="shift"></GoogleMaps>
    </div>


    <!-- COMPANY SHIFTS -->
    <ShiftsCarousel :shifts="shiftsOfThisEmployer" v-if="shiftsOfThisEmployer.length" class="px-6 mb-16">
      <h2 class="text-2xl font-normal mb-8"> Shifts van dit bedrijf</h2>
      <template v-slot:shiftItem="shifts">
        <ShiftItem
            v-for="(shift,index) in shifts.data"
            :shift="shift"
            :key="index"
            :has-passed="true"
        />
      </template>
    </ShiftsCarousel>


  </div>
</template>

<script>
import repository from "@/repositories/repository";
import ShiftDetailEmployeeHeader from "@/components/shifts/ShiftDetailEmployeeHeader";
import ShiftDetailEmployeeBaseInfo from "@/components/shifts/ShiftDetailEmployeeBaseInfo";
import ShiftDetailEmployeeTeam from "@/components/shifts/ShiftDetailEmployeeTeam";
import ShiftItem from "@/components/shifts/ShiftItem";
import ShiftsCarousel from "@/components/shifts/ShiftsCarousel";
import GoogleMaps from "@/components/GoogleMaps";

const shiftRepository = repository.get("shift");

export default {
  components: {
    GoogleMaps,
    ShiftItem,
    ShiftsCarousel,
    ShiftDetailEmployeeHeader,
    ShiftDetailEmployeeBaseInfo,
    ShiftDetailEmployeeTeam,
  },

  data: () => ({
    dataFetched: false,
    shift: null,
    shiftsOfThisEmployer: [],
  }),

  methods: {
    handleResponseGetShift(response) {
      switch (response.message) {
        case 'shift_not_found':
          this.errorToast('De shift hebben we niet kunnen vinden, het kan zijn dat deze al heeft plaatsgevonden.', 'dashboard_employee')
          break;
      }
    },
  },

  async created() {
    this.$store.dispatch('general/startloading');
    this.shift = await shiftRepository.getByIdForEmployee(this.$route.params.id).then((response) => {
      this.handleResponseGetShift(response);
      return response;
    });
    this.shiftsOfThisEmployer = await shiftRepository.getAllFromEmployerForEmployee(this.shift.employer.id);
    this.dataFetched = true;
    this.$store.dispatch('general/endloading');
  }
}
</script>
