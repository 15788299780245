<template>
  <div class="pr-4">
    <select v-model="selectedWeek" @change="weekChange()" class="bg-transparent mb-1">
      <option
          v-for="(week) in weeks"
          :key="week.weekNumber"
          :value="week"
      >{{ week.firstDay | moment("D MMM") }} - {{ week.lastDay | moment("D MMM") }} ({{ week.weekNumber }})
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    minDate: {
      type: String,
      required: false,
    },
    maxDate: {
      type: String,
      required: false,
    }
  },


  data() {
    return {
      weeks: [],
      selectedWeek: {},
    }
  },


  methods: {
    createWeekArray() {
      let now = this.$moment();
      let maxWeeksBack = 24;

      //1. add current week to array
      let week = this.createWeekObject(now);
      this.weeks.push(week);

      //2. calculate previous week
      for (let i = 0; i < this.weeks.length; i++) {
        let currentWeek = this.weeks[i]
        let previousWeekFirstDay = currentWeek.firstDayMomentInstance.subtract(1, 'w');

        // if(currentWeek.weekNumber <= this.$moment(this.minDate).format('W')){
        //   break;
        // }

        //add week to weeks array
        week = this.createWeekObject(previousWeekFirstDay);
        this.weeks.push(week);

        if (i > maxWeeksBack) {
          break;
        }
      }
    },

    createWeekObject(day) {
      return {
        firstDayMomentInstance: day.clone().startOf('week'),
        firstDay: day.clone().startOf('week').format('YYYY-MM-DD'),
        lastDay: day.clone().endOf('week').format('YYYY-MM-DD'),
        weekNumber: day.week(),
      }
    },

    weekChange() {
      this.$emit('weekChange', this.selectedWeek);
    },

    changeWeekFromOutside(action) {
      if (action === 'next') {
        const previousWeekNumber = this.selectedWeek.weekNumber + 1;
        this.selectedWeek = this.weeks.find((week) => {
          return week.weekNumber === previousWeekNumber;
        })
      }

      if (action === 'previous') {
        const previousWeekNumber = this.selectedWeek.weekNumber - 1;
        this.selectedWeek = this.weeks.find((week) => {
          return week.weekNumber === previousWeekNumber;
        })
      }
      this.weekChange();
    }
  },

  created() {
    this.createWeekArray();
    this.selectedWeek = this.weeks[0];
  }

}
</script>

<style lang="scss" scoped="true">
  select {
    width: 100%;
    max-width: 320px;
    option {
      color: #000;
    }
  }
</style>