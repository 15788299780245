<template>


  <div class="image-upload-container" style="position:relative;">

    <div class="edit_icon" @click="hasButton ? null : $refs.file.click()">
        <i class="icon-edit" />
    </div>

    <div class="flex mb-4" style="align-items: flex-start;">


      <template v-if="!isCropping">

        <img v-if="currentImage"
             ref="currentFile"
             class="rounded-full w-48 mx-auto"
             :src="currentImage"
             alt=""
             :class="{ 'cursor-pointer' : !hasButton }"
             @click="hasButton ? null : $refs.file.click()"
        >

        <img v-if="!currentImage"
             class="rounded-full w-48 mx-auto"
             src="@/assets/images/default_avatar.jpg"
             alt=""
             :class="{ 'cursor-pointer' : !hasButton }"
             @click="hasButton ? null : $refs.file.click()"
        >


      </template>
      <modal name="crop_modal" :adaptive="true" height="auto" @before-close="beforeCloseModal">
        <div v-if="isCropping" class="flex flex-col flex-wrap items-end p-4 bottom-0">
          <cropper
              class="cropper-custom"
              imageClass="cropper-custom"
              boundariesClass="cropper-custom"
              :resizeImage=false
              :src="image"
              ref="cropper"
              :stencil-props="{
                    aspectRatio: 1,
                }"/>
          <div class="flex justify-end w-full mt-4">
            <button type="button" class="button" @click="crop(); $modal.hide('crop_modal')">
              {{ $t('components.ImageUpload.crop') }}
            </button>
          </div>
        </div>
      </modal>
    </div>

    <input class="hidden" type="file" ref="file" @change="loadImage($event)" @click="$modal.show('crop_modal')" accept=".png, .jpg, .jpeg">

    <template v-if="hasButton">
      <div class="mb-4">
        <button type="button" class="button" @click="$refs.file.click()">
          {{ $t('components.ImageUpload.new_image') }}
        </button>
      </div>
    </template>

    <template v-if="hasSubtext">
      <p class="text-gray text-sm text-center">
        {{ $t('components.ImageUpload.photo_advice') }}
      </p>
    </template>


  </div>
</template>

<script>
import {
  Cropper
} from 'vue-advanced-cropper'

export default {
  props: {
    initial_image: {
      type: String,
      required: false
    },
    hasButton: {
      type: Boolean,
      required: false,
      default: true
    },
    hasSubtext: {
      type: Boolean,
      required: false,
      default: true
    },
  },

  components: {
    Cropper
  },

  data() {
    return {
      image: null,
      imageName: null,
      currentImage: null,
      isCropping: false
    }
  },

  methods: {
    loadImage(event) {
      this.isCropping = true;
      // Reference to the DOM input element
      var input = event.target;

      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.image = e.target.result;
          this.imageName = input.files[0].name
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },

    beforeCloseModal() {
      this.isCropping = false;
      this.resetImageValues();
    },

    crop() {
      this.isCropping = false;
      const result = this.$refs.cropper.getResult();
      this.currentImage = result.canvas.toDataURL();
      this.updateImageUrl(this.currentImage);
    },

    //reset image, in case another image gets selected afterwards
    resetImageValues() {
      this.image = null;
      this.$refs.file.value = '';
    },


    updateImageUrl(currentImage) {
      const file = this.$helpers.dataURLtoBlob(currentImage);
      this.$emit('fileChange', file, this.imageName, currentImage);
      this.resetImageValues();
    },
  },

  created() {
    this.currentImage = this.initial_image;
  }
}
</script>

<style>
.cropper-custom {
  max-height: 400px !important;
}

.edit_icon {
  height: 50px;
  width: 50px;
  background-color: #FF5F00;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border-radius: 50%;
  color: #fff;
}

.edit_icon i {
    display: flex;
    font-size: 1.25rem;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}


</style>

