<template>
  <div>
    <PageTitle title="Nieuwe shift inplannen" lead="Get your shifts together"/>

    <div class="container mx-auto p-4" v-if="dataFetched">

      <ValidationObserver ref="form" class="mb-4">

        <div class="panel">
          <!--  jobgroup    -->
          <h3>Kies functiegroep</h3>
          <InputSelect id="jobGroups"
                       :selected="jobGroupSelected"
                       :options="jobGroups"
                       placeholder="Kies functiegroep"
                       :required="true"
                       @onChange="onJobGroupChange($event)"/>
        </div>

        <div class="panel">
          <h3>Functie</h3>
          <NewInputSelect id="jobs"
                       :selected="formData.jobSelected"
                       placeholder="Kies functie"
                       :options="jobs"
                       :required="true"
                       :disabled="!jobGroupSelected"
                       @onChange="formData.jobSelected = $event"/>
        </div>


        <div class="panel">
          <h3>Locatie</h3>
          <InputSelect id="locations"
                       :selected="mainLocation.id"
                       :options="organization.locations"
                       :required="!extraLocation"
                       :disabled="extraLocation"
                       @onChange="onLocationChange($event)"/>
          <InputCheckbox id="extraLocation" @input="toggleExtraLocation()" :isChecked="extraLocation">
            <div class="text-gray text-sm">
              <p class="mb-0 text-black">Extra Locatie</p>
              Voeg een andere locatie toe
            </div>
          </InputCheckbox>


          <div v-if="extraLocation">
            <InputText id="location-title" :placeholder="`Naam locatie (optioneel)`" :required="false" v-model="location.title"/>
            <AddressAutoComplete
                @successAddress="fillAddress"
                @errorAddress="clearAddress"
            />
            <InputText id="street" :placeholder="$t('general.street')" :required="true" v-model="location.street" :disabled="true"/>
            <InputText id="city" :placeholder="$t('general.city_name')" :required="true" v-model="location.city" :disabled="true"/>
            <InputText id="province" :placeholder="$t('general.province')" :required="true" v-model="location.province" :disabled="true"/>
            <InputCheckbox id="extraLocation" v-model="location.saveExtraLocation" :isChecked="location.saveExtraLocation">
              <div class="text-gray text-sm">
                <p class="mb-0 text-black">Extra Locatie opslaan</p>
                Sla deze locatie op om deze in de toekomst snel te kunnen kiezen.
              </div>
            </InputCheckbox>
          </div>
        </div>


        <div class="panel">
          <h3>Start &amp; eindtijd</h3>
          <StartEndTimeShiftCreate :minShiftDuration="minTime" :maxShiftDuration="maxTime"
              @startTimeChanged="formData.startTime = $event"
              @endTimeChanged="formData.endTime = $event "
          />

          <!--  flex endtime    -->
          <InputCheckbox id="flexibleEndTime" v-model="formData.isFlexibleEndTime" :isChecked="formData.isFlexibleEndTime">
            <div class="text-gray text-sm">
              <p class="mb-0 text-black">Eindtijd flexibel</p>
              Een exacte eindtijd kunnen we niet garanderen. Je bent mogelijk eerder of later klaar.
            </div>
          </InputCheckbox>
        </div>

        <template v-for="(dateInput, index) in dateInputs">
          <div class="md:flex mb-2" :key="dateInput.id">

            <div class="flex">
              <div class="mr-4 md:mr-8 flex-grow">
                <h3 class="mt-4 md:mt-8">Datum</h3>
                <DateTimeField :time-enabled="false"
                               :disabledDatesUpTo="$helpers.currentDateInIso()"
                               @dateChanged="dateInput.date = $event"
                />
              </div>

              <div class="md:hidden mr-4 flex justify-center" style="align-self: center; padding-top:30px ">
                <button @click="removeDate(index)" :key="`remove-${dateInput.id}`" class="">
                  <i class="icon-cross text-xs text-gray hover:text-red"/>
                </button>
              </div>
            </div>

            <div class="md:mr-8">
              <h3 class="mt-4 md:mt-8">Aantal personen</h3>
              <InputNumber id="amountEmployees"
                           v-model="dateInput.amountEmployees"
                           @increment="incrementAmountEmployees(dateInput)"
                           @reduce="reduceAmountEmployees(dateInput)"/>
            </div>

            <div class="hidden md:flex justify-center" style="align-self: center; padding-top:30px ">
              <button @click="removeDate(index)" :key="`remove-${dateInput.id}`" class="">
                <i class="icon-cross text-xs text-gray hover:text-red"/>
              </button>
            </div>
          </div>
        </template>

        <button class="text-orange text-sm mb-8 block -mt-2" @click="addDate">
          <i class="icon-plus text-xs"/> extra datum
        </button>

        <div class="panel">
          <h3>Extra photo's uploaden (optioneel)</h3>
          <Dropzone ref="dropzone" class="mb-4"></Dropzone>
          <p class="text-xs text-gray mb-4">Standaard worden foto’s van je profiel getoond, upload specifieke foto’s die iets over deze shift zeggen</p>
        </div>

        <InputTextarea id="descriptionWork" :required="true" v-model="formData.descriptionWork" label="Omschrijf werkzaamheden voor deze shift" placeholder="Taakomschrijving werkzaamheden"/>
        <InputTextarea id="requiredSkills" :required="false" v-model="formData.requiredSkills" label="Vereiste skills" placeholder="Denk aan Sushi maken, BBQ koken, Bepaalde wijnkennis"/>
        <InputTextarea id="dressCode" :required="false" v-model="formData.dressCode" label="Kledingvoorschriften" placeholder="Geef een omschrijving"/>
        <InputTextarea id="placeToReport" :required="false" v-model="formData.placeToReport" label="Waar moet je je melden als je gaat werken?" placeholder="Geef een omschrijving"/>
        <InputTextarea id="parkingInstructions" :required="false" v-model="formData.parkingInstructions" label="Uitleg over parkeren" placeholder="Waar kun je parkeren?"/>
        <InputTextarea id="additionalDescription" :required="false" v-model="formData.additionalDescription" label="Aanvullende eisen of opmerkingen" placeholder="Aanvullende eisen of opmerking"/>

        <div class="panel">
          <h3>Welke talen zijn vereist?</h3>
          <MultiSelect :tagOptions="languages" placeholder="Selecteer talen" :selectionRequired="true" @updateSelectedSelectables="formData.requiredLanguages = formatRequiredLanguagesArray($event)"></MultiSelect>
        </div>
      </ValidationObserver>

      <button-primary text="Maak shift aan" @click.native="validateForm()"></button-primary>

    </div>
  </div>
</template>

<script>
import ButtonPrimary from "@/components/buttons/ButtonPrimary";

import Dropzone from "@/components/image/Dropzone";
import repository from "@/repositories/repository";
import MultiSelect from "@/components/MultiSelect";
import DateTimeField from "@/components/date-time/DateTimeField";
import AddressAutoComplete from "@/components/input_fields/groups/AddressAutoComplete"
import organisationRepository from "@/repositories/organisationRepository";
import StartEndTimeShiftCreate from "@/components/date-time/StartEndTimeShiftCreate";
import constants from "@/settings/constants";

const shiftRepository = repository.get("shift");


export default {
  name: 'ShiftCreateForm',
  components: {
    Dropzone,
    ButtonPrimary,
    MultiSelect,
    StartEndTimeShiftCreate,
    DateTimeField,
    AddressAutoComplete
  },


  data: () => ({
    dataFetched: false,
    jobGroupSelected: null,
    extraLocation: false,
    location: {
      title: '',
      selectedLocationId: null,
      saveExtraLocation: false,
      city: '',
      street: '',
      houseNumber: '',
      province: '',
      zipCode: '',
      addressCoordinates: '',
    },
    
    minTime: constants.MIN_SHIFT_DURATION_HOURS,
    maxTime: constants.MAX_SHIFT_DURATION_HOURS,

    organization: {},

    dateInputCounter: 0,
    dateInputs: [{
      id: `date-0`,
      date: '',
      amountEmployees: 1,
    }],

    formData: {
      isFlexibleEndTime: false,
      jobSelected: null,
      dressCode: '',
      descriptionWork: '',
      placeToReport: '',
      parkingInstructions: '',
      requiredSkills: '',
      additionalDescription: '',
      requiredLanguages: [],
      startTime: '',
      endTime: '',
    }
  }),

  watch: {
    jobGroupSelected: function() {
      this.formData.jobSelected = null;
      this.minTime = constants.MIN_SHIFT_DURATION_HOURS;
      this.maxTime = constants.MAX_SHIFT_DURATION_HOURS;
    },
    jobSelected: function(newValue) {
      if (newValue !== null) {
        this.getMinMaxTimes(newValue);
      }
    }
  },

  computed: {
    languages: function () {
      return this.$store.getters['language/get_language_list'];
    },
    jobGroups: function () {
      return this.$store.getters['jobGroup/get_jobGroup_list'];
    },
    jobs: function () {
      let allJobs = this.$store.getters['job/get_job_list'];

      return allJobs.filter((item) => {
        return item.jobGroupId == this.jobGroupSelected;
      })
    },
    jobSelected: function() {
      return this.formData.jobSelected;
    },
    mainLocation: function () {

      let mainLocation = this.organization.locations.find((item) => {
        return item.mainLocation === true;
      })

      return mainLocation;
    },

  },


  methods: {
    validateForm() {
      this.$refs.form.validate().then(async (validated) => {
        if (validated) {

          let isConfirmed = await this.alertBeforeCreateShift();
          if (isConfirmed) {
            let formData = this.$refs.dropzone.getFiles();
            formData.append('data', JSON.stringify(this.buildShiftData()));

            this.$store.dispatch('general/startloading');
            this.$store.dispatch('shift/create_shift_action', formData).then((response) => {
              this.$store.dispatch('general/endloading');
              this.handleResponse(response);
            })
          }
        }
      });
    },

    getMinMaxTimes: function(jobId) {
      console.log('Getting shift times for jobId', jobId);
      // Initial values
      let newMinTime = constants.MIN_SHIFT_DURATION_HOURS;
      let newMaxTime = constants.MAX_SHIFT_DURATION_HOURS;

      // Override if set
      const selectedJob = this.jobs.filter((item) => {
        return item.id == jobId;
      })
      if (selectedJob[0].minimumShiftDurationInHours) {
        newMinTime = selectedJob[0].minimumShiftDurationInHours;
      } 
      if (selectedJob[0].maximumShiftDurationInHours) {
        newMaxTime = selectedJob[0].maximumShiftDurationInHours;
      }
      this.minTime = newMinTime;
      this.maxTime = newMaxTime;
      console.log('min', this.minTime, 'max', this.maxTime);
    },

    buildShiftData() {
      return {
        ...this.formData,
        location: this.location,
        dateInputs: this.dateInputs,
      }
    },

    handleResponse(response) {
      switch (response.message) {
        case 'shift_created':
          this.successToast('Shift(s) aangemaakt', 'dashboard_employer')
          break;
        default:
          this.errorToast('Shift kon niet worden aangemaakt, probeer het later opnieuw', 'dashboard_employer')
      }
    },

    async onJobGroupChange(jobGroupId) {
      this.jobGroupSelected = jobGroupId;
      //select 1st in array
      // this.formData.jobSelected = this.jobs[0].id;
    },

    incrementAmountEmployees(dateInput) {
      const numeric = parseInt(dateInput.amountEmployees);
      dateInput.amountEmployees = isNaN(numeric) ? 1 : numeric + 1;
    },

    reduceAmountEmployees(dateInput) {
      dateInput.amountEmployees = Math.max(1, dateInput.amountEmployees - 1);
    },

    addDate() {
      this.dateInputs.push(
          {
            id: `date-${++this.dateInputCounter}`,
            date: '',
            amountEmployees: 1,
          }
      );
    },

    removeDate(index) {
      this.dateInputs.splice(index, 1);
    },


    async handleCopyShift() {
      const shiftId = this.$helpers.getUrlParam('shiftId');

      if (shiftId !== null) {
        const shift = await shiftRepository.getByIdForEmployer(shiftId);
        this.jobGroupSelected = shift.jobGroupId;

        this.formData.isFlexibleEndTime = shift.isFlexibleEndTime;
        this.formData.jobSelected = shift.jobId;
        this.formData.dressCode = shift.dressCode;
        this.formData.descriptionWork = shift.descriptionWork;
        this.formData.placeToReport = shift.placeToReport;
        this.formData.parkingInstructions = shift.parkingInstructions;
        this.formData.requiredSkills = shift.requiredSkills;
        this.formData.additionalDescription = shift.additionalDescription;
        this.formData.requiredLanguages = this.formatRequiredLanguagesArray(shift.requiredLanguages);
      }
    },

    alertBeforeCreateShift() {
      return this.$swal({
        title: "Weet je zeker dat je deze shift nu online wil zetten",
        text: "Shifts kunnen niet meer gewijzigd worden en alleen  worden geannuleerd volgens de voorwaarden van de CAO en My Shifts.",
        confirmButtonText: "Plaats Shift",
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#FF5F00',
      }).then((result) => {
        return result.isConfirmed;
      })
    },

    formatRequiredLanguagesArray(requiredLanguages) {
      return requiredLanguages.map(element => element.id);
    },


    /*LOCATION*/
    fillAddress(data) {
      this.location.selectedLocationId = (data.id) ? data.id : this.location.selectedLocationId;
      this.location.street = data.street;
      this.location.houseNumber = data.houseNumber;
      this.location.city = data.city;
      this.location.zipCode = data.zipCode;
      this.location.province = data.province;
      this.location.addressCoordinates = data.addressCoordinates;
    },

    clearAddress() {
      this.location.street = '';
      this.location.city = '';
      this.location.province = '';
      this.location.addressCoordinates = {};
    },

    toggleExtraLocation() {
      this.extraLocation = !this.extraLocation;
      if (this.extraLocation) {
        this.clearAddress();
      }

      if (!this.extraLocation) {
        console.log('LOC', this.location.selectedLocationId);
        let location = this.getLocationById(this.location.selectedLocationId);
        this.fillAddress(location);
      }
    },

    onLocationChange(selectedLocationId) {
      this.location.selectedLocationId = selectedLocationId;
      let location = this.getLocationById(selectedLocationId);
      this.fillAddress(location);
    },

    getLocationById(locationId) {
      return this.organization.locations.find((item) => {
        return item.id == locationId;
      })
    },

    async handleOrganisation() {
      this.organization = await organisationRepository.get();
      this.fillAddress(this.mainLocation);
      // this.fillAddress(this.mainLocation);
    },
    /*LOCATION*/


  },

  async created() {
    this.$store.dispatch('general/startloading');
    await this.$store.dispatch('language/fetch_all_languages_action');
    await this.$store.dispatch('jobGroup/fetch_all_jobGroups_action');
    await this.$store.dispatch('job/fetch_all_jobs_action');
    await this.handleOrganisation();
    await this.handleCopyShift();
    this.dataFetched = true;
    this.$store.dispatch('general/endloading');
  }

};
</script>



