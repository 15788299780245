<template>
<div class="form-group">
    <template v-if="label">
        <label :for="id">{{label}} <span v-if="required">*</span></label>
    </template>

    <ValidationProvider :rules="required ? 'required|phoneNumber' : 'phoneNumber'" v-slot="{ errors, classes }">
        <input class="form-field" type="text" :placeholder="placeholder" :id="id" :class="classes" :value="value" @input="$emit('input', $event.target.value)" v-on:keyup.enter="onEnter()" />
        <div class="validate-error">{{ errors[0] }}</div>
    </ValidationProvider>
</div>
</template>

<script>
export default {
    props: {
        id: {
            required: true,
            type: String,
        },

        label: {
            required: false,
            type: String,
        },

        placeholder: {
            required: false,
            type: String,
        },

        required: {
            required: false,
            type: Boolean,
        },

        value: {
            required: false,
            type: String,
        },

    },

  methods:{
    onEnter(){
      this.$emit('onEnter')
    }
  }
}
</script>
