<template>
  <div class="form-group">
    <div class="border border-gray-light rounded mt-2" :class="{ 'bg-orange': isChecked, 'bg-noise-top': isChecked, 'text-white': isChecked }">
      <label class="flex px-4 h-12 block items-center cursor-pointer"
      >
        <div class="flex w-full">
          <span v-if="isChecked" class="mr-3 text-black">
            <i class="icon-checkmark"/>
          </span>
          <input type="checkbox" class="opacity-0 absolute" :id="id" :value="value"
                 @change="emitCheckedId(id)"
          >
          {{ label }}
        </div>
      </label>

      <div class="p-3 pl-12 mb-1 -mt-4" v-if="isChecked && !!$slots.default">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: [String, Number]
    },

    label: {
      required: true,
      type: String,
    },

    value: {
      required: true,
      type: [String, Number]
    },

    checked: {
      required: false,
      type: [Array, Boolean],
      default: false,
    },
  },


  computed: {
    isChecked() {
      return this.checkIfChecked();
    },
  },


  methods: {
    emitCheckedId(id) {
      this.$emit('emitCheckedId', id)
    },

    checkIfChecked() {
      return this.$helpers.checkIfValueInArray(this.checked, this.id);
    }
  }
}
</script>
