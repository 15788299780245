<template>
  <div>
    <div class="h-40 py-4 px-6 bg-black bg-noise-bottom">
      <div class="flex justify-between items-center">
        <LogoBlackSvg :width="screenWidth > 768 ? 110 : 80"/>

        <button type="button" class="text-white text-sm mb-4" @click="navigateBack()" style="z-index: 10">
          Terug <i class="icon-cross text-orange ml-2"/>
        </button>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>

// import store from "@/store/store";
// import {redirectHomeForUserType} from "@/services/authentication/authService";

export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    screenWidth() {
      return window.screen.width || 1200;
    },
  },
  methods: {
    navigateBack() {
      this.$router.go(-1)
      // const userGroup = store.getters['user/get_user'].group;
      // const redirectRoute = redirectHomeForUserType(userGroup);
      //
      // this.$router.push({name: redirectRoute});
    }
  }
}
</script>

<style scoped>
</style>
