import AxiosClient from "@/config/axios/axios";
import ErrorService from "@/services/errors/ErrorService";
const resource = "/job";

export default {
    getAll(version) {
        return AxiosClient.get(`${resource}/get/all/?version=${version}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                ErrorService.onError(error)
            })
    },


    getByJobGroup(jobGroupId) {
        return AxiosClient.get(`${resource}/get/by-job-group/?id=${jobGroupId}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                ErrorService.onError(error)
            })
    },
}
