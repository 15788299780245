<template>
  <div class="alert" v-if="condition"><slot></slot></div>
</template>

<script>
export default {
  props: {
    condition: {
      required: true,
      type: Boolean,
    },
  }
}
</script>

<style scoped>

</style>
