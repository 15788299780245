<template>
<div>
    <template v-if="label">
        <label :for="id">{{label}} <span v-if="required">*</span></label>
    </template>

    <ValidationProvider :rules="required ? 'required' : ''" v-slot="{ errors }">
        <input :id="id" type="radio" :value="value" :placeholder="placeholder" @change="$emit('input', $event.target.value)" />
        <div class="validate-error">{{ errors[0] }}</div>
    </ValidationProvider>

</div>
</template>

<script>
export default {
    props: {
        id: {
            required: true,
            type: String,
        },

        label: {
            required: false,
            type: String,
        },

        placeholder: {
            required: false,
            type: String,
        },

        required: {
            required: false,
            type: Boolean,
        },

        value: {
            required: false,
            type: String,
        },
    },
}
</script>
