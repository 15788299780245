<template>
  <div>
    <div class="black-bar"></div>
    <div class="flex flex-col items-center text-center p-4">
      <a class="no-underline mt-2 mb-4" href="/">
        <LogoBlackSvg/>
      </a>

      <div class="container md:w-1/2">
        <h1>{{ $t('general.login') }}</h1>
        <p class="lead">{{ $t('login.title') }}</p>

        <InputEmail id="email" :placeholder="$t('general.email')" :required="true" v-model="username"/>
        <InputPassword id="password" :placeholder="$t('general.password')" :required="true" v-model="password" @keyup.enter.native="login"/>
        <p class="text-gray text-sm">{{ $t('onboarding.back_to_onboarding_login.info_note') }}</p>
        <div class="alert" v-if="loginFeedback">{{ loginFeedback }}</div>
        <button class="button button--primary" @click="login">{{ $t('general.login') }}</button>

        <template v-if="environment === 'local' || environment === 'Staging'">
          <button class="button button--custom-login my-1" @click="loginDev('emp')">Employer</button>
          <button class="button button--custom-login my-1" @click="loginDev('uzk')">Uitzendkracht</button>
          <button class="button button--custom-login my-1" @click="loginDev('zzp')">ZZP'er</button>
        </template>

      </div>
    </div>

    <div class="px-4 text-sm sm:text-center">
      <p>
        <router-link :to="{ name: 'password-forgot' }">{{ $t('login.forgot_password') }}</router-link>
      </p>
      <p>
        <router-link :to="{ name: 'onboarding_options' }">{{ $t('login.create_account') }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>


import {redirectHomeForUserType} from '@/services/authentication/authService'

export default {
  data: () => ({
    username: '',
    password: '',
    uid: '',
    loginFeedback: false,
    environment: process.env.VUE_APP_ENV_MODE,
  }),

  mounted() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    this.uid = urlParams.get('uid');
    if (this.uid) {
      console.log('UID present, login as user');
      this.loginAsUser(this.uid);
    }
  },

  methods: {

    login() {
      const {
        username,
        password
      } = this;


      if (username && password) {
        this.$store.dispatch('general/startloading');
        this.$store.dispatch('auth/login_action', {
          username,
          password
        }).then(async (response) => {
          await this.$store.dispatch('auth/get_csrf_token_action');
          this.handleResponse(response)
        });
      }

    },

    loginAsUser(uid) {
      this.$store.dispatch('auth/logout_action');
      
      if (uid) {
        this.$store.dispatch('general/startloading');
        this.$store.dispatch('auth/login_as_user_action', { uid }).then(async (response) => {
          await this.$store.dispatch('auth/get_csrf_token_action');
          this.handleResponse(response)
        });
      }

    },


    loginDev(type) {
      let username = '';
      let password = '';

      if(type === 'uzk'){
        username = process.env.VUE_APP_USER_UZK_UN;
        password = process.env.VUE_APP_USER_UZK_PW;
      }

      if(type === 'zzp'){
        username = process.env.VUE_APP_USER_ZZP_UN;
        password = process.env.VUE_APP_USER_ZZP_PW;
      }

      if(type === 'emp'){
        username = process.env.VUE_APP_USER_EMP_UN;
        password = process.env.VUE_APP_USER_EMP_PW;
      }


      if (username && password) {
        this.$store.dispatch('general/startloading');
        this.$store.dispatch('auth/login_action', {
          username,
          password
        }).then(async (response) => {
          await this.$store.dispatch('auth/get_csrf_token_action');
          this.handleResponse(response)
        });
      }

    },


    handleResponse(response) {
      console.log('User logged in, so clear shift filters');
      this.$store.dispatch('shift/reset_filters');

      if (response.message === 'login_success') {
        const hasRedirect = this.$helpers.getUrlParam('redirectFromLogin');
        if(hasRedirect){
          this.$router.push({ path: hasRedirect });
        }else{
          this.redirectUserByGroup();
        }
      }

      if (response.message === 'login_success_first_login') {
        this.redirectUserByGroup(true);
      }

      if (response.message === 'credentials_incorrect') {
        this.loginFeedback = this.$t('login.feedback.credentials_incorrect');
      }

      if (response.message === 'user_pending') {
        if(response.user.onboardingHash.length){
          this.$store.dispatch('onboardingExperience/set_intake_user_info', response.user)
          this.$store.dispatch('onboardingGeneral/set_onboarding_hash', response.user.onboardingHash)
          this.$store.dispatch('onboardingGeneral/set_intake_user_group', response.user.group)

          this.$router.push({name: 'onboarding_experience_job_group_options'});
        }

        if(!response.user.onboardingHash.length){
          this.loginFeedback = this.$t('login.feedback.user_pending');
        }
      }

      this.$store.dispatch('general/endloading');
    },


    redirectUserByGroup(firstLogin) {
      const redirectRoute = redirectHomeForUserType(this.$store.getters['user/get_user'].group);

      if(firstLogin){
        this.$router.push({name: redirectRoute, query: { firstLogin: 'true' }});
      }else{
        this.$router.push({name: redirectRoute});
      }
    },
  },



};
</script>
