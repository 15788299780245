<template>
<div class="form-group">
    <label class="border border-gray-light rounded p-4 block items-center mt-3 mb-2 cursor-pointer" :class="{ 'bg-orange': isChecked, 'bg-noise': isChecked, 'text-white': isChecked }">
        <span v-if="isChecked" class="mr-3 text-black">V</span>
        <input type="checkbox" class="opacity-0 absolute" v-model="checked">

        {{ label }}
    </label>

    <textarea v-if="checked" ref="input" :id="id" :placeholder="placeholder" class="form-field"></textarea>
</div>
</template>

<script>
export default {
    props: {
        id: {
            required: true,
            type: String,
        },

        label: {
            required: true,
            type: String,
        },

        placeholder: {
            required: false,
            type: String,
        },

        checked: {
            required: false,
            type: Boolean,
            default: false,
        },
    },

    computed: {
        isChecked() {
            return this.checked === true || this.checked.length > 0;
        },
    },

    watch: {
        checked(value) {
            if (value) {
                this.$nextTick(() => this.$refs.input.focus());
            }
        }
    }
}
</script>
