import ErrorService from "@/services/errors/ErrorService";
import AxiosClient from "@/config/axios/axios";
const resource = "/time-sheet";


export default {

    fill(timeSheet) {
        return AxiosClient.post(`${resource}/fill`, timeSheet)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },


    getAll() {
        return AxiosClient.get(`${resource}/get/`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },

    getByDateRange(dateRange) {
        return AxiosClient.post(`${resource}/get/by-date-range`, dateRange)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },


    getDateFirstTimeSheet() {
        return AxiosClient.get(`${resource}/get/first`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },


    changeStatus(data) {
        return AxiosClient.post(`${resource}/status-change/`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },

    delete(data) {
        return AxiosClient.post(`${resource}/delete/`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },


}
