import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import nl from '@/locales/nl/nl.js'
import en from '@/locales/en/en.js'

function messages() {
    return  {
        en: en(),
        nl: nl()
    }
}

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: messages()
})
