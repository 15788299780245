<template>
<div>
  <template v-if="!routeName">
    <button type="button" :class="[classes, extraClasses]" :disabled="disabled">
      {{text}}
    </button>
  </template>

  <template v-if="routeName">
    <router-link :to="{name: routeName}" tag="button" :class="[classes, extraClasses]" :disabled="disabled">{{text}}</router-link>
  </template>
</div>

</template>

<script>
export default {
  props: {
    routeName: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    bgColor: {
        type: String,
        default: 'white',
    },
    extraClasses:{
      type: String,
      default: '',
    }
  },

  computed: {
    classes() {
        return `${this.extraClasses} button__width-equal button--primary_text--normal bg-noise--${this.bgColor}`
    }
  },

  data: () => ({
  }),
}
</script>

<style scoped>
button:disabled,
button[disabled]{
  background-color: #cccccc;
  color: #666666;
}
</style>
