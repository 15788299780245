<template>
  <div>
    <ValidationProvider class="w-1/2" rules="required" v-slot="{ errors }">
      <div class="relative w-full">
        <i class="icon-clock_thin absolute text-xl left-0 ml-4 text-gray-darker transform -translate-y-1/2 fill-current top-1/2" />

        <datetime type="time"
                  input-class="w-full px-2 py-3 border rounded-md border-gray-light pl-14 focus:outline-none"
                  v-model="time"
                  :min-datetime="disabledTimesUpTo"
                  :max-datetime="disabledTimesFrom"
                  :placeholder="placeholder"
                  :minute-step="15"
        />

      </div>
      <div class="validate-error">{{ errors[0] }}</div>
    </ValidationProvider>
  </div>
</template>

<script>

import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import {Settings} from 'luxon'
Settings.defaultLocale = 'nl'


export default {

  props: {
    placeholder: {
      type: String,
      required: false,
    },
    disabledTimesUpTo: {
      type: String,
      required: false,
    },
    disabledTimesFrom: {
      type: String,
      required: false,
    },
    initialTime: {
      type: String,
      required: false,
    },
  },

  components: {
    Datetime,
  },

  data: () => ({
    time: '',
  }),

  watch: {
    time: function (value) {
      this.$emit('timeChanged', value)
    }
  },

  methods: {
    checkForInitialValues(){
      if(this.initialTime !== undefined){
        this.time = this.initialTime;
      }
    }
  },

  created() {
    this.checkForInitialValues();
  }
}
</script>
