<template>
  <div v-if="dataFetched">
    <div class="container mx-auto px-6 py-4 -mt-28 relative z-10 border-b border-gray-lighter mb-4">
      <div class="flex flex-col items-center">
        <ImageUpload class="rounded-full h-40 w-40"
                     @fileChange="updateSelectedPhoto"
                     :initial_image="photoUrl"
                     :has-button="false"
                     :has-subtext="false"
        />
        <span class="inline-block self-center bg-black text-white text-sm rounded px-3 py-1 -mt-2 mb-1" style="z-index: 10">{{ userGroup_mixin(user.group) }}</span>
      </div>

      <h2 class="text-lg mb-1">{{ user.firstName }}</h2>
      <div class="block">
        <p v-if="user.jobsMain.length" class="text-gray-dark mb-6">
          <span v-for="(job, index) in user.jobsMain" :key="index">
            {{ job.title }}<span v-if="index !== user.jobsMain.length - 1">,</span>
          </span>
        </p>
      </div>


      <div class="text-gray-dark mb-8 leading-relaxed block">
        <div class="mb-1"><i class="icon-pin mr-1"/> {{ user.city }} (woonplaats)</div>
        <div v-if="user.driversLicence"><i class="icon-checkmark_alt mr-1 text-green"/> Rijbewijs</div>
        <div v-if="!user.driversLicence"><i class="icon-cross mr-1 text-red"/> Rijbewijs</div>
      </div>

      <div class="block">
        <h3>Omschrijving</h3>

        <p class="text-gray-dark" v-html="user.description"></p>
      </div>

      <div v-if="user.educations[user.jobGroupId]" class="block">
        <h3>Opleidingen</h3>

        <ul v-for="(education, index) in user.educations[user.jobGroupId]" class="mb-1" :key="index">
          <li >{{ education.title }}</li>
        </ul>
      </div>

      <div v-if="user.certificates[user.jobGroupId]" class="block">
        <h3>Certificaten</h3>

        <ul v-for="(certificate, index) in user.certificates[user.jobGroupId]" class="text-gray mb-1" :key="index">
          <li>{{ certificate.title }}</li>
        </ul>
      </div>

      <div v-if="user.specialisations.length" class="block">
        <h3>Speciale skills</h3>

        <ul v-for="(specialty, index) in user.specialisations" class="text-gray mb-1" :key="index">
          <li>{{ specialty.title }}</li>
        </ul>
      </div>

      <div class="block">
        <h3>Talen</h3>
        <Flag class="mr-2" v-for="(language, index) in user.languages" :code="language.code" size="small" :key="index"/>
      </div>

      <div class="block" v-if="workexperienceGroupedByJob">
        <div v-for="(job, index) in Object.keys(workexperienceGroupedByJob)" :key="`${index}_with_experience`" class="mb-6">
          <h3>Werkervaring {{ job }}</h3>
          <ul v-for="(experience, innerIndex) in workexperienceGroupedByJob[job]" class="text-gray mb-1" :key="innerIndex">
            <li>
              {{ experience.nameOfEmployer }}, {{ experience.city }} -
              <template v-if="experience.yearsExperience">{{ experience.yearsExperience }} jaar</template>
              <template v-if="experience.yearsExperience && experience.monthsExperience"> & </template>
              <template v-if="experience.monthsExperience">{{ experience.monthsExperience }} maanden</template>
            </li>
          </ul>
        </div>
      </div>


    </div>

    <div class="container mx-auto p-4 flex flex-col items-center text-xs mb-8" v-if="!employerIsViewing">
      <h3 class="text-xs mb-1">Profiel wijzigen?</h3>
      <p class="leading-loose text-gray text-center">
        Wil je gegevens in je account wijzigen, stuur My Shift team een mail met je wijzigen.<br/>
        <a :href="`mailto:${myShiftsMail}`">Wijzigingen doorgeven ({{ myShiftsMail }})</a>
      </p>

    </div>
  </div>
</template>


<script>
import Flag from '@/components/Flag';
import constants from "@/settings/constants";
import ImageUpload from "@/components/image/ImageUpload";


export default {



  components: {
    Flag,
    ImageUpload
  },

  data: () => ({
    dataFetched: false,
    user: '',
    photoUrl: null,
    employerIsViewing: false,
    myShiftsMail: process.env.VUE_APP_MYSHIFTS_MAILTO,
  }),


  computed: {
    workexperienceGroupedByJob: function () {
      const workExperience = this.user.workexperience;
      if(workExperience){
        let withWorkExperience = workExperience.filter((experience) => {
          return !experience.hasNoExperience;
        })
        return this.lodash.groupBy(withWorkExperience, 'job')
      }

    return workExperience;
    },

    // noWorkexperience: function () {
    //   return this.user.workexperience.filter((experience) => {
    //     return experience.hasNoExperience;
    //   })
    // },
  },

  methods: {
    updateSelectedPhoto(photo, photoName,) {
      let formData = new FormData();
      formData.append('photo', photo, photoName);
      formData.append('data', JSON.stringify(this.buildProfilePhotoData()));
      this.$store.dispatch('user/user_profile_photo_action', formData).then((response) => {
        this.$store.dispatch('user/user_update_profile_photo', response.data.photoUrl)
        this.photoUrl = response.data.photoUrl;
      });
    },

    buildProfilePhotoData() {
      return {
        userId: this.user.id,
      }
    },
  },

  async created() {
    this.$store.dispatch('general/startloading');
    const userGroup = this.$store.getters['user/get_user'].group;

    if (userGroup === constants.USERGROUP_EMPLOYER) {
      this.user = await this.$store.dispatch('user/user_get_by_id_action', this.$helpers.getUrlParam('id'));
      this.employerIsViewing = true;
    }

    if (userGroup !== constants.USERGROUP_EMPLOYER) {
      this.user = this.$store.getters['user/get_user'];
    }

    this.photoUrl = this.user.imageUrl;

    this.dataFetched = true;
    this.$store.dispatch('general/endloading');
  }
}
</script>

<style scoped>
.block {
  @apply mb-8 text-xs md:text-sm leading-loose;
}

.block h3 {
  @apply mb-1 text-sm md:text-base font-semibold;
}
</style>
