import repository from "@/repositories/repository";
const jobGroupRepository = repository.get("jobGroup");

const state = () => ({
    jobGroupsList: []
})

const mutations = {
    SET_JOB_GROUP_LIST(state, jobGroupsList) {
        state.jobGroupsList = jobGroupsList
    },
}

const actions = {
     fetch_all_jobGroups_action: async ({state, commit}) => {
         if (!state.jobGroupsList.length) {
             const response = await jobGroupRepository.getAll();
             if(response){
                 commit('SET_JOB_GROUP_LIST', response.data);
                 return response.data;
             }
         }
    },
}

const getters = {
    get_jobGroup_list: (state) => {
       return state.jobGroupsList;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
