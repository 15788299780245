import i18n from '@/config/i18n'

export default (router) => {
    router.beforeEach((to, from, next) => {
        let language = i18n.locale;

        //default to english
        if(!language){
            language = 'en';
        }

        i18n.locale = language;
        next();
    })
}
