<template>
  <div class="time_registration mb-6 border border-gray-light rounded">
    <div class="py-3 px-4 flex">
      <div class="relative rounded-full w-16 h-18 pb-2 mr-4">
        <img class="text-center w-full h-full rounded-full" :src="timeSheet.registrant.imageUrl" />
        <div class="absolute flex items-center bg-black text-xs text-white rounded bottom-0 leading-tight py-1 px-2 " style="left: 50%; transform: translate(-50%, 0)">
          {{ userGroup_mixin(timeSheet.registrant.group) }}
        </div>
      </div>
      <div>
        <p class="text-sm text-gray mb-0">{{ timeSheet.title }}</p>
        <h3 class="text-lg mb-0">{{ timeSheet.registrant.firstName }}</h3>
        <p class="text-xs text-orange my-0">{{ timeSheet.shift.start | moment("dddd D MMMM") }}</p>
        <p v-if="timeSheet.location" class="text-xs text-orange mb-2"><strong>{{ timeSheet.location }}</strong></p>
      </div>
    </div>

    <div :class="`time_registration__hours bg-noise-bottom bg-noise--gray ${types[timeSheet.employer_status].bg} py-3 px-4 flex justify-between items-center`">
      <div>
        <!-- <div class="text-xl text-gray-darker leading-none mb-2">{{ timeSheet.duration.hours }} uur <span v-if="timeSheet.duration.minutes !== '00'">, {{ timeSheet.duration.minutes }} minuten</span></div>
        <div class="text-xs text-gray">inc. {{ timeSheet.duration.break.breakInMinutes }} minuten pauze</div> -->
        <div class="text-xs text-gray">Starttijd: {{ $helpers.toNiceTime(timeSheet.shift.start) }}</div>
        <div class="text-xs text-gray">Eindtijd: {{ $helpers.toNiceTime(timeSheet.shift.end) }}</div>
        <div class="text-xs text-gray">Pauze: {{ timeSheet.duration.break.breakInMinutes }} minuten</div>
        <!--<div class="text-xs text-gray">Reiskosten: {{ timeSheet }}</div>-->
      </div>
      <div v-if="timeSheet.employer_status === 'unhandled'">
<!--        <button type="button" class="rounded-full bg-green h-10 w-10" @click="$emit('changeStatus', {timeSheet, status: 'approved'})">-->
<!--          <i class="icon-checkmark_alt text-white" />-->
<!--        </button>-->
        <button type="button" class="rounded-full bg-red h-10 w-10 ml-2" @click="$emit('changeStatus', {timeSheet, status: 'declined'})">
          <i class="icon-cross text-white" />
        </button>
      </div>
      <div v-else class="rounded-full bg-white h-10 w-10 flex justify-center items-center	">
        <i :class="`${types[timeSheet.employer_status].icon}`"/>
      </div>
    </div>
  </div>

</template>
<script>



export default {
  props: {
    timeSheet: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      toggledEdit: false,
      types: {
        unhandled: {
          bg: 'bg-gray-lighter',
        },
        approved: {
          bg: 'bg-green bg-opacity-10',
          icon: 'icon-checkmark_alt text-green',
        },
        declined: {
          bg: 'bg-yellow bg-opacity-10',
          icon: 'icon-clock text-yellow text-xl',
        },
      }
    }
  }

}
</script>

