import constants from "@/settings/constants";

export default (Vue) => {
    const plugin = {
        install() {
            Vue.constants = constants
            Vue.prototype.$constants = constants
        }
    }
    Vue.use(plugin)
}
