import AxiosClient from "@/config/axios/axios";
import store from "@/store/store";
import ErrorService from "@/services/errors/ErrorService";
const resource = "/recruiter";

export default {

    getSubscribedUsers() {
        store.dispatch('general/startloading');
        return AxiosClient.get(`${resource}/get/subscribed-users`)
            .then((response) => {
                store.dispatch('general/endloading');
                return response;
            })
            .catch((error) => {
                ErrorService.onError(error)
            })
    },

}
