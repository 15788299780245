import * as VueGoogleMaps from 'vue2-google-maps'

export default (Vue)=>{

    Vue.use(VueGoogleMaps, {
        load: {
            key: process.env.VUE_APP_GOOGLE_API_TOKEN,
            libraries: 'places', // This is required if you use the Autocomplete plugin
        },
        options:{
            disableDefaultUI: true,
            scrollwheel: false,
            styles: [{
                'featureType': 'administrative',
                'elementType': 'geometry',
                'stylers': [{color: '#d59563', 'visibility': 'off'}]
            },
                {
                    'featureType': 'poi',
                    'stylers': [{color: '#d59563', 'visibility': 'off'}]
                },
                {
                    'featureType': 'road',
                    'elementType': 'labels.icon',
                    'stylers': [{color: '#d59563', 'visibility': 'off'}]
                },
                {
                    'featureType': 'transit',
                    'stylers': [{color: '#d59563', 'visibility': 'off'}]
                }]
        }


    })
}


