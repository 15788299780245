import AxiosClient from "@/config/axios/axios";

const resource = "/auth";

export default {


    login(username, password) {

        const data = {
            'username': username,
            'password': password,
        }

        return AxiosClient.post(`${resource}/login`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            })
    },

    loginAsUser(uid) {
        return AxiosClient.post(`${resource}/login?uid=${uid}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            })
    },


    logout() {
     return AxiosClient.get(`${resource}/logout`)
    },


    resetRefreshToken() {
        return AxiosClient.get(`${resource}/refresh_token`)
    },


    passwordResetInit($username) {

        const data = {
            'username': $username
        }

        return AxiosClient.post(`${resource}/password_reset_init`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            })
    },


    passwordReset(newPassword, code, id) {

        const data = {
            'code': code,
            'id': id,
            'newPassword': newPassword
        }

        return AxiosClient.post(`${resource}/password_reset`, data)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            })
    },


    getCsrf() {
        return AxiosClient.get(`${resource}/get-csrf`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error.response;
            })
    },


}
