import ErrorService from "@/services/errors/ErrorService";
import AxiosClient from "@/config/axios/axios";
const resource = "/shift-registration";

export default {
    create(formData) {
        return AxiosClient.post(`${resource}/create/`, formData)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },


    accept(data) {
        return AxiosClient.post(`${resource}/accept/`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },


    decline(data) {
        return AxiosClient.post(`${resource}/decline/`, data)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },


    cancel(registrationId) {
        return AxiosClient.post(`${resource}/cancel/?id=${registrationId}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },


    unsubscribe(formData) {
        return AxiosClient.post(`${resource}/unsubscribe/`, formData)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                ErrorService.onError(error);
            })
    },
}
