<template>
  <div>
    <template v-if="label">
      <label :for="id" class="font-bold block mb-2">{{label}} <span v-if="required">*</span></label>
    </template>

    <ValidationProvider :rules="required ? 'required' : ''" v-slot="{ errors }">
      <textarea class="form-field"
          :id="id"
          :value="value"
          :placeholder="placeholder"
          @input="$emit('input', $event.target.value)"
          rows="10" cols="30"></textarea>

      <div class="validate-error">{{ errors[0] }}</div>
    </ValidationProvider>

  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },

    label: {
      required: false,
      type: String,
    },

    placeholder: {
      required: false,
      type: String,
    },

    required: {
      required: false,
      type: Boolean,
    },

    value: {
      required: false,
      type: String,
    },

  },
}
</script>
