<template>
  <div class="text-xs">
    <div class="flex items-center cursor-pointer relative" @click="isOpen = !isOpen">
      <i class="icon text-orange mr-2" :class="{ 'icon-plus': !isOpen, 'icon-minus': isOpen }"/>
      <h3 class="mb-0 font-normal text-orange">{{ title }}</h3>
    </div>

    <div v-if="isOpen" class="ml-4 py-2 text-xs text-gray" @click="isOpen = false">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    isOpen: false,
  }),


}
</script>

<style scoped>
.icon {
  font-size: .5rem;
}
</style>
