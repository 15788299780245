const USERGROUP_SELF_EMPLOYED = 2;
const USERGROUP_TEMPORARY_EMPLOYED = 1;
const USERGROUP_RECRUITER = 3;
const USERGROUP_EMPLOYER = 6;

const JOB_GROUP_KITCHEN_CREW = 45;
const JOB_GROUP_HOSPITALITY = 48;
const JOB_GROUP_FACILITY = 51;

const MIN_AGE_TEMPORARY_EMPLOYED  = 18;
const MIN_AGE_SELF_EMPLOYED = 18;

const MIN_SHIFT_DURATION_HOURS  = 3;
const MAX_SHIFT_DURATION_HOURS = 12;


export default {
    USERGROUP_SELF_EMPLOYED,
    USERGROUP_TEMPORARY_EMPLOYED,
    USERGROUP_RECRUITER,
    USERGROUP_EMPLOYER,

    JOB_GROUP_KITCHEN_CREW,
    JOB_GROUP_HOSPITALITY,
    JOB_GROUP_FACILITY,

    MIN_AGE_TEMPORARY_EMPLOYED,
    MIN_AGE_SELF_EMPLOYED,

    MIN_SHIFT_DURATION_HOURS,
    MAX_SHIFT_DURATION_HOURS
};
