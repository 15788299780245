<template>
  <div>
    <ValidationProvider class="w-1/2" rules="required" v-slot="{ errors }">
      <div class="relative w-full">
      <svg class="absolute left-0 ml-4 text-green-900 transform -translate-y-1/2 fill-current top-1/2 " width="24px" height="23px" viewBox="0 0 24 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="" fill-rule="evenodd">
          <g transform="translate(-175.000000, -597.000000)" fill="" fill-rule="nonzero">
            <g transform="translate(175.000000, 597.000000)">
              <path d="M24,20 C24,21.6568542 22.6568542,23 21,23 L21,23 L3,23 C1.34314575,23 0,21.6568542 0,20 L0,20 L0,5 C0,3.34314575 1.34314575,2 3,2 L3,2 L5,2 L5,0 L7,0 L7,2 L17,2 L17,0 L19,0 L19,2 L21,2 C22.5976809,2 23.9036609,3.24891996 23.9949073,4.82372721 L24,5 Z M22,9 L2,9 L2,20 C2,20.5128358 2.38604019,20.9355072 2.88337887,20.9932723 L3,21 L21,21 C21.5522847,21 22,20.5522847 22,20 L22,20 L22,9 Z M8,16 L8,19 L4,19 L4,16 L8,16 Z M14,16 L14,19 L10,19 L10,16 L14,16 Z M8,11 L8,14 L4,14 L4,11 L8,11 Z M14,11 L14,14 L10,14 L10,11 L14,11 Z M20,11 L20,14 L16,14 L16,11 L20,11 Z M5,4 L3,4 C2.44771525,4 2,4.44771525 2,5 L2,5 L2,7 L22,7 L22,5 C22,4.48716416 21.6139598,4.06449284 21.1166211,4.00672773 L21,4 L19,4 L19,5 L17,5 L17,4 L7,4 L7,5 L5,5 L5,4 Z"></path>
            </g>
          </g>
        </g>
      </svg>
      <datetime :type="(timeEnabled) ? 'datetime' : ''" input-class="w-full px-2 py-3 border rounded-md border-gray-light pl-14 focus:outline-none" v-model="date" :min-datetime="disabledDatesUpTo" :max-datetime="disabledDatesFrom" :placeholder="placeholder"></datetime>
      </div>
      <div class="validate-error">{{ errors[0] }}</div>
    </ValidationProvider>
  </div>
</template>

<script>

//date-time
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import {Settings} from 'luxon'
Settings.defaultLocale = 'nl'

export default {

  props: {
    placeholder: {
      type: String,
      required: false,
    },
    disabledDatesUpTo: {
      type: String,
      required: false,
    },
    disabledDatesFrom: {
      type: String,
      required: false,
    },
    timeEnabled:{
      type: Boolean,
      required: true
    },
    initialDate: {
      type: String,
      required: false
    },
  },

  components: {
    Datetime,
  },

  data: () => ({
   date:'',
  }),

  watch: {
    date: function (value) {
      this.$emit('dateChanged', value)
    }
  },

  created() {
    if(this.initialDate){
      this.date = this.initialDate;
    }
  }
}
</script>
