<template>
  <div>

    <PageTitle title="Kleding" :centered="true" />

    <div class="container mx-auto px-4">
      <div class="w-full">

        <h2>Kleding aanvragen</h2>

        <p>Vraag hier je gratis kledingpakket aan <a href="https://api.whatsapp.com/send?phone=0653531999">0653531999</a> of via <a href="mailto:myshifts@dasroggel.nl">myshifts@dasroggel.nl</a> <br> Wil je extra kleding dan kun je deze hier direct bestellen bij onze leverancier DAS Roggel</p>


        <hr class="my-6">

        <h3>Kitchen Crew</h3>
        <ul class="mb-6">
          <li>Koksbuis met logo - €49,50</li>
          <li>Koksbuis met je naam en logo - €51,50</li>
        </ul>

        <h3>Hospitality Crew</h3>
        <ul class="mb-6">
          <li>Witte blouse met logo €39,50</li>
          <li>Witte blouse met logo en naam ( optie ) €41,50</li>
          <li>Complete kelner set: aansteker, pen, snel opener, kelners mes €12,50</li>
        </ul>

        <h3>Facilitair Crew</h3>
        <ul class="mb-6">
          <li>Housekeeping jas met logo €44,95</li>
          <li>Housekeeping jas met logo en naam ( optie ) €45,95</li>
        </ul>

        <h3>Extra merchandise</h3>
        <ul class="mb-6">
          <li>Polo met logo zwart/wit/oranje €19,95</li>
          <li>Polo met logo en naam zwart/wit/oranje ( optie ) €21,95</li>
          <li>Zwarte blouse met logo €39,50</li>
          <li>Zwarte blouse met logo en naam €41,50</li>
          <li>Be your own boss t-shirt €12,50</li>
          <li>Get your shifts together t-shirt €12,50</li>
          <li>Plain t-shirt met logo €12,50</li>
        </ul>

        <p>*<i>Levering binnen 5 werkdagen na betaling van de factuur.</i></p>


      </div>
    </div>
  </div>
</template>

<script>


export default {
  data: () => ({
    urlEasyflex: process.env.VUE_APP_EASYFLEX_LOGIN,
    urlBookHoursForm: process.env.VUE_APP_BOOK_HOURS_FORM,
    emailJobplanet: process.env.VUE_APP_JOBPLANET_EMAIL,

    phoneNumberJobplanet: process.env.VUE_APP_JOBPLANET_PHONE,
    phoneNumberJobplanetVisual: process.env.VUE_APP_JOBPLANET_PHONE_VISUAL,

    phoneNumberInvoices: process.env.VUE_APP_JOBPLANET_PHONE_INVOICES,
    phoneNumberInvoicesVisual: process.env.VUE_APP_JOBPLANET_PHONE_INVOICES_VISUAL,

    pdfUrlTemporary: `${process.env.VUE_APP_PUBLIC_BACKEND_URL}data/instructies_uren_boeken_easyflex.pdf`,
    pdfUrlSelf: `${process.env.VUE_APP_PUBLIC_BACKEND_URL}data/facturatie_zzp.pdf`,
  }),
}
</script>

<style scoped>

</style>
