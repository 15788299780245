<template>
  <div class="form-group" :class="disabled ? 'disabled' : ''">
    <template v-if="label">
      <label :for="id">{{ label }} <span v-if="required">*</span></label>
    </template>

    <ValidationProvider :rules="required ? 'required' : ''" v-slot="{ errors }">

      <div class="form-select ">
        <select class="form-field"

                :name="id"
                :id="id"
                :value="selectedValue"
                @change="selectedValue = $event.target.value; $emit('onChange', selectedValue)"
                :disabled="disabled"
        >
          <option :value="null" :selected="!selected" :disabled="!selected">{{ placeholder }}</option>
          <option v-for="option in options" :key="option.id" :value="option.id">{{ option.title }}
          </option>
        </select>
      </div>

      <div class="validate-error">{{ errors[0] }}</div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
name: 'NewInputSelect',
  props: {
    id: {
      required: true,
      type: String,
    },

    selected: {
      required: false,
      type: [String, Number],
    },

    label: {
      required: false,
      type: String,
    },

    placeholder: {
      required: false,
      type: String,
      default: ''
    },

    required: {
      required: false,
      type: Boolean,
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false
    },

    options: {
      required: true,
      type: [Boolean, Array],
    },
  },

  data: () => ({
    selectedValue: ''
  }),

  created() {
      this.selectedValue = this.selected;
  }
}
</script>


<style>
.signupselect {
  background: url(https://i.ibb.co/fCsFTVL/icn-dropdown-chevron.png) no-repeat;
  width: 15px;
  height: 8px;
  background-size: contain;
}

.disabled {
  opacity: .5;
  filter: alpha(opacity=50); /* IE<9 */
  cursor: default;
}
</style>
