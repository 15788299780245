import constants from '@/settings/constants'

export function saveUserToLocalStorage(user) {
    localStorage.setItem('user', JSON.stringify(user));
}

export function removeUserFromLocalStorage() {
    localStorage.clear();
}

export function redirectHomeForUserType(userType) {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const uid = urlParams.get('uid');

    if (!uid) {
        switch(userType) {
            case constants.USERGROUP_TEMPORARY_EMPLOYED:
                return 'dashboard_employee' //route name
            case constants.USERGROUP_SELF_EMPLOYED:
            return 'dashboard_employee' //route name
            case constants.USERGROUP_RECRUITER:
                return 'dashboard_recruiter'
            case constants.USERGROUP_EMPLOYER:
                return 'dashboard_employer'
        }
    }
}
