<template>
<div>
  <ul>
    <li>My Shifts factureert elke week op maandag en dinsdag</li>
    <li>Facturen worden voorgefinancieerd door Impact Factoring, zorg dat je bij betaling het juiste klantnummer en rekeningnummer gebruikt zoals aangegeven op onze factuur</li>
    <li>Bedrijven die geen uitzendkrachten inhuren kunnen maximaal 21% op onze G-rekening storten</li>
    <li>Wij sturen vsanaf nu een aparte factuur voor het G-rekening gedeelte</li>
    <li>Het standaard betaaltermij is 21 dagen mits contractueel anders met ons is afgesproken</li>
    <li>Wij werken met kredietverzekering en limieten. Dat betekend dat je als bedrijf kan uitvragen totdat het kredietlimiet bereikt is. Heeft je bedrijf geen limiet ontvang je van ons een factuur en kun je vooruit betalen. Je kan dus pre paid opwaarderen om uit te vragen. Zonder limiet is uitvragen zonder vooruitbetaling niet meer mogelijk!</li>
    <li>Heb je vragen over je limiet? Dan kun je ons bellen: 077-2030060</li>
    <li>Heb je vragen of je factuur? Dan kun je ons mailen: <a href="mailto:debiteuren@myshifts.nl">debiteuren@myshifts.nl</a></li>
    <li>Financieel contactpersoon: Marjanka van der Weide, CFO My Shifts</li>
  </ul>
</div>
</template>

<script>
export default {
  props: {
    JEXdata: {
      type: Object,
      required: true,
    }
  },
}
</script>
