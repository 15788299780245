<template>
  <div>
    <div class="bg-black bg-noise-top text-white py-4">
      <div class="flex flex-col items-center text-center p-4">
        <a class="no-underline mt-2 mb-4" href="/">
          <LogoBlackSvg/>
        </a>
        <h1>{{ $t('onboarding.appointment.header.title') }}</h1>
        <p class="lead">{{ $t('onboarding.appointment.header.subtitle') }}</p>
      </div>
    </div>
    <div class="black-bar"></div>

    <div class="container mx-auto px-10">
		<div class="mt-10">
			<h3>Welkom bij My Shifts!</h3>
			<p>Een van onze medewerkers gaat je zo spoedig mogelijk bellen om te zorgen dat je account geactiveerd kan worden. <br>Je mag ons ook direct bellen of mailen.</p>
		</div>
		<div class="mt-10">
			<ul>
				<li>Telefoon: 077-2030060</li>
				<li>Mail: <a href="mailto:frontoffice@myshifts.nl">frontoffice@myshifts.nl</a></li>
			</ul>
		</div>
    </div>
  </div>
</template>

<script>
import CalendlyService from "@/services/CalendlyService";
import constants from "@/settings/constants";

export default {

  data() {
    return {
      jobGroupId: null,
      calendlyLink: null
    }
  },

  computed: {
    jobGroups: function() {
      return this.$store.getters['jobGroup/get_jobGroup_list'];
    },

    componentByUserType: function () {
      switch (this.userGroup) {
        case constants.USERGROUP_SELF_EMPLOYED:
          return 'appointmentSelfEmployed';
        case constants.USERGROUP_TEMPORARY_EMPLOYED:
          return 'appointmentTemporaryEmployed';
        case constants.USERGROUP_EMPLOYER:
          return 'appointmentEmployer';
        default:
          return 'appointmentEmployer';
      }
    },

    calendlyCategory() {
      //1. get calendly info
      const calendlyInfo = this.$store.getters['onboardingGeneral/get_calendly_info'];
      //2. check if the user falls in any of the western provinces
      return (new CalendlyService(calendlyInfo.province, calendlyInfo.jobGroup)).getCalendlyUrl();
    },

    // isEmployer() {
    //   return constants.USERGROUP_EMPLOYER === this.userGroup;
    // },

    userGroup() {
      const userGroup = this.$store.getters['onboardingGeneral/get_intake_user_group'];
      return userGroup.userGroup;
    }
  },


  methods: {
    openCalendly() {
      if (this.calendlyLink) {
        console.log('Passing link from CMS to calendly', this.calendlyLink);
        // eslint-disable-next-line no-undef
        Calendly.initPopupWidget({url: this.calendlyLink})        
      } else {
        // eslint-disable-next-line no-undef
        Calendly.initPopupWidget({url: this.calendlyCategory})
      }
    },

    getCalendlyLinkFromCraftCMS: function() {
      for (let i = 0; i < this.jobGroups.length; i++) {
        if (this.jobGroups[i].id == this.jobGroupId) {
          this.calendlyLink = this.jobGroups[i].calendlyLink;
          console.log(this.jobGroupId, this.calendlyLink);
        }
      }
    },

    alertIntakeIsRequired() {
      return this.$swal({
        title: this.$t('onboarding.appointment.alert.title'),
        text: this.$t('onboarding.appointment.alert.text'),
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
        focusConfirm: false,
      }).then((result) => {
        return result.isConfirmed;
      })
    },
  },

  async created() {
    // What's our jobGroup ID?
    this.jobGroupId = this.$store.getters['onboardingGeneral/get_calendly_info'].jobGroup;
    console.log('Job group is', this.jobGroupId);

    // Dispatch fetching of jobGroups
    this.jobGroups = await this.$store.dispatch('jobGroup/fetch_all_jobGroups_action');

    this.getCalendlyLinkFromCraftCMS();

    let calendlyScript = document.createElement('script')
    calendlyScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    document.head.appendChild(calendlyScript)

    let calendlyStyle = document.createElement('link');
    calendlyStyle.type = "text/css";
    calendlyStyle.rel = "stylesheet";
    calendlyStyle.href = 'https://assets.calendly.com/assets/external/widget.css';
    document.head.appendChild(calendlyStyle);
  }
}
</script>
