import authRepository from './authRepository';
import userRepository from './userRepository';
import educationRepository from "@/repositories/educationRepository";
import certificateRepository from "@/repositories/certificateRepository";
import jobRepository from "@/repositories/jobRepository";
import jobGroupRepository from "@/repositories/jobGroupRepository";
import caoRepository from "@/repositories/caoRepository";
import languageRepository from "@/repositories/languageRepository";
import recruiterRepository from "@/repositories/recruiterRepository";
import shiftRepository from "@/repositories/shiftRepository";
import shiftRegistrationRepository from "@/repositories/shiftRegistrationRepository";


const repositories = {
    'auth': authRepository,
    'user': userRepository,
    'education' : educationRepository,
    'certificate' : certificateRepository,
    'job': jobRepository,
    'jobGroup': jobGroupRepository,
    'cao': caoRepository,
    'language': languageRepository,
    'recruiter': recruiterRepository,
    'shift': shiftRepository,
    'shiftRegistration': shiftRegistrationRepository,
}



export default {
    get: name => repositories[name]
};
