<template>
  <div class="shift_detail__header" style="margin-top: -4rem">
    <Slick ref="slider" :options="sliderOptions_mixin" v-if="shift.photos.length">
      <template v-for="(photo,index) in shift.photos">
        <div class="shift_detail__header__slide" :key="index" :style="`background-image: url(${photo.imageUrl});`"></div>
      </template>
    </Slick>

    <div v-else class="shift_detail__header__slide">
      <img src="@/assets/images/shift_placeholder.jpg" alt="" style="border-radius: 0.5rem;">
    </div>

    <span class="shift_detail__header__details">
      <span class="shift_detail__header__rate bg-noise-bottom">
        <span class="shift_detail__header__rate__symbol">&euro;</span>
        <span class="shift_detail__header__rate__major">{{ (shift.averageSalary + "").split(".")[0] }}</span>
        <span class="shift_detail__header__rate__minor">{{
            padNumbers(shift.averageSalary + "").split(".")[1] ? padNumbers(shift.averageSalary + "").split(".")[1] : '00'
          }}
        </span>
        <span class="shift_detail__header__rate__period">/uur</span>
      </span>
      <span class="shift_detail__header__hours bg-noise-top">
        <span class="shift_detail__header__hours__amount">
          {{ shift.durationHoursMinutes.hours }},{{ shift.durationHoursMinutes.minutes }}
        </span>
        <span class="shift_detail__header__hours__unit">uur</span>
      </span>
    </span>
  </div>
</template>

<script>
import Slick from 'vue-slick';

export default {
  components:{
    Slick,
  },

  props: {
    shift: {
      type: Object,
      required: true
    },
  },


  methods:{
    padNumbers(value) {
      return String(value).padStart(2, '0');
    },
  }
}
</script>


<style>
.shift_detail__header .slick-prev {
  @apply z-20;

  display: none !important;
  top: calc(50% - 1.25rem) !important;
  left: calc(50% - 480px - 1.25rem);
}

.shift_detail__header .slick-next {
  @apply z-20;

  display: none !important;
  top: calc(50% - 1.25rem) !important;
  left: calc(50% + 480px - 1.25rem);
}

@media (min-width: 640px) {
  .shift_detail__header .slick-prev,
  .shift_detail__header .slick-next {
    display: block !important;
  }
}
</style>

<style scoped>
.shift_detail__header {
  @apply -mt-32 mb-36 w-full relative;

  height: 260px;
}

.shift_detail__header__slide {
  @apply w-full bg-cover bg-center rounded-lg relative mx-6;

  height: 260px;
  width: calc(100vw - 3rem) !important;
  max-width: calc(100vw - 3rem) !important;
}

.shift_detail__header__details {
  @apply absolute bottom-0 left-0 px-6;
}

@media (min-width: 640px) {
  .shift_detail__header {
    height: 480px;
  }

  .shift_detail__header__slide {
    height: 480px;
    width: 640px !important;
    max-width: calc(100vw - 3rem) !important;
  }

  .shift_detail__header__details {
    left: calc(50% - 320px);
  }
}

@media (min-width: 640px) {
  .shift_detail__header__slide {
    height: 480px;
    width: 960px !important;
  }

  .shift_detail__header__details {
    left: calc(50% - 480px);
  }
}

.shift_detail__header__location span {
  @apply text-gray pl-2;
}

.shift_detail__header__rate {
  @apply relative inline-block bg-orange p-3 pt-2 pr-4;

  bottom: -.5rem;
}

.shift_detail__header__rate > * {
  @apply leading-none align-top;
}

.shift_detail__header__rate__symbol {
  @apply font-semibold text-sm;
}

.shift_detail__header__rate__major {
  @apply relative font-semibold px-1 mb-2;

  font-size: 1.75rem;
}

.shift_detail__header__rate__minor {
  @apply font-semibold text-sm pr-1;
}

.shift_detail__header__rate__period {
  @apply absolute bottom-0 right-0 pb-2 pr-3 text-xs;
}

.shift_detail__header__hours {
  @apply relative inline-block bg-black text-white py-2 px-3 pt-2;
}

.shift_detail__header__hours > * {
  @apply leading-none align-top;
}

.shift_detail__header__hours__amount {
  @apply relative font-semibold text-2xl px-1;
}

.shift_detail__header__hours__unit {
  @apply text-sm pr-1;
}


</style>
