import Login from "@/views/authentication/Login.vue";
import PasswordReset from "@/views/authentication/PasswordReset.vue";
import PasswordForgot from "@/views/authentication/PasswordForgot.vue";

export default [
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            requiresAuth: false,
            layout: 'no-navigation'
        }
    },
    {
        path: "/password-reset",
        name: "password-reset",
        component: PasswordReset,
        meta: {
            requiresAuth: false,
            layout: 'no-navigation'
        }
    },
    {
        path: "/password-forgot",
        name: "password-forgot",
        component: PasswordForgot,
        meta: {
            requiresAuth: false,
            layout: 'no-navigation'
        }
    },
]
