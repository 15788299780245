import store from "@/store/store";
import axios from "@/config/axios/axios";


export default (axiosConfig) => {

    let isRefreshing = false;
    let failedQueue = [];

    const processQueue = (error) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve();
            }
        });

        failedQueue = [];
    };

    axiosConfig.interceptors.response.use((response) => {
        return response;
    }, async (err) => {
        const originalRequest = err.config;

        if (err.response.data.message === 'token_verification_error') {
            // strange behaviour -> logout
            store.dispatch('auth/logout_action');
        }

        // 401 access token needs to be refreshed
        if (err.response.data.message === 'new_access_token_required' && !originalRequest._retry) {

            //requests after the 1st request has failed
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    //create array of failed requests
                    failedQueue.push({resolve, reject});
                })
                    .then(() => {
                        return axios(originalRequest);
                    })
                    .catch(err => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            //Start refreshing RT token
            return new Promise(function (resolve, reject) {
                store.dispatch('auth/reset_refresh_token')
                    .then(() => {
                        //run all subsequent requests again
                        processQueue(null);
                        //try the 1st request that failed again
                        resolve(axios(originalRequest));
                    })
                    .catch(err => {
                        processQueue(err);
                        //logout if no token, token expired or token mismatch
                        store.dispatch('auth/logout_action');
                        reject(err);
                    })
                    .then(() => {
                        isRefreshing = false;
                    });
            });
        }

        return Promise.reject(err);
    });

}
