<template>
<div>
    <div class="text-center">
        <button class="button button--primary" @click.prevent="submit();">
            {{ buttonText }}
        </button>
    </div>
</div>
</template>

<script>
export default {
    props: {
        nextRouteName: {
            type: String,
            required: true
        },
        buttonText: {
            type: String,
            required: true
        },
    },

    methods: {
        submit() {
            this.$emit('submit')
        },

        navigate() {
            this.$router.push({
                name: this.nextRouteName
            });
        }
    }

}
</script>
