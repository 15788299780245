//EMPLOYEES
import OnboardingOptions from "@/views/onboarding/employees/OnboardingOptions";
import Personal from "@/views/onboarding/employees/Personal";
import ExperienceJobGroupOptions from "@/views/onboarding/employees/ExperienceJobGroupOptions";
import ExperienceEducation from "@/views/onboarding/employees/ExperienceEducation";
import ExperienceJobs from "@/views/onboarding/employees/ExperienceJobs";
import Profile from "@/views/onboarding/employees/Profile";
import Appointment from "@/views/onboarding/Appointment";

//RECRUITER
import RecruiterPersonal from "@/views/onboarding/recruiter/Personal";


//Employer
import EmployerPersonal from "@/views/onboarding/employer/Personal";


export default [
    //GENERAL
    {
        path: '/onboarding/options/',
        name: 'onboarding_options',
        component: OnboardingOptions,
        meta: {
            requiresAuth: false,
            onboardingOnly: false,
            layout: 'onboarding'
        }
    },
    {
        path: '/onboarding/appointment',
        name: 'onboarding_appointment',
        component: Appointment,
        meta: {
            requiresAuth: false,
            onboardingOnly: false,
            layout: 'onboarding'
        }
    },

    //EMPLOYEES
    {
        path: '/onboarding/personal',
        name: 'onboarding_personal',
        component: Personal,
        meta: {
            requiresAuth: false,
            onboardingOnly: false,
            layout: 'onboarding'
        }
    },
    {
        path: '/onboarding/experience/job-group-options',
        name: 'onboarding_experience_job_group_options',
        component: ExperienceJobGroupOptions,
        meta: {
            requiresAuth: false,
            onboardingOnly: true,
            layout: 'onboarding'
        }
    },
    {
        path: '/onboarding/experience/education',
        name: 'onboarding_experience_education',
        component: ExperienceEducation,
        meta: {
            requiresAuth: false,
            onboardingOnly: true,
            layout: 'onboarding'
        }
    },
    {
        path: '/onboarding/experience/jobs',
        name: 'onboarding_experience_jobs',
        component: ExperienceJobs,
        meta: {
            requiresAuth: false,
            onboardingOnly: true,
            layout: 'onboarding'
        }
    },
    {
        path: '/onboarding/profile',
        name: 'onboarding_profile',
        component: Profile,
        meta: {
            requiresAuth: false,
            onboardingOnly: true,
            layout: 'onboarding'
        }
    },


    //RECRUITER
    {
        path: '/onboarding/recruiter/personal',
        name: 'onboarding_recruiter_personal',
        component: RecruiterPersonal,
        meta: {
            requiresAuth: false,
            onboardingOnly: false,
            layout: 'onboarding'
        }
    },



    //EMPLOYER
    {
        path: '/onboarding/employer/personal',
        name: 'onboarding_employer_personal',
        component: EmployerPersonal,
        meta: {
            requiresAuth: false,
            onboardingOnly: false,
            layout: 'onboarding'
        }
    },


]
