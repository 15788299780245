<template>
    <div class="w-full mt-4 text-left relative">
        <slot></slot>
      <Slick ref="slick" :options="slickOptions_mixin" class="slick-slider--no-margin">
        <!--    SHIFT ITEM SLOT    -->
        <slot name="shiftItem" v-bind:data="shifts">

        </slot>
        <!--    SHIFT ITEM SLOT    -->
      </Slick>
    </div>
</template>

<script>

import Slick from "vue-slick";

export default {
  props: {
    shifts: {
      type: Array,
      required: true
    },
  },

  components: {
    Slick,
  },
}
</script>
