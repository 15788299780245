<template>
<div class="form-group">
    <template v-if="label">
        <label :for="id">{{label}} <span v-if="required">*</span></label>
    </template>

    <ValidationProvider rules="required|min:8" v-slot="{ errors, classes }">
        <div class="relative">
            <input class="form-field"
                :id="id"
                :class="classes"
                :type="(isVisible) ? 'text' : 'password'"
                :value="value"
                :placeholder="placeholder"
                :autocomplete="autocomplete"
                @input="$emit('input', $event.target.value)"
            />

            <span class="absolute right-0 p-4 text-sm text-gray hover:text-black cursor-pointer lowercase" v-if="!isVisible" @click="isVisible = !isVisible;">
                <i class="icon-eye_closed" />
            </span>
            <span class="absolute right-0 p-4 text-sm text-gray hover:text-black cursor-pointer lowercase" v-if="isVisible" @click="isVisible = !isVisible;">
                <i class="icon-eye_open" />
            </span>
        </div>

        <div class="validate-error">{{ errors[0] }}</div>
    </ValidationProvider>

</div>
</template>

<script>
export default {
    props: {
        id: {
            required: true,
            type: String,
        },

        label: {
            required: false,
            type: String,
        },

        placeholder: {
            required: false,
            type: String,
        },

        required: {
            required: false,
            type: Boolean,
        },

        value: {
            required: false,
            type: String,
        },

        autocomplete: {
            type: String,
            default: 'on',
        }
    },

    data: () => ({
        isVisible: false,
    }),
}
</script>
