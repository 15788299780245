<template>
  <div>
    <div class="black-bar"></div>
    <div class="flex flex-col items-center p-4">
      <a class="no-underline mt-2 mb-4" href="/">
        <LogoBlackSvg/>
      </a>

      <h1>{{ $t('onboarding.options.header.title') }}</h1>
      <p>{{ $t('onboarding.options.header.subtitle') }}</p>

      <div class="flex flex-col md:flex-row md:gap-8 w-full md:w-auto">
        <!-- <button type="button"
                class="flex flex-col justify-start md:w-1/2 group rounded text-left p-8 md:pt-10 m-2 border border-gray-light hover:bg-gray-lighter"
                @click="setUserType($constants.USERGROUP_TEMPORARY_EMPLOYED)"
        >
          <div class="block w-full text-center mb-5 md:mb-8">
            <h2 class="text-orange leading-tight group-hover:text-black">
              {{ $t('onboarding.options.button1_text') }}
            </h2>
            <p class="text-xs md:text-sm text-gray">{{ $t('onboarding.options.button1_subtext') }}</p>
          </div>
          <ul>
            <li>{{ $t('onboarding.options.button1_usps.1') }}</li>
            <li>{{ $t('onboarding.options.button1_usps.2') }}</li>
            <li>{{ $t('onboarding.options.button1_usps.3') }}</li>
            <li>{{ $t('onboarding.options.button1_usps.4') }}</li>
            <li>{{ $t('onboarding.options.button1_usps.5') }}</li>
            <li>{{ $t('onboarding.options.button1_usps.6') }}</li>
          </ul>
          <span class="block w-full button button--primary mt-8 md:mt-auto">{{ $t('onboarding.options.button1_signup') }}</span>
        </button> -->

        <button type="button"
                class="flex flex-col justify-start group rounded text-left p-8 md:pt-10 m-2 border border-gray-light hover:bg-gray-lighter"
                @click="setUserType($constants.USERGROUP_SELF_EMPLOYED)"
        >
          <div class="block w-full text-center mb-5 md:mb-8">
            <h2 class="text-orange leading-tight group-hover:text-black">
              {{ $t('onboarding.options.button2_text') }}
            </h2>
            <p class="text-xs md:text-sm text-gray">{{ $t('onboarding.options.button2_subtext') }}</p>
          </div>
          <ul>
            <li>{{ $t('onboarding.options.button2_usps.1') }}</li>
            <li>{{ $t('onboarding.options.button2_usps.2') }}</li>
            <li>{{ $t('onboarding.options.button2_usps.3') }}</li>
            <li>{{ $t('onboarding.options.button2_usps.4') }}</li>
          </ul>
          <span class="block w-full button button--primary mt-8">{{ $t('onboarding.options.button2_signup') }}</span>
        </button>
      </div>


      <!-- <div class="container mx-auto">
        <div class="text-center my-8">
          <p class="text-center text-sm mb-0">{{ $t('onboarding.options.recruiter_text') }}</p>
          <p>
            <router-link class="text-sm" :to="{ name: 'onboarding_recruiter_personal' }">{{ $t('login.create_account_recruiter') }}</router-link>
          </p>
        </div>
      </div> -->
    </div>

<!--    <div>-->
<!--      <OverlaySmall>-->
<!--          <div class="flex justify-center mb-6">-->
<!--            <a :href="instructionVideoUrl" class="cursor-pointer" target="_blank"><img class="w-full rounded inline-block max-w-xs" alt="" src="@/assets/images/youtube_placeholder.jpg"/></a>-->
<!--          </div>-->

<!--          <div class="text-center">-->
<!--            <h3>{{ $t('feedback.instruction_video.title') }}</h3>-->
<!--            <a :href="instructionVideoUrl" class="cursor-pointer" target="_blank"> {{ $t('feedback.instruction_video.link_text') }}</a>-->
<!--          </div>-->
<!--      </OverlaySmall>-->
<!--    </div>-->


  </div>
</template>


<script>
//import OverlaySmall from "@/components/OverlaySmall";


export default {
  components: {
    //OverlaySmall,
  },

  data: () => ({
    instructionVideoUrl: process.env.VUE_APP_INSTRUCTION_VIDEO,
  }),

  methods: {
    setUserType(userGroup) {
      this.$store.dispatch('onboardingGeneral/set_intake_user_group', userGroup)
      this.$router.push({name: 'onboarding_personal'});
    },

    handleRecruiterCode() {
      const recruiterCode = this.$helpers.getUrlParam('recruiterCode');
      if (recruiterCode) {
        this.$store.dispatch('onboardingGeneral/set_recruiter_code', recruiterCode)
      }
    },


  },


  created() {
    this.handleRecruiterCode();

  },
}
</script>

<style lang="scss" scoped>
  button ul { 
    li {
      font-size: 14px !important;
      &:before {    
        content: '';    
        background-image: url('../../../assets/images/list-check.png');
        width: 16px;
        height: 12px;
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 6px;
      }
    }
  }
  button span.button {
    width: 100%;
    text-align: center;
  }
</style>