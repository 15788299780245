export default {

    data: () => ({
        slickOptions_mixin: {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: false,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false
                    }
                }
            ]
        },
    }),
}
