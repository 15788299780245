import nl_views from '@/locales/nl/nl_views.json'
import nl_general from '@/locales/nl/nl_general.json'
import nl_components from '@/locales/nl/nl_components.json'
import nl_onboarding from '@/locales/nl/nl_onboarding.json'



export default () => {
    return {
        ...nl_views,
        ...nl_general,
        ...nl_components,
        ...nl_onboarding,
    }
}
