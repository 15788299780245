import timeSheetRepository from "@/repositories/timeSheetRepository";

export default {
    data: () => {
        return {
            dataFetched: false,
            timeSheets: [],
            startTime: '',
            endTime: '',
            dateFirstTimeSheet: null,
            selectedWeek: null
        }
    },

    computed: {
        currentWeekNumber: function () {
            return this.$moment().format('W');
        },
        firstWeekNumber: function () {
            return this.$moment(this.dateFirstTimeSheet).format('W');
        }
    },

    methods: {
        async saveTimeSheet(timeSheet) {
            await timeSheetRepository.fill(timeSheet);
            await this.getTimeSheets(this.selectedWeek);
        },

        async changeStatus(data) {
            await timeSheetRepository.changeStatus(data);
            this.getTimeSheets(this.selectedWeek)
        },

        async getTimeSheets(week) {
            this.selectedWeek = week;
            const dateRange = {
                minDate: week.firstDay,
                maxDate: week.lastDay,
            }
            this.timeSheets = await timeSheetRepository.getByDateRange(dateRange);
        },

        timeRangeCurrentWeek() {
            const now = this.$moment();
            return {
                firstDay: now.clone().startOf('week').format('YYYY-MM-DD'),
                lastDay: now.clone().endOf('week').format('YYYY-MM-DD'),
                weekNumber: now.clone().format('W'),
            }
        }
    },

    async created() {
        //get 1st timesheet ever, for the user. So we know how much weeks back to build dropdown
        this.dateFirstTimeSheet = await timeSheetRepository.getDateFirstTimeSheet();

        //get time-sheets of current week
        await this.getTimeSheets(this.timeRangeCurrentWeek());
        this.dataFetched = true;
    }
}
