<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>


export default {
  components: {

  },
  data() {
    return {}
  }
}
</script>

<style scoped>
</style>
