import Vue from 'vue'

const getDefaultState = () => {
    return {
        userInfo: '',
        jobGroup: '',
        jobs: [],
        educations: {},
        certificates: {},
        extraEducations: {},
        extraCertificates: {},
        specialisations: {},
    }
}

const state = getDefaultState();

const mutations = {
    /*GENERAL*/
    RESET_STATE_ONBOARDING_EXPERIENCE(state) {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(state, getDefaultState())
    },

    SET_INTAKE_USER_INFO(state, userInfo) {
        state.userInfo = userInfo;
    },

    SET_JOB_GROUP(state, jobGroup) {
        state.jobGroup = jobGroup;
    },


    /*EXPERIENCE*/
    SET_JOBS(state, selectedJobArray) {

        let jobgroupExists = false

        state.jobs.forEach(jobGroup => {
            if (jobGroup.jobGroupId === state.jobGroup) {
                jobgroupExists = true
            }
        })

        //if job group doesnt exist
        if (!jobgroupExists) {
            let newJobGroup = {jobGroupId: state.jobGroup, jobs: []}
            state.jobs.push(newJobGroup)
        }

        //if job group already exists
        if (jobgroupExists) {
            createJobsObject(state, selectedJobArray);
        }
    },


    SET_EDUCATIONS(state, educations) {
        Vue.set(state.educations, `jobGroup${state.jobGroup}`, educations);
    },

    SET_CERTIFICATES(state, certificates) {
        Vue.set(state.certificates, `jobGroup${state.jobGroup}`, certificates);
    },

    SET_EXTRA_EDUCATIONS(state, extraEducations) {
        Vue.set(state.extraEducations, `jobGroup${state.jobGroup}`, extraEducations);
    },

    SET_EXTRA_CERTIFICATES(state, extraCertificates) {
        Vue.set(state.extraCertificates, `jobGroup${state.jobGroup}`, extraCertificates);
    },

    SET_SPECIALISATION(state, specialisation) {
        if (!state.specialisations[`jobGroup${state.jobGroup}`]) {
            Vue.set(state.specialisations, `jobGroup${state.jobGroup}`, []);
        }

        state.specialisations[`jobGroup${state.jobGroup}`].push(specialisation);
    },

    SET_WORK_EXPERIENCE(state, workExperienceData) {
        let jobs = getJobsOfCurrentJobgroup(state);
        const jobId = workExperienceData.jobId;
        const index = findJobIndex(jobs, jobId);
        jobs[index].workexperience.push(workExperienceData.workexperience);
    },

    DELETE_SPECIALISATION(state, index) {
        state.specialisations[`jobGroup${state.jobGroup}`].splice(index, 1);
    },


    DELETE_WORK_EXPERIENCE(state, workExperienceData) {
        let jobs = getJobsOfCurrentJobgroup(state);
        const jobId = workExperienceData.jobId;
        const index = findJobIndex(jobs, jobId);
        jobs[index].workexperience.splice(workExperienceData.index, 1)
    },

}

const actions = {
    /*GENERAL*/
    reset_state_onboarding_experience: ({commit}) => {
        commit('RESET_STATE_ONBOARDING_EXPERIENCE')
    },

    set_intake_user_info: ({commit}, userInfo) => {
        commit('SET_INTAKE_USER_INFO', userInfo)
    },

    set_job_group: ({commit}, jobGroup) => {
        commit('SET_JOB_GROUP', jobGroup)
    },


    /*EXPERIENCE*/
    set_jobs: ({commit}, jobs) => {
        commit('SET_JOBS', jobs)
    },

    set_educations: ({commit}, educations) => {
        commit('SET_EDUCATIONS', educations)
    },

    set_certificates: ({commit}, certificates) => {
        commit('SET_CERTIFICATES', certificates)
    },

    set_extra_educations: ({commit}, extraEducations) => {
        commit('SET_EXTRA_EDUCATIONS', extraEducations)
    },

    set_extra_certificates: ({commit}, extraCertificates) => {
        commit('SET_EXTRA_CERTIFICATES', extraCertificates)
    },

    set_specialisation: ({commit}, specialisation) => {
        commit('SET_SPECIALISATION', specialisation)
    },

    set_work_experience: ({commit}, workExperienceData) => {
        commit('SET_WORK_EXPERIENCE', workExperienceData)
    },

    delete_specialisation: ({commit}, index) => {
        commit('DELETE_SPECIALISATION', index)
    },

    delete_work_experience: ({commit}, workExperienceData) => {
        commit('DELETE_WORK_EXPERIENCE', workExperienceData)
    },

}


const getters = {

    /*GENERAL*/
    get_intake_user_info: (state) => {
        return state.userInfo;
    },

    get_job_group: (state) => {
        return state.jobGroup;
    },


    /*EXPERIENCE*/
    get_jobs: (state) => {
        let jobs = getJobsOfCurrentJobgroup(state);
        if (jobs.length) {
            return createSelectedJobsArray(jobs);
        }
        return [];
    },

    get_jobs_list: (state) => {
        let jobs = getJobsOfCurrentJobgroup(state);
        if (jobs.length) {
            return jobs;
        }
        return [];
    },

    get_work_experience: (state) => (jobId) => {
        let jobs = getJobsOfCurrentJobgroup(state);
        const index = findJobIndex(jobs, jobId)
        if (index >= 0) {
            return jobs[index].workexperience;
        }

        return [];
    },

    get_educations: (state) => {
        if (state.educations[`jobGroup${state.jobGroup}`]) {
            return state.educations[`jobGroup${state.jobGroup}`];
        }
        return [];
    },

    get_certificates: (state) => {
        if (state.certificates[`jobGroup${state.jobGroup}`]) {
            return state.certificates[`jobGroup${state.jobGroup}`];
        }
        return [];
    },

    get_extra_educations: (state) => {
        if (state.extraEducations[`jobGroup${state.jobGroup}`]) {
            return state.extraEducations[`jobGroup${state.jobGroup}`];
        }
        return '';
    },

    get_extra_certificates: (state) => {
        if (state.extraCertificates[`jobGroup${state.jobGroup}`]) {
            return state.extraCertificates[`jobGroup${state.jobGroup}`];
        }
        return '';
    },


    get_specialisations_list: (state) => {
        if (state.specialisations[`jobGroup${state.jobGroup}`]) {
            return state.specialisations[`jobGroup${state.jobGroup}`];
        }
        return [];
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}


/*METHODS*/
const createJobsObject = (state, selectedJobsArray) => {

    //return current jobgroup
    let jobs = getJobsOfCurrentJobgroup(state);

    //set all to false, the ones in the selectedArray will be set later to true
    jobs.forEach((job) => {
        Vue.set(job, 'checked', false);
    })

    //update jobs state
    selectedJobsArray.forEach((jobId) => {
        //check if job already exists in array
        let exist = jobs.some((element) => {
            return element.id === jobId
        })

        //if doesnt exists yet add to array
        if (!exist) {
            jobs.push({id: jobId, checked: true, workexperience: []})
        }

        //if exist set checked value ot true
        if (exist) {
            const index = findJobIndex(jobs, jobId)
            Vue.set(jobs[index], 'checked', true);
        }
    })
};


const createSelectedJobsArray = (jobs) => {
    const selectedJobsArray = [];
    jobs.forEach((job) => {
        if (job.checked) {
            selectedJobsArray.push(job.id)
        }
    })
    return selectedJobsArray;
};


const findJobIndex = (jobs, jobId) => {
    const index = jobs.findIndex((job) => {
        return job.id === jobId;
    })
    return index;
}


const findJobGroupIndex = (jobGroups, jobGroupId) => {
    const index = jobGroups.findIndex((jobGroup) => {
        return jobGroup.jobGroupId === jobGroupId;
    })
    return index;
}


const getJobsOfCurrentJobgroup = (state) => {
    let jobgroup = state.jobs.map((jobGroup) => {
        if (jobGroup.jobGroupId === state.jobGroup) {
            return jobGroup;
        }
    })

    const jobGroupIndex = findJobGroupIndex(state.jobs, state.jobGroup);
    if (jobGroupIndex >= 0) {
        return jobgroup[jobGroupIndex].jobs;
    }

    return [];
}




