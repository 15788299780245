<template>
  <div class="form-group">
    <template v-if="label">
      <label :for="id">{{ label }} <span v-if="required">*</span></label>
    </template>

    <ValidationProvider rules="required|email" v-slot="{ errors, classes }">
      <input class="form-field" type="email"
             :id="id"
             :class="classes"
             :value="value"
             :placeholder="placeholder"
             :autocomplete="autocomplete"
             @input="$emit('input', $event.target.value)"
             @paste="(!allowPaste) ? $event.preventDefault() : true"
      />
      <div class="validate-error">{{ errors[0] }}</div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },

    label: {
      required: false,
      type: String,
    },

    placeholder: {
      required: false,
      type: String,
    },

    required: {
      required: false,
      type: Boolean,
    },

    value: {
      required: false,
      type: String,
    },

    autocomplete: {
      type: String,
      default: 'on',
    },

    allowPaste: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
}
</script>
