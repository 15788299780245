<template>
  <div>
    <div class="bg-black bg-noise-top text-white py-4">
      <div class="flex flex-col items-center text-center p-4">
        <a class="no-underline mt-2 mb-4" href="/">
          <LogoBlackSvg/>
        </a>

        <h1 v-if="userGroup" v-html="$t('onboarding.personal.header.title', {
                userGroup: '<span class=\'text-orange\'>' + userGroup + '</span>'
            })"></h1>

        <p class="lead">{{ $t('onboarding.personal.header.subtitle') }}</p>
      </div>
    </div>
    <div class="black-bar mb-4"></div>

    <div class="container mx-auto p-4">
      <div class="flex flex-col">
        <BackToOnboarding/>
        <ValidationObserver ref="form" class="mb-4">

          <div v-if="isSelfEmployed()" class="mb-3">
            <h3>ZZP gegevens</h3>
            <InputText id="kvk" :placeholder="$t('general.coc_number')" rules="kvk" :required="true" v-model="kvk"/>
            <InputText id="btw" :placeholder="`btw-nummer`" :required="true" v-model="btw"/>
            <InputCheckbox id="liabilityInsurance" v-model="hasLiabilityInsurance">
              <div class="text-gray text-sm">
                vink dit aan als je verzekerd bent voor aansprakelijkheid
              </div>
            </InputCheckbox>
          </div>

          <div class="panel">
            <h3>{{ $t('onboarding.personal.titles.personal_data') }}</h3>
            <InputText id="firstname" :placeholder="$t('general.firstname')" :required="true" v-model="firstname"/>
            <InputText id="lastname" :placeholder="$t('general.lastname')" :required="true" v-model="lastname"/>
            <date-picker class="form-group" v-model="dateOfBirth"
                         format="DD-MM-YYYY"
                         :placeholder="$t('general.birth_date')"
                         :input-class="'form-field' + (showDateOfBirthError ? ' mb-1' : '')"
                         @clear="dateOfBirth = ''"
                         @blur="dateOfBirthHasFocussed = true"
            ></date-picker>
            <div v-if="showDateOfBirthError" class="validate-error -mt-1">{{ $t('validation.required') }}</div>

            <InputText id="phone" :placeholder="$t('general.phone')" :required="true" v-model="phone"/>
            <InputText id="recruiterCode" :placeholder="$t('general.recruiter_code')" :required="false" v-model="recruiterCode"/>
          </div>

          <div class="panel">
            <h3>{{ $t('onboarding.personal.titles.inlog_data') }}</h3>
            <InputEmail id="email" :placeholder="$t('general.email')" autocomplete="off" :required="true" v-model="email"/>
            <InputEmail id="email" :allowPaste="false" :placeholder="$t('general.email_valid')" autocomplete="off" :required="true" v-model="emailToValidate"/>
            <InputPassword id="password" :placeholder="$t('general.password')" autocomplete="off" :required="true" v-model="password"/>

            <InputCheckbox id="privacyCheck" v-model="privacyChecked">
              <div class="text-gray text-sm">
                {{ $t('onboarding.personal.requirements.privacy_check') }}
                <a :href="termsAnConditionsUrl" class="text-gray-darker" target="_blank">{{ $t('general.terms_and_conditions') }}</a>
                {{ $t('general.and') }}
                <a :href="privacyStatementUrl" class="text-gray-darker" target="_blank">{{ $t('general.privacy_statement') }}</a>
              </div>
            </InputCheckbox>
            <InputCheckbox id="privacyCheck" v-model="hasCitizenServiceNumber">
              <div class="text-gray text-sm">
                {{ $t('onboarding.personal.requirements.citizen_service_number') }}<a target="_blank" href="https://www.government.nl/topics/personal-data/citizen-service-number-bsn">{{ $t('general.citizen_service_number') }}</a>
              </div>
            </InputCheckbox>
          </div>
        </ValidationObserver>

        <div class="alert" v-if="dateOfBirth !== '' && !isOfAge">{{ $t('onboarding.personal.requirements_feedback.min_age', {ageLimit: ageLimit}) }}</div>
        <div class="alert" v-if="!privacyChecked">{{ $t('onboarding.personal.requirements_feedback.privacy_check') }}</div>
        <div class="alert" v-if="!emailValidated">{{ $t('onboarding.personal.requirements_feedback.email_validate') }}</div>
        <div class="alert" v-if="!hasCitizenServiceNumber">{{ $t('onboarding.personal.requirements_feedback.citizen_service_number') }}</div>
        <div class="alert" v-if="loginFeedback">{{loginFeedback}} <router-link v-if="loginFeedbackLogin" class="text-black" :to="{ name: 'login' }">Login</router-link></div>

        <NavNext nextRouteName="onboarding_experience_job_group_options" :buttonText="$t('general.next')" @submit="validateForm" ref="NavNext"/>
        <NavPrevious prevRouteName="onboarding_options"/>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import BackToOnboarding from "@/components/onboarding/BackToOnboarding";


export default {
  components: {
    DatePicker,
    BackToOnboarding
  },

  data: () => ({
    firstname: '',
    lastname: '',
    dateOfBirth: '',
    phone: '',
    phoneCountry: '',
    kvk: '',
    btw:'',
    hasLiabilityInsurance: false,
    recruiterCode: '',
    email: '',
    emailToValidate: '',
    password: '',
    ageLimit: null,
    isOfAge: false,
    privacyChecked: false,
    hasCitizenServiceNumber: false,
    loginFeedback: null,
    loginFeedbackLogin: false,
    dateOfBirthHasFocussed: false,
    showDateOfBirth: false,
    termsAnConditionsUrl: process.env.VUE_APP_PUBLIC_BACKEND_URL + 'data/gebruikersvoorwaarden-my-shifts.pdf',
    privacyStatementUrl: process.env.VUE_APP_PUBLIC_BACKEND_URL + 'data/privacyverklaring-my-shifts.pdf'
  }),


  computed: {
    showDateOfBirthError: function () {
      return !this.dateOfBirth && this.dateOfBirthHasFocussed;
    },

    emailValidated: function () {
      return this.email === this.emailToValidate;
    },

    userGroup: function () {
      if (this.isSelfEmployed()) {
        return this.$t('general.self_employed');
      }
      return this.$t('general.temporary_worker');
    },
  },


  watch: {
    dateOfBirth: function () {
      this.isOfAge = this.$helpers.isOfAge(this.dateOfBirth, this.ageLimit);
    }
  },

  methods: {
    validateForm() {
      this.$refs.form.validate().then((validated) => {
        if (validated && this.privacyChecked && this.isOfAge && this.hasCitizenServiceNumber && this.emailValidated) {
          const data = this.buildUserData();
          this.$store.dispatch('general/startloading');
          this.$store.dispatch('user/user_create_action', data).then((response) => {
            this.$store.dispatch('onboardingExperience/set_intake_user_info', response.data.user_info)
            this.handleResponse(response.data)
          });
        }
      });
    },

    buildUserData() {
      return {
        firstname: this.firstname,
        lastname: this.lastname,
        kvk: this.kvk,
        btw: this.btw,
        hasLiabilityInsurance: this.hasLiabilityInsurance,
        dateOfBirth: this.dateOfBirth,
        phone: this.phoneCountry + ' ' + this.phone,
        recruiterCode: this.recruiterCode,
        email: this.email,
        password: this.password,
        usergroups: this.$store.getters['onboardingGeneral/get_intake_user_group'],
      }
    },

    handleResponse(response) {
      if (response.message === 'username_taken') {
        this.loginFeedback = this.$t('onboarding.personal.feedback.username_taken') + " " + this.$t('onboarding.personal.feedback.username_taken_pending');
        this.loginFeedbackLogin = true;
      }

      if (response.message === 'recruiter_code_invalid') {
        this.loginFeedback = this.$t('onboarding.personal.feedback.recruiter_code_invalid');
      }

      if (response.message === 'user_created') {
        this.loginFeedback = this.$t('onboarding.personal.feedback.user_created');
        this.$store.dispatch('onboardingGeneral/set_onboarding_hash', response.user_info.onboardingHash)
        this.$refs.NavNext.navigate();
      }

      if (response.message === 'user_not_created') {
        this.loginFeedback = this.$t('onboarding.personal.feedback.user_not_created');
      }

      this.$store.dispatch('general/endloading');
    },

    isSelfEmployed() {
      return this.$store.getters['onboardingGeneral/get_intake_user_group'] === this.$constants.USERGROUP_SELF_EMPLOYED;
    },

    setAgeLimit() {
      if (!this.isSelfEmployed()) {
        this.ageLimit = this.$constants.MIN_AGE_TEMPORARY_EMPLOYED;
      }

      if (this.isSelfEmployed()) {
        this.ageLimit = this.$constants.MIN_AGE_SELF_EMPLOYED;
      }
    },

    handleUserTypeByParam() {
      const userGroup = this.$helpers.getUrlParam('userGroup');
      if (userGroup !== null) {
        this.setUserType(userGroup)
      }
    },

    setUserType(userGroup) {
      switch (userGroup) {
        case 'self_employed':
          this.$store.dispatch('onboardingGeneral/set_intake_user_group', this.$constants.USERGROUP_SELF_EMPLOYED)
          break
        case 'temporary_employed':
          this.$store.dispatch('onboardingGeneral/set_intake_user_group', this.$constants.USERGROUP_TEMPORARY_EMPLOYED)
          break
        default:
          break;
      }
    },

    autofillRecruiterCode() {
      const recruiterCode = this.$store.getters['onboardingGeneral/get_recruiter_code'];
      if (recruiterCode) {
        this.recruiterCode = recruiterCode;
      }
    },

    checkUserGroupSet(){
      const isSetUserGroup = Number.isInteger(this.$store.getters['onboardingGeneral/get_intake_user_group'])

      if(!isSetUserGroup){
        this.$router.push({name: 'onboarding_options'});
      }
    }

  },

  created() {
    this.checkUserGroupSet();
    this.setAgeLimit();
    this.handleUserTypeByParam();
    this.autofillRecruiterCode();
  }
};
</script>
