<template>
  <div v-if="dataFetched">
    <div class="bg-black bg-noise-top text-white py-4 pb-36">
      <div class="flex flex-col items-center text-center p-4">
        <a class="no-underline mt-2 mb-4" href="/">
          <LogoBlackSvg/>
        </a>
        <h1>{{ $t('onboarding.profile.header.title') }}</h1>
        <p class="lead">{{ $t('onboarding.profile.header.subtitle') }}</p>
      </div>
    </div>
    <div class="black-bar mb-4"></div>

    <div class="container mx-auto p-4 -mt-40 relative z-10">
      <h4 class="mb-3 text-white text-center font-bold">{{ $t('onboarding.profile.profile_photo') }}</h4>

      <div class="flex flex-col items-center">
        <ImageUpload class="rounded-full h-40 w-40"
                     @fileChange="updateSelectedPhoto"
                     :initial_image="photoUrl"
                     :has-button="false"
                     :has-subtext="false"
        />
      </div>

      <ValidationObserver ref="form">
        <div class="panel">
          <h3>{{ $t('onboarding.profile.titles.description') }}</h3>
          <InputTextarea id="description" :required="false" v-model="description" :placeholder="$t('onboarding.profile.placeholders.description')"/>
        </div>

        <div class="panel">
          <h3>{{ $t('onboarding.profile.titles.address') }}</h3>
          <AddressAutoComplete
              @successAddress="successAddress"
              @errorAddress="errorAddress"
          />


          <InputText id="street" :placeholder="$t('general.street')" :required="true" v-model="street" :disabled="true"/>
          <InputText id="city" :placeholder="$t('general.city')" :required="true" v-model="city" :disabled="true"/>
          <InputText id="province" :placeholder="$t('general.province')" :required="true" v-model="province" :disabled="true"/>
        </div>

        <div class="panel">
          <h3>{{ $t('onboarding.profile.titles.drivers_licence') }}</h3>
          <InputRadioGroup id="driversLicence" :required="true" :options="[{title: $t('general.yes'), value: true},{title: $t('general.no'), value: false}]" @newValue="driversLicence = $event"/>
        </div>

        <div class="panel">
          <h3>{{ $t('onboarding.profile.titles.languages') }}</h3>
          <div v-for="(language, index) in languages" :key="index">
            <CheckboxGroup :id="language.id" :label="language.title" :value="language.id" :checked="selectedLanguages" @emitCheckedId="_self.$helpers.toggleArrayItem(selectedLanguages, $event)"/>
          </div>
        </div>
      </ValidationObserver>

      <p v-if="loginFeedback">
        {{ loginFeedback }}
      </p>

      <div class="alert" v-if="!languageChosen">{{ $t('onboarding.profile.alert.language') }}</div>
      <button class="button button--primary" @click="validateForm">{{ $t('general.send') }}</button>
    </div>

    <NavPrevious prevRouteName="onboarding_experience_jobs"/>
  </div>
</template>

<script>
import ImageUpload from "@/components/image/ImageUpload";
import AddressAutoComplete from "@/components/input_fields/groups/AddressAutoComplete";

export default {
  components: {
    ImageUpload,
    AddressAutoComplete
  },

  data: () => ({
    dataFetched: false,
    photoUrl: null,
    loginFeedback: null,
    addressCoordinates: {},
  }),

  methods: {
    validateForm() {
      this.$refs.form.validate().then(async (validated) => {
        if (validated && this.languageChosen) {

          let isConfirmed = await this.alertProfileGoingToBeSent();
          if (isConfirmed) {
            let data = this.buildIntakeData();
            this.$store.dispatch('general/startloading');
            this.$store.dispatch('user/user_fill_profile_action', data).then((response) => {
              this.handleResponse(response.data)
            });
          }

        }
      });
    },

    buildIntakeData() {
      return {
        ...this.$store.getters['onboardingProfile/get_full_profile'],
        onboardingHash: this.$store.getters['onboardingGeneral/get_onboarding_hash'],
        userId: this.$store.getters['onboardingExperience/get_intake_user_info'].id,
        jobGroup: this.$store.getters['onboardingExperience/get_job_group'],
        jobs: this.$store.getters['onboardingExperience/get_jobs_list'],
        educations: this.$store.getters['onboardingExperience/get_educations'],
        certificates: this.$store.getters['onboardingExperience/get_certificates'],
        extraEducations: this.$store.getters['onboardingExperience/get_extra_educations'],
        extraCertificates: this.$store.getters['onboardingExperience/get_extra_certificates'],
        specialisations: this.$store.getters['onboardingExperience/get_specialisations_list'],
        addressCoordinates: this.addressCoordinates,
      }
    },

    successAddress(data) {
      this.street = data.street;
      this.houseNumber = data.houseNumber;
      this.city = data.city;
      this.zipCode = data.zipCode;
      this.province = data.province;
      this.addressCoordinates = data.addressCoordinates;
    },

    errorAddress() {
      this.street = '';
      this.city = '';
      this.province = '';
    },

    buildProfilePhotoData() {
      return {
        onboardingHash: this.$store.getters['onboardingGeneral/get_onboarding_hash'],
        userId: this.$store.getters['onboardingExperience/get_intake_user_info'].id,
      }
    },

    updateSelectedPhoto(photo, photoName,) {
      let formData = new FormData();
      formData.append('photo', photo, photoName);
      formData.append('data', JSON.stringify(this.buildProfilePhotoData()));
      this.$store.dispatch('user/user_profile_photo_action', formData).then((response) => {
        this.$store.dispatch('onboardingProfile/set_profile_photo', response.data.photoUrl)
        this.photoUrl = response.data.photoUrl;
      });
    },

    setPhotoFromStore() {
      this.photoUrl = this.$store.getters['onboardingProfile/get_profile_photo'];
    },



    handleResponse(response) {
      if (response.message === 'user_updated') {
        this.loginFeedback = this.$t('onboarding.profile.feedback.user_updated');
        this.clearOnboardingMemory();
        this.$router.push({
          name: 'onboarding_appointment'
        });
      }

      if (response.message === 'user_not_updated') {
        this.loginFeedback = this.$t('onboarding.profile.feedback.user_not_updated');
      }
      this.$store.dispatch('general/endloading');
    },

    alertProfileGoingToBeSent() {
      return this.$swal({
        title: this.$t('onboarding.profile.alert.title'),
        text: this.$t('onboarding.profile.alert.text'),
        confirmButtonText: this.$t('general.send_now'),
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#FF5F00',
      }).then((result) => {
        return result.isConfirmed;
      })
    },

    clearOnboardingMemory() {
      const jobGroup = this.$store.getters['onboardingExperience/get_job_group'];
      const userGroup = this.$store.getters['onboardingGeneral/get_intake_user_group'];

      const province = this.province;
      this.$store.dispatch('onboardingExperience/reset_state_onboarding_experience');
      this.$store.dispatch('onboardingGeneral/reset_state_onboarding_general');
      this.$store.dispatch('onboardingProfile/reset_state_onboarding_profile');
      this.$store.dispatch('onboardingGeneral/set_calendly_info', {province: province, jobGroup: jobGroup});
      this.$store.dispatch('onboardingGeneral/set_intake_user_group', {userGroup: userGroup});
    }
  },

  async created() {
    this.$store.dispatch('general/startloading');
    this.setPhotoFromStore();
    await this.$store.dispatch('language/fetch_all_languages_action');
    this.dataFetched = true;
    this.$store.dispatch('general/endloading');
  },

  computed: {
    description: {
      set(description) {
        this.$store.dispatch('onboardingProfile/set_profile_description', description)
      },
      get() {
        return this.$store.getters['onboardingProfile/get_profile_description'];
      }
    },

    street: {
      set(street) {
        this.$store.dispatch('onboardingProfile/set_profile_street', street)
      },
      get() {
        return this.$store.getters['onboardingProfile/get_profile_street'];
      }
    },

    houseNumber: {
      set(houseNumber) {
        this.$store.dispatch('onboardingProfile/set_profile_houseNumber', houseNumber)
      },
      get() {
        return this.$store.getters['onboardingProfile/get_profile_houseNumber'];
      }
    },

    city: {
      set(city) {
        this.$store.dispatch('onboardingProfile/set_profile_city', city)
      },
      get() {
        return this.$store.getters['onboardingProfile/get_profile_city'];
      }
    },

    zipCode: {
      set(zipCode) {
        this.$store.dispatch('onboardingProfile/set_profile_zipCode', zipCode)
      },
      get() {
        return this.$store.getters['onboardingProfile/get_profile_zipCode'];
      }
    },

    province: {
      set(province) {
        this.$store.dispatch('onboardingProfile/set_profile_province', province)
      },
      get() {
        return this.$store.getters['onboardingProfile/get_profile_province'];
      }
    },

    selectedLanguages: {
      set(selectedLanguages) {
        this.$store.dispatch('onboardingProfile/set_profile_selectedLanguages', selectedLanguages)
      },
      get() {
        return this.$store.getters['onboardingProfile/get_profile_selectedLanguages'];
      }
    },

    otherLanguages: {
      set(otherLanguages) {
        this.$store.dispatch('onboardingProfile/set_profile_otherLanguages', otherLanguages)
      },
      get() {
        return this.$store.getters['onboardingProfile/get_profile_otherLanguages'];
      }
    },

    driversLicence: {
      set(driversLicence) {
        this.$store.dispatch('onboardingProfile/set_profile_driversLicence', driversLicence)
      },
      get() {
        return this.$store.getters['onboardingProfile/get_profile_driversLicence'];
      }
    },

    languageChosen: function () {
      return this.selectedLanguages.length > 0;
    },

    languages: function () {
      return this.$store.getters['language/get_language_list'];
    },
  },

}
</script>
