<template>
<div>
    <div class="flex justify-between items-center border border-gray-light bg-gray-lighter px-4 py-3 mb-2"
        v-for="(specialisation, index) in specialisations"
        :key="index"
    >
        <span class="text-sm">{{specialisation}}</span>
        <!-- @todo Add confirmation -->
        <button @click="deleteSpecialisation(index)">
            <i class="icon-cross" />
        </button>
    </div>
</div>
</template>

<script>
export default {
    props: {
        specialisations: {
            required: true,
            type: Array,
        },
    },

    methods: {
        deleteSpecialisation(index) {
            this.$store.dispatch('onboardingExperience/delete_specialisation', index);
        },
    },

}
</script>

