import constants from "@/settings/constants";

import DashboardEmployee from "@/views/DashboardEmployee";
import ShiftDetailEmployee from "@/views/ShiftDetailEmployee";
import ShiftDetailEmployeeArchive from "@/views/ShiftDetailEmployeeArchive";
import ShiftOverviewEmployee from "@/views/ShiftOverviewEmployee";
import ShiftOverviewEmployeeArchive from "@/views/ShiftOverviewEmployeeArchive";
import TimeSheetsEmployee from "@/views/TimeSheetsEmployee";
import Costumes from "@/views/Costumes";


const SELF_EMPLOYED = constants.USERGROUP_SELF_EMPLOYED;
const TEMPORARY_EMPLOYED = constants.USERGROUP_TEMPORARY_EMPLOYED;

export default [
    {
        path: "/employee/dashboard",
        name: "dashboard_employee",
        component: DashboardEmployee,
        meta: {
            requiresAuth: true,
            hasPermission: [TEMPORARY_EMPLOYED, SELF_EMPLOYED],
            layout: 'navigation'
        }
    },


    {
        path: "/employee/shifts/overview",
        name: "shifts_employee_overview",
        component: ShiftOverviewEmployee,
        meta: {
            requiresAuth: true,
            hasPermission: [TEMPORARY_EMPLOYED, SELF_EMPLOYED],
            layout: 'navigation'
        }
    },

    {
        path: "/employee/shifts/overview/archive",
        name: "shifts_employee_overview_archive",
        component: ShiftOverviewEmployeeArchive,
        meta: {
            requiresAuth: true,
            hasPermission: [TEMPORARY_EMPLOYED, SELF_EMPLOYED],
            layout: 'navigation'
        }
    },

    {
        path: "/employee/shifts/detail/:id",
        name: "shifts_employee_detail",
        component: ShiftDetailEmployee,
        meta: {
            requiresAuth: true,
            hasPermission: [TEMPORARY_EMPLOYED, SELF_EMPLOYED],
            layout: 'navigation-back'
        }
    },


    {
        path: "/employee/shifts/detail/archive/:id",
        name: "shifts_employee_detail_archive",
        component: ShiftDetailEmployeeArchive,
        meta: {
            requiresAuth: true,
            hasPermission: [TEMPORARY_EMPLOYED, SELF_EMPLOYED],
            layout: 'navigation-back'
        }
    },




    {
        path: "/employee/time-sheets",
        name: "time_sheets_employee",
        component: TimeSheetsEmployee,
        meta: {
            requiresAuth: true,
            hasPermission: [TEMPORARY_EMPLOYED, SELF_EMPLOYED],
            layout: 'navigation',
        }
    },

    {
        path: "/costumes",
        name: "costumes",
        component: Costumes,
        meta: {
            requiresAuth: true,
            hasPermission: [TEMPORARY_EMPLOYED, SELF_EMPLOYED],
            layout: 'navigation'
        }
    },



]
