const moment = require('moment')

const setAxiosFileConfig = () => {
    return {
        headers: {'Content-Type': 'multipart/form-data'},
        transformRequest: []
    }
}

const isOfAge = (dateOfBirth, ageRequirement) => {
    let ageInYears = moment().diff(dateOfBirth, 'years');
    return ageInYears  >= ageRequirement;
}


const scrollToTop = () => {
    window.scrollTo(0, 0);
}

const getUrlParam = ($paramater) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get($paramater);
}


const currentDateInIso = () => {
    var dateobj = new Date();
    return dateobj.toISOString();
}

const toNiceDate = (date) => {
    var momentDate = moment(date);
    var niceDate = moment(momentDate).format('DD-MM-YYYY');
    return niceDate;
}

const toNiceTime = (date) => {
    moment.locale('nl');
    var momentDate = moment(date);
    var niceTime = moment(momentDate).format('LT')
    return niceTime;
}

const dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type: mime});
}

const checkIfValueInArray = (array, value) => {
    return array.indexOf(value) !== -1
}

// if doesnt exist add to array, else remove from array
const toggleArrayItem = (array, id) => {
    if (array.indexOf(id) !== -1) {
        array.splice(array.indexOf(id), 1);
    } else {
        array.push(id);
    }
}


export default {
    setAxiosFileConfig,
    scrollToTop,
    currentDateInIso,
    toNiceDate,
    toNiceTime,
    dataURLtoBlob,
    isOfAge,
    getUrlParam,
    checkIfValueInArray,
    toggleArrayItem,
};
