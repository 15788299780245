<template>
  <router-link :to="{name: routeName}" tag="button" :class="['nav-item', { 'active': routeIsActive }]">
      <i v-if="icon" :class="`icon-${icon}`" />
      {{text}}
  </router-link>
</template>

<script>


export default {
  props: {
    routeName: {
      type: String,
      required: true,
    },
    icon: {
        type: String,
        default: '',
    },
    text: {
      type: String,
      required: true,
    },
  },

  computed:{
    routeIsActive(){
      return this.routeName === this.$route.name;
    }
  }
}
</script>

<style lang="scss" scoped="true">

.nav-item {
  position: relative;
  overflow: hidden;
  height: 100%;
  color: #333;
  font-size: 13px;
  padding: 1em 0;
  @apply flex flex-1 flex-grow flex-col justify-center items-center md:flex-none md:flex-row md:flex-grow-0;
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -4px;
    right: 0;
    height: 4px;
    background-color: #FF5F00;
    transition: bottom 300ms ease;
  }
  i {
    &:before {
      margin-left: 0;
    }
    @screen md {
      margin-right: .3rem;
    }
  }
  &:hover, &:focus {
    background-color: #F7F7F7;
  }
  &.active {
    background-color: #F7F7F7;
    color: #FF5F00;
    &:after {
      bottom: 0;
    }
  }
  @screen md {
    color: #FFF;
    font-size: 15px;
    padding: 0 1.25em;
    &:hover, &:focus, &.active {
      background-color: #111;
      color: #FFF;
      &:after {
        bottom: 0;
      }
    }
  }
}


// .nav-item {
//     @apply mx-4 py-4 md:py-5 text-gray-darker md:text-white h-full md:items-center flex flex-1 flex-grow md:flex-grow-0 justify-center;
//   margin-left: 0.8rem !important;
//   margin-right: 0.8rem !important;
//     font-size: .7rem;
// }

// @screen md {
//     .nav-item {
//         @apply text-sm;
//     }
// }

// .nav-item i {
//     @apply mr-1;
// }

// .nav-item {
//     @apply md:flex-row flex flex-col items-center;
// }

// .nav-item i {
//     @apply mr-0;

//     font-size: 1.333rem;
// }

// @screen md {
//     .nav-item i {
//         @apply text-sm mr-2;
//     }
// }

// .nav-item.active {
//     @apply text-orange relative;
// }

// .nav-item.active::before {
//     @apply absolute block bg-orange md:rounded-t rounded-b-none top-0 md:top-auto md:bottom-0 left-0 right-0;

//     content: ' ';
//     height: 5px;
// }

</style>

