import repository from "@/repositories/repository";

const languageRepository = repository.get("language");


const state = () => ({
    languagesList: []
})

const mutations = {
    FETCH_ALL_LANGUAGES(state, languagesList) {
        state.languagesList = languagesList
    },
}

const actions = {
    fetch_all_languages_action: async ({state, commit}) => {
        if (!state.languagesList.length) {
            const response = await languageRepository.getAll();
            commit('FETCH_ALL_LANGUAGES', response.data);
        }
    },
}


const getters = {
    get_language_list: (state) => {
        return state.languagesList;
    },
};


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
