<template>

  <div class="shift" :class="{ 'shift--responsive': !fixedWidth }">
    <router-link class="shift__header cursor-pointer" :to="{ name: shiftDetailRouteName, params: {id: shift.id }}" tag="div" :style="shift.photos.length ? `background-image: url(${shift.photos[0].imageUrl});` : {'background-image': 'url(' + require('@/assets/images/shift_placeholder.jpg') + ')'}">
      <span v-if="shift.registration.status !== 'unsigned'" class="shift__header__status" :class="`shift__header__status--${shift.registration.status}`">
        <i v-if="shift.registration.status === 'accepted'" class="icon-checkmark_alt"/>
        <i v-if="shift.registration.status === 'pending'" class="icon-clock"/>
        <i v-if="shiftRedStatuses" class="icon-cross"/>
      </span>
      <span class="shift__header__location">
        <i class="icon-pin"/> {{ shift.shiftLocation.city }}
      </span>


      <span class="shift__header__details">
        <span class="shift__header__rate bg-noise-bottom">
          <span class="shift__header__rate__symbol">&euro;</span>
          <span class="shift__header__rate__major">{{ (shift.averageSalary + "").split(".")[0] }}</span>
          <span class="shift__header__rate__minor">{{
              padNumbers(shift.averageSalary + "").split(".")[1] ? padNumbers(shift.averageSalary + "").split(".")[1] : '00'
            }}
          </span>
          <span class="shift__header__rate__period">/uur</span>
        </span>
        <span class="shift__header__hours bg-noise-top">
          <span class="shift__header__hours__amount">{{ shift.durationHoursMinutes.hours }},{{ shift.durationHoursMinutes.minutes }}</span>
          <span class="shift__header__hours__unit">uur</span>
        </span>
      </span>

    </router-link>

    <div class="shift__body" style="z-index: 10000">
      <router-link class="cursor-pointer" :to="{ name: shiftDetailRouteName, params: {id: shift.id }}" tag="h2">{{ shift.jobTitle }}</router-link>
      <p class="shift__body__time">{{ shift.startDateTime | moment("dddd") }} {{ shift.startDateTime | moment("D MMMM") }},
        {{ shift.startDateTime | moment("HH:mm") }} - {{ shift.endDateTime | moment("HH:mm") }}
        ({{ shiftDuration }} uur)
      </p>
      <p class="shift__body__location">
        <i class="icon-pin"/>
        {{ shift.employer.companyName }}
      </p>

      <div v-if="acceptedRegistrars && shift.registration.status === 'accepted'" class="shift__body__shiftees">
        <Shiftee v-for="(registrar, index) in acceptedRegistrars" :key="index"
                 :registrar="registrar"
                 :style="`z-index: ${30-index}; left: -${0.75*index}rem;`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Shiftee from '@/components/shifts/Shiftee';
import shiftCalculationsMixin from "@/mixins/shiftCalculationsMixin";

export default {
  mixins: [shiftCalculationsMixin],
  props: {
    shift: {
      type: Object,
      required: true
    },
    fixedWidth: {
      type: Boolean,
      default: true,
    },
    hasPassed: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    Shiftee,
  },

  data: () => ({}),

  computed: {
    acceptedRegistrars() {
      return this.shift.registrars.filter((registrar) => {
        return registrar.status === 'accepted';
      });
    },
    shiftDetailRouteName() {
      if (this.hasPassed) {
        return 'shifts_employee_detail_archive'
      }
      return 'shifts_employee_detail'
    },
    shiftDuration() {
        let duration = this.shift.durationHoursMinutes.hours;

        if (this.shift.durationHoursMinutes.minutes > 0) {
            duration += `:${this.shift.durationHoursMinutes.minutes}`
        }

        return duration;
    }
  },

  methods: {
    padNumbers(value) {
      return String(value).padStart(2, '0');
    }
  }
};
</script>

<style scoped>
.shift {
  @apply rounded;
  width: 294px !important;
}

@screen sm {
  .shift {
    width: 320px !important;
  }
}

.shift--responsive {
  @apply mb-0;

  width: 100% !important;
}

.shift__header {
  @apply w-full bg-cover bg-center bg-gray-lighter rounded-lg relative;

  height: 180px;
}

.shift__header__status {
  @apply absolute top-0 left-0 rounded-full border-2 border-white text-white inline-block h-10 w-10 flex items-center justify-center m-3;
}

.shift__header__status--accepted {
  @apply bg-green text-xl;
}

.shift__header__status--accepted i {
  @apply relative;
  left: -2px;
}

.shift__header__status--pending {
  @apply bg-yellow text-2xl;
}

.shift__header__status--pending i {
  @apply relative;
  top: -1px;
}

.shift__header__status--declined, .shift__header__status--canceled, .shift__header__status--unsubscribed, .shift__header__status--unsubscribedByEmployer, .shift__header__status--canceledByEmployer{
  @apply bg-red text-xl;
}

.shift__header__location {
  @apply absolute top-0 right-0 m-2 py-2 px-3 text-sm leading-tight rounded bg-white border border-gray-lighter;
}

.shift__header__location span {
  @apply text-gray-dark pl-2;
}

.shift__header__details {
  @apply absolute bottom-0 left-0;
}

.shift__header__rate {
  @apply relative inline-block bg-orange p-3 pt-2 pr-4 h-12;

  bottom: -.5rem;
}

.shift__header__rate > * {
  @apply leading-none align-top;
}

.shift__header__rate__symbol {
  @apply font-semibold text-sm;
}

.shift__header__rate__major {
  @apply relative font-semibold px-1 mb-2;

  font-size: 1.6rem;
  top: .05rem;
}

.shift__header__rate__minor {
  @apply font-semibold text-sm pr-1;
}

.shift__header__rate__period {
  @apply absolute bottom-0 right-0 pb-2 pr-3 text-xs;
}

.shift__header__hours {
  @apply relative inline-block bg-black text-white py-2 px-3 pt-2;

  bottom: -.25rem;
}

.shift__header__hours > * {
  @apply leading-none align-top;
}

.shift__header__hours__amount {
  @apply relative font-semibold text-xl px-1;
}

.shift__header__hours__unit {
  @apply text-sm pr-1;
}

.shift__body {
  @apply text-left py-4;
}

.shift__body h3 {
  @apply text-xl mb-2;
}

.shift__body__time {
  @apply text-sm text-orange mb-1;
}

.shift__body__location {
  @apply text-sm text-gray-dark mb-2;
}

.shift__body__shiftees {
  @apply flex my-4 inline-block  overflow-y-hidden;
}

.shift__body__shiftees > * {
  @apply relative border-4 border-white flex-shrink-0;
}

.shift .icon-pin {
  @apply text-xs;
}

</style>
