<template>
  <div class="time_registration mb-6 pt-6 -mx-6 px-6 md:mx-0 md:px-0 border-t border-gray-light">
    <div class="flex justify-between">
      <h3 class="text-lg mb-1 leading-tight">{{ timeSheet.title }}</h3>
      <button class="rounded-full bg-gray-light hover:bg-gray w-5 h-5"
              v-if="timeSheet.employee_status === 'unhandled' || toggledEdit"
              @click="overlay.deleteTimeSheet = true"
      >
        <i class="icon-cross text-2xs text-white leading-none flex items-center justify-center"/>
      </button>
    </div>

    <p class="text-xs text-orange mb-2 leading-tight">{{ timeSheet.shift.start | moment("dddd D MMMM") }}</p>
    <p class="text-xs text-gray-dark mb-3">
      <i class="icon-pin text-xs"/>
      {{ timeSheet.shift.organisation.name }}, {{ timeSheet.shift.address.city }}
    </p>

    <!-- EDIT MODE -->
    <div class="time_registration__form" v-if="timeSheet.employee_status === 'unhandled' || toggledEdit">
      <div class="flex w-full space-x-3">
        <TimeField
            @timeChanged="timeSheet.shift.start = $event"
            :placeholder="'Van'"
            :initial-time="timeSheet.shift.start"
        />

        <TimeField
            @timeChanged="timeSheet.shift.end = $event"
            :placeholder="'Tot'"
            :initial-time="timeSheet.shift.end"
        />
      </div>
      <div class="flex">
        <InputText :id="`${timeSheet.id}-break`"
                   :class="`w-24`"
                   v-model="timeSheet.duration.break.breakInMinutes"
                   placeholder="00"
        />
        <span class="ml-4 mb-4 text-sm self-center w-11/12">Minuten pauze</span>
      </div>
      <div class="flex">
        <button-primary text="Opslaan" @click.native="saveTimeSheet(); (toggledEdit) ? toggledEdit = !toggledEdit : toggledEdit"></button-primary>
      </div>
    </div>

    <!-- CLOSED MODE -->
    <div v-else :class="`time_registration__hours ${types[timeSheet.employer_status].bg} rounded py-3 px-4 flex justify-between items-center`">
      <div>
        <div class="text-xl text-gray-darker leading-none mb-2">{{ timeSheet.duration.hours }} uur<span v-if="timeSheet.duration.minutes !== '00'">, {{ timeSheet.duration.minutes }} minuten</span></div>
        <div class="text-xs text-gray">Dit is excl. {{ timeSheet.duration.break.breakInMinutes }} minuten pauze, voor deze tijd krijg je betaald.</div>
        <div class="text-xs text-gray">{{ timeSheet.shift.distance }} km totale reisafstand</div>
      </div>
      <div>
        <button v-if="timeSheet.employer_status === 'unhandled'" @click="toggledEdit = !toggledEdit" type="button" class="rounded-full bg-white h-10 w-10">
          <i :class="`${types[timeSheet.employer_status].icon}`"/>
        </button>
        <div v-else class="rounded-full bg-white h-10 w-10 flex justify-center items-center	">
          <i :class="`${types[timeSheet.employer_status].icon}`"/>
        </div>
      </div>
    </div>
    <div class="text-gray text-xs mt-3" v-html="types[timeSheet.employer_status].text"></div>


    <!-- overlay: pending  -->
    <div v-if="overlay.deleteTimeSheet">
      <Overlay @closeOverlay="overlay.deleteTimeSheet = false">
        <h3 class="text-lg mb-1">Weet je zeker dat je deze shift niet gewerkt hebt?</h3>

        <div class="mb-6 pb-4 md:pb-6 leading-loose text-sm text-gray border-b border-gray-light">
          Dat betekend dat je geen uren meer kunt boeken voor deze shift en dat je deze uren dus ook niet krijgt uitbetaald.
        </div>

        <button-primary text="Ja, Time-sheet verwijderen" @click.native="$emit('changeStatus', {timeSheet, status: 'not-worked'})" style="width: 100%"></button-primary>
      </Overlay>
    </div>
  </div>
</template>

<script>
import TimeField from "@/components/date-time/TimeField";
import ButtonPrimary from "@/components/buttons/ButtonPrimary";
import Overlay from "@/components/Overlay";
import constants from "@/settings/constants";


export default {
  props: {
    timeSheet: {
      type: Object,
      required: true,
    }
  },

  components: {
    TimeField,
    ButtonPrimary,
    Overlay
  },

  data() {
    return {
      toggledEdit: false,
      initialTimeSheet: {},
      types: {
        unhandled: {
          bg: 'bg-gray-lighter',
          icon: 'icon-edit text-orange',
          text: "Uren zijn <u>aanpasbaar</u> tot dat het bedrijf akkoord geeft"
        },
        approved: {
          bg: 'bg-green bg-opacity-10',
          icon: 'icon-checkmark_alt text-green',
          text: "Uren zijn geaccordeerd door het bedrijf"
        },
        declined: {
          bg: 'bg-yellow bg-opacity-10',
          icon: 'icon-clock text-yellow text-xl',
          text: "Uren worden dubbel gecheckt door My Shifts"
        },
      },

      overlay: {
        deleteTimeSheet: false,
      },
    }
  },

  methods: {
    saveTimeSheet() {
      this.handleCrossMidnight(this.timeSheet);

      if (this.totalLabourTime(this.timeSheet) < constants.MIN_SHIFT_DURATION_HOURS) {
        this.shiftDurationIncorrectAlert();
      } else {
        this.$emit('saveTimeSheet', this.timeSheet)
      }
    },

    totalLabourTime(timeSheet) {
      var timeSheetEndTime = timeSheet.shift.end;
      // eslint-disable-next-line no-unused-vars
      if (this.$moment(timeSheet.shift.end) < this.$moment(timeSheet.shift.start)) {
        timeSheetEndTime = this.$moment(timeSheet.shift.end).add(1, 'days');
      }

      const minutesDiffShiftTime = this.$moment(timeSheetEndTime).diff(timeSheet.shift.start, 'minutes');
      const hoursDiffShiftTime = minutesDiffShiftTime / 60;

      let breakTimeInHours = timeSheet.duration.break.breakInMinutes / 60;
      return hoursDiffShiftTime - breakTimeInHours;
    },

    handleCrossMidnight(newTimeSheet) {
      const initialStart =  this.$moment(this.initialTimeSheet.shift.start).format('H');
      const initialEnd = this.$moment(this.initialTimeSheet.shift.end).format('H');
      const initialIsCrossMidnight = this.isCrossMidnight(initialStart, initialEnd);

      const newStart =  this.$moment(newTimeSheet.shift.start).format('H');
      const newEnd = this.$moment(newTimeSheet.shift.end).format('H');
      const newIsCrossMidnight = this.isCrossMidnight(newStart, newEnd);

      if(initialIsCrossMidnight && !newIsCrossMidnight){
        const newEndTime = this.$moment(newTimeSheet.shift.end).subtract(1, 'days');
        newTimeSheet.shift.end =  newEndTime.format();
      }
      return newTimeSheet;
    },

    isCrossMidnight(start, end) {
      return parseInt(end) < parseInt(start);
    },

    shiftDurationIncorrectAlert() {
      return this.$swal({
        title: 'Shifttijd onjuist',
        text: `Let op! De shift moet minimaal ${constants.MIN_SHIFT_DURATION_HOURS}uur duren.`,
        showCloseButton: true,
        showConfirmButton: false,
        showCancelButton: false,
        focusConfirm: false,
      });
    },
  },


  created() {
    //this has to be done to not just copy the reference to the same object, but create a new object
    this.initialTimeSheet = JSON.parse(JSON.stringify(this.timeSheet));
  }
}
</script>

