<template>
  <div>
    <PageTitle :title="shift.jobTitle">
      <template v-slot:pageTitleLeft>
        <p class="text-sm text-orange mb-2">
            {{ shift.startDateTime | moment("dddd") }} {{ shift.startDateTime | moment("DD MMMM") }} {{ shift.startDateTime | moment("HH:mm") }} - {{ shift.endDateTime | moment("HH:mm") }}
            ({{ shift.durationHoursMinutes.hours }}:{{ shift.durationHoursMinutes.minutes }} uur)
          </p>
          <p class="text-sm text-white mb-2">
            <i class="icon-pin"/>
            <span>Locatie shift: </span>
            {{ shift.shiftLocation.city }}, {{ shift.shiftLocation.street }} {{ shift.shiftLocation.houseNumber }}
          </p>
      </template>
      <template v-slot:pageTitleRight>
        <div class="grid grid-cols-2">
          <div class="col-span-2">
            <a @click="copyShift()" class="button button--gray-outline">Deze shift opnieuw inplannen</a>            
          </div>
          <div class="col-span-2" v-if="timeLeftToStart(shift) !== `in_the_past`">
            <p class="text-white text-xs mt-4">
              <template v-if="!isFilledShift">
                <span class="mr-4">nog {{ timeLeftToStart(shift) }} om een keuze te maken</span>
                <span class="clock-icon rounded-full bg-yellow text-3xl"><i class="icon-clock relative" style="top: -1px;"/></span>
              </template>
              <template v-if="isFilledShift">
                <span class="mr-4">Shift is al gevuld</span>
                <span class="clock-icon rounded-full bg-green text-3xl"><i class="icon-checkmark_alt relative" style="top: -1px;"/></span>
              </template>
            </p>
          </div>
        </div>        
      </template>
    </PageTitle>
  </div>
</template>

<script>
import PageTitle from "@/components/page/PageTitle";
import shiftCalculationsMixin from "@/mixins/shiftCalculationsMixin";

export default {
  mixins: [shiftCalculationsMixin],

  props: {
    shift: {
      type: Object,
      required: true
    },
  },

  components: {
    PageTitle
  },

  methods: {
    copyShift() {
      const shiftId = this.$route.params.id;
      this.$router.push({name: 'shift_create', query: {shiftId: shiftId}})
    },

  }
}
</script>

<style lang="scss" scoped>
.clock-icon {
  width: 42px;
  height: 42px;
  vertical-align: middle;
}
</style>