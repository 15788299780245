import Vue from 'vue';
import * as Sentry from "@sentry/vue";
import store from "@/store/store";
import {router} from "@/router/router"

export default class ErrorService {

    static onError(error) {

        switch (error.response.data.message) {
            case 'message_bird_issue_sms':
                this.displayErrorAlert('Er ging iets mis. Sms kon niet worden verstuurd, je telefoonnummer klopt wellicht niet.', false);
                break;
            case 'no_refresh_token_set':
                this.displayErrorAlert('Je was te lang niet online, je moet opnieuw inloggen.', false);
                break;
            case 'refresh_token_expired':
                this.displayErrorAlert('Je was te lang niet online, je moet opnieuw inloggen.', false);
                break;
            case 'geolocation_issue':
                this.displayErrorAlert('Foutief adres in het systeem. neem contact op met My Shfits', false);
                break;
            case 'geolocation_issue_zero_results':
                this.displayErrorAlert('Foutief adres in het systeem. neem contact op met My Shfits', false);
                break;
            default:
                this.displayErrorAlert("Oeps er ging iets mis, probeer het later opnieuw", true);
        }

        // Send Error to Log Engine e.g LogRocket
        store.dispatch('general/endloading');
        ErrorService.sentryLogEngine(error);
    }


    static displayErrorAlert(text, redirect = null) {

        Vue.$toast(text, {
            toastClassName: 'custom-error',
        });

        if(redirect){
            router.push('/').catch(() => {
            })
        }

    }


    static sentryLogEngine(error) {
        const user = store.getters['user/get_user'];

        if (user){
            Sentry.setUser({email: user.email});
        }

        Sentry.captureException(error);
    }


}
