<template>
  <div class="-mt-6" v-if="dataFetched">
    <ShiftDetailEmployerHeader :shift="shift"></ShiftDetailEmployerHeader>


    <div class="flex flex-col items-center text-center px-6 py-4">
      <div class="w-full mt-4 text-left relative">
        <h2 class="text-2xl font-normal mb-2">{{ shift.amountEmployeesAccepted }} / {{ shift.amountEmployees }} aanmeldingen</h2>
        <p class="text-gray-dark">Shifties die zich hadden aangemeld voor deze shift</p>
        <Slick ref="slick" :options="slickOptions_mixin" class="mb-8 slick-slider--no-margin">
          <template v-for="(registrar,index) in shift.registrars">
            <div :key="index">
              <ShiftRegistrar
                  :registrar="registrar"
                  :shift="shift"
                  :hasPassed="true"
              />
            </div>
          </template>
        </Slick>

        <div class="block">
          <h3>Wat wordt er van je verwacht?</h3>
          <div v-html="shift.descriptionWork"></div>
        </div>

        <div class="block">
          <h3>Vereiste skills</h3>
          <div v-html="shift.requiredSkills"></div>
        </div>

        <div class="block">
          <h3>Vereiste talen</h3>
          <Flag class="mr-2" v-for="(language, index) in shift.requiredLanguages" :code="language.code" :key="index"/>
        </div>

        <div class="block">
          <h3>Waar moet je je melden?</h3>
          <div v-html="shift.placeToReport"></div>
        </div>

        <div class="block">
          <h3>Waar kun je parkeren?</h3>
          <div v-html="shift.parkingInstructions"></div>
        </div>

        <div class="block">
          <h3>Aanvullende eisen en opmerkingen</h3>
          <div v-html="shift.additionalDescription"></div>
        </div>
      </div>
    </div>
  </div>


</template>


<script>
import repository from "@/repositories/repository";
import ShiftRegistrar from '@/components/shifts/ShiftRegistrar';
import Flag from '@/components/Flag';
import Slick from 'vue-slick';
import shiftCalculationsMixin from "@/mixins/shiftCalculationsMixin";
import ShiftDetailEmployerHeader from "@/components/shifts/ShiftDetailEmployerHeader";

const shiftRepository = repository.get("shift");

export default {
  mixins: [shiftCalculationsMixin],
  components: {
    Slick,
    ShiftRegistrar,
    Flag,
    ShiftDetailEmployerHeader
  },


  data: () => ({
    dataFetched: false,
    shift: null,
    shiftCosts: null,
    overlay: {
      accept: false,
      decline: false,
    },
    registrar: null,
    reasonDecline: null,
    reasonsDecline: [
      {id: 'Niet geschikt', title: 'Niet geschikt'},
      {id: 'Slechte ervaring', title: 'Slechte ervaring'},
      {id: 'Profiel incompleet', title: 'Profiel incompleet'},
      {id: 'Dienst vervallen', title: 'Dienst vervallen'},
      {id: 'Niet meer nodig', title: 'Niet meer nodig'},
      {id: 'Overig', title: 'Overig'},
    ],
  }),


  methods: {

    calculateShiftCosts(registrar) {
      this.shiftCosts = (registrar.travelAllowance.total + (this.shift.duration * registrar.labourCost)).toFixed(2);
    },
  },


  async created() {
    this.$store.dispatch('general/startloading');
    this.shift = await shiftRepository.getByIdForEmployer(this.$route.params.id)
    this.dataFetched = true;
    this.$store.dispatch('general/endloading');
  }
}
</script>

<style scoped>
.block {
  @apply mb-8 text-xs md:text-sm leading-loose;
}

.block h3 {
  @apply mb-1 text-sm md:text-base font-semibold;
}
</style>
