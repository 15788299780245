<template>
  <div v-if="dataFetched">
    <PageTitle>
      <template v-slot:pageTitleLeft>
        <WeekPicker
          :min-date="dateFirstTimeSheet"
          @weekChange="getTimeSheets($event)"
          ref="weekPicker"
        />
        <p class="text-gray text-sm mb-0">Openstaande uren accorderen</p>
      </template>
    </PageTitle>

    <!--  JOBGROUP NAVIGATION  -->
    <div class="mx-auto leading-loose text-gray border-b md:border-b-0 border-gray-light px-6 mb-6">
      <div class="md:hidden text-sm pb-4 md:pb-6 mt-4 md:mt-6">
        <InputSelect id="jobGroups"
                     :selected="jobGroupSelected"
                     :options="jobGroups"
                     placeholder="Kies functiegroep"
                     @onChange="onJobGroupChange($event)"/>
      </div>

      <div class="hidden md:block job-group-navigation border-b border-gray-light">
        <button v-for="jobGroup in jobGroups" type="text" :class="{ active: jobGroupSelected === jobGroup.id}" class="text-black mr-8 py-4" @click="jobGroupSelected = jobGroup.id" :key="jobGroup.id">
          {{ jobGroup.title }}
        </button>
      </div>
    </div>

    <div class="mx-auto px-6">
      <div class="md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        <TimeSheetItemEmployer
            v-for="(timeSheet) in timeSheetsByJobGroup" :key="timeSheet.id"
            :time-sheet="timeSheet"
            @changeStatus="changeStatus($event)"
        />


        <div v-if="timeSheetsByJobGroup.length < 1">
          <p>Geen shifts die geaccodeerd hoeven worden</p>
        </div>
      </div>
    </div>

    <div class="mx-auto px-6 mt-8 text-center flex justify-between">
        <button v-if="selectedWeek.weekNumber > firstWeekNumber"
                type="button" class="button button--outline button--auto"
                @click="changeWeekWithButton('previous')">
          Vorige week
        </button>

        <button v-if="selectedWeek.weekNumber <  currentWeekNumber"
                type="button" class="button button--outline button--auto"
                @click="changeWeekWithButton('next')">
          Volgende week
        </button>
    </div>

    <div class="container mx-auto px-6 mt-8 mb-12 text-center">
        <h4 class="text-sm font-bold">Heb je een fout gemaakt? Neem contact op</h4>
        <p class="text-sm text-gray leading-loose">
          Mocht je de verkeerde uren hebben goedgekeurd of afgekeurd. Neem dan contact op met het My Shifts team.
          <!--        <a href="">Stuur een email</a><br>-->
          <!--        <a href="">Stuur een bericht via whatsapp</a>-->
        </p>
    </div>

    <!--MSS-504-->
     <div v-if="new_hour_system_modal">
      <Overlay @closeOverlay="new_hour_system_modal = false;">
        <div class="relative">
            <div class="contract__container mb-32">
              <p>Uren controleren kan tot maandag 12:00. Wij accorderen maandagochtend alles zodat de facturen gegenereerd kunnen worden.</p>
              <p>Jullie kunnen ons mailen als er uren gewijzigd moeten worden. Wij regelen dit direct! Mailen kan naar <a href="mailto:finance@myshifts.nl">finance@myshifts.nl</a></p>
              <p>Als wij factuur verstuurd hebben en de ZZP'er uitbetaald is kunnen facturen niet meer gewijzigd of gecrediteerd worden!</p>
            </div>
            <div class="fixed bottom-0 left-0 right-0 mx-auto max-w-lg p-6 pt-0 bg-white bg-opacity-90">
                <ButtonPrimary class="mt-4" text="Accepteren" @click.native="new_hour_system_modal = false;" />
            </div>
        </div>
      </Overlay>
    </div>
    <!--MSS-504-->

  </div>
</template>

<script>
import TimeSheetItemEmployer from "@/components/timesheets/TimeSheetItemEmployer";
import WeekPicker from "@/components/date-time/WeekPicker";
import timeSheetMixin from "@/mixins/timeSheetMixin";
import constants from "@/settings/constants";
import Overlay from "@/components/Overlay";
import ButtonPrimary from "@/components/buttons/ButtonPrimary";

export default {
  mixins: [timeSheetMixin],

  components: {
    TimeSheetItemEmployer,
    WeekPicker,
    Overlay,
    ButtonPrimary
  },

  data: () => ({
    week: null,
    declineReason: null,
    jobGroupSelected: 'all',
    jobGroups: [
      {id: 'all', title: 'Alles'},
      {id: constants.JOB_GROUP_KITCHEN_CREW, title: 'Kitchen Crew'},
      {id: constants.JOB_GROUP_HOSPITALITY, title: 'Hospitality'},
      {id: constants.JOB_GROUP_FACILITY, title: 'Facilitair'},
    ],
    overlay: {
      decline: false,
    },
    new_hour_system_modal: false
  }),

  watch: {
    dataFetched: function() {
      if (this.dataFetched) {
        console.log('done');
        this.new_hour_system_modal = true;
      }
    }
  },

  computed: {
    timeSheetsByJobGroup(){

      if(this.jobGroupSelected === 'all'){
        return this.timeSheets;
      }

      const test = this.timeSheets.filter((item) => {
        return item.registrant.jobGroupId === this.jobGroupSelected;
      })


      return test;
    }
  },


  methods: {
    decline() {
      // Show success
      this.successToast('Je afwijzing van de uren zijn succesvol verstuurd');
      // Hide overlay
      this.overlay.decline = false;
    },

    onJobGroupChange(jobGroupId) {
      this.jobGroupSelected = jobGroupId;
    },

    changeWeekWithButton(action) {
      this.$refs.weekPicker.changeWeekFromOutside(action);
    }
  }
}
</script>


<style scoped>
.job-group-navigation > * {
  @apply relative outline-none;
}

.job-group-navigation > :hover::before {
  @apply absolute block bg-gray-light rounded-t rounded-b-none top-0 md:top-auto md:bottom-0 left-0 right-0;

  content: ' ';
  height: 5px;
}

.job-group-navigation > .active::before {
  @apply absolute block bg-orange rounded-t rounded-b-none top-0 md:top-auto md:bottom-0 left-0 right-0;

  content: ' ';
  height: 5px;
}
</style>
