<template>
  <div>
    <template v-if="label">
      <label :for="id">{{ label }} <span v-if="required">*</span></label>
    </template>


    <ValidationProvider :rules="required ? `required|${rules}` : `${rules}`" v-slot="{ errors, classes }">
      <input class="form-field" type="text" :placeholder="placeholder" :id="id" :class="classes" :value="value" @input="$emit('input', $event.target.value)" v-on:keyup.enter="onEnter()" :disabled="disabled"/>
      <div class="validate-error">{{ errors[0] }}</div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },

    label: {
      required: false,
      type: String,
    },

    placeholder: {
      required: false,
      type: String,
    },

    required: {
      required: false,
      type: Boolean,
    },

    rules: {
      required: false,
      type: String,
      default: '',
    },

    value: {
      required: false,
      type: [String,Number],
    },

    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },

  },

  methods: {
    onEnter() {
      this.$emit('onEnter')
    }
  }
}
</script>
