<template>
  <div v-if="dataFetched">
    <ShiftOverviewPaginated
        :pagination-results="paginationResults"
        :text="{
          title: 'Shifts archief',
          subtitle: 'Alle shifts die al gewerkt en gesloten zijn',
          noneAvailable: 'Geen afgeronde shifts beschikbaar..',
          button:'Laad meer shifts'
        }"
        :hasPassed="true"
        @getNextPageResults="getPaginationResults($event)"
    >


      <template v-slot:shiftItem="shifts">
        <EmployerShiftItem v-for="(shift, innerIndex) in shifts.data"
                           :key="innerIndex"
                           :shift="shift"
                           :fixed-width="false"
                           :has-passed="true"
        />
      </template>


    </ShiftOverviewPaginated>
  </div>
</template>
<script>
import shiftRepository from "@/repositories/shiftRepository";
import ShiftOverviewPaginated from "@/components/shifts/ShiftOverviewPaginated";
import EmployerShiftItem from "@/components/shifts/EmployerShiftItem";

export default {
  components: {
    ShiftOverviewPaginated,
    EmployerShiftItem
  },

  data: () => ({
    dataFetched: false,
    paginationResults: {},
  }),

  methods: {
    async getPaginationResults(pageNr) {
      this.paginationResults = await shiftRepository.getPaginatedPastForEmployer(pageNr);
    }
  },

  async created() {
    this.$store.dispatch('general/startloading');
    await this.getPaginationResults(1)
    this.$store.dispatch('general/endloading');
    this.dataFetched = true;
  },
}
</script>
