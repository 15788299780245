<template>
  <div>

    <ShiftFilters v-on="$listeners" />

    <div class="px-6">
      <div v-if="shiftsLoading || loading" class="mt-6">
        <p>Shift aan het laden..</p>
      </div>

      <div class="md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        <!--    SHIFT ITEM SLOT    -->
        <slot name="shiftItem" v-bind:data="shifts"></slot>
        <!--    SHIFT ITEM SLOT    -->
      </div>
    </div>

    <div class="py-6 flex justify-center" v-if="shiftsLoading">
      <vue-loaders name="ball-beat" color="#fb5f00" scale="1"></vue-loaders>
    </div>

    <div class="flex justify-center mb-6" v-if="!shiftsLoading && pageNr < totalPages">
      <button class="button button--outline" @click="loadMoreShifts">
        <span class="font-bold text-sm">Laad meer shifts</span>
      </button>
    </div>

    <div class="text-center mb-6 hidden">
      <span>Page {{ pageNr }}/{{ totalPages }}</span>
    </div>

  </div>
</template>

<script>
import ShiftFilters from "@/components/shifts/Filters";
// import Observer from "@/components/Observer";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    paginationResults: {
      type: Object,
      required: true
    },
    // hasPassed: {
    //   type: Boolean,
    //   required: false,
    //   default: false
    // }
  },

  components: {
    ShiftFilters,
    // Observer
  },

  data: () => ({
    shiftsLoading: false,
    pageNr: 1,
    shifts: [],
    totalPages: 0,
  }),

  watch: {
    paginationResults: function (paginationResults) {
      this.totalPages = paginationResults.totalPages;
      this.processNewPaginationResults(paginationResults);
    },
  },

  methods: {
    async loadMoreShifts() {
      this.shiftsLoading = true;

      if (this.pageNr < this.totalPages) {
        this.pageNr++;
        if (this.pageNr > this.totalPages) {
          return false;
        }
          await this.$emit('getNextPageResults', this.pageNr);
        }
    },

    processNewPaginationResults(paginationResults) {
      this.shifts = [...this.shifts, ...paginationResults.pageResults];
      this.shiftsLoading = false;
    },

    clearShifts(){
      this.shifts = [];
    },

    clearPaginationInfo(){
      this.pageNr = 1;
      this.totalPages  = 0;
    }
  },

  created() {
    this.shifts = this.paginationResults.pageResults;
    this.totalPages = this.paginationResults.totalPages;
  }
}
</script>
