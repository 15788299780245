<template>
  <ValidationProvider :rules="selectionRequired ? 'required' : ''" v-slot="{ errors }">
    <multiselect
        v-model="value"
        tag-placeholder="Voeg deze tag toe"
        deselectGroupLabel="'Druk op enter om een groep te deselecteren'"
        deselectLabel=""
        selectLabel=""
        :placeholder="placeholder"
        label="title"
        track-by="id"
        :options="tagOptions"
        :multiple="true"
        :taggable="taggable"
        @tag="addTag"
        required
        :group-values="groupSelect ? 'items' : null" :group-label="groupSelect ? 'group' : null" :group-select="groupSelect"
    >
    </multiselect>
    <div class="validate-error">{{ errors[0] }}</div>
  </ValidationProvider>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  props: {
    tagOptions: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      required: false,
      default: "Zoek of maak een tag"
    },
    groupSelect: {
      type: Boolean,
      required: false,
      default: false
    },
    taggable: {
      type: Boolean,
      required: false,
      default: true
    },
    preSelected: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    },
    selectionRequired: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    Multiselect
  },

  data() {
    return {
      value: [],
      options: []
    }
  },

  watch: {
    value: function (value) {
      this.$emit('updateSelectedSelectables', value)
    },
  },

  methods: {
    addTag(newTag) {
      const tag = {
        title: newTag,
        id: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000)),
        new: true
      }
      this.options.push(tag)
      this.value.push(tag)
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
