import toastMixin from "@/mixins/toastMixin";
import usergroupNameMixin from "@/mixins/usergroupNameMixin";
import slickOptionsMixin from "@/mixins/slickOptionsMixin";
import sliderOptionsMixin from "@/mixins/sliderOptionsMixin";

export default (Vue) =>{
    Vue.mixin(toastMixin);
    Vue.mixin(usergroupNameMixin);
    Vue.mixin(slickOptionsMixin);
    Vue.mixin(sliderOptionsMixin);
}
