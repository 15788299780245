<template>
  <PlatformNavigationTemplate>
    <template v-slot:logo>
      <a class="no-underline mt-2 mb-4" href="/">
        <LogoBlackSvg :width="screenWidth > 768 ? 110 : 80"/>
      </a>
    </template>

    <template v-slot:navigation>
      <nav-item route-name="dashboard_employer" text="Dashboard" icon="home"></nav-item>
      <nav-item route-name="shifts_employer_overview" text="Shifts" icon="search"></nav-item>
      <nav-item route-name="time_sheets_employer" text="Uren" icon="clock_hours_alt"></nav-item>
      <nav-item route-name="jex" text="Facturatie" icon="checkmark_alt"></nav-item>
      <nav-item route-name="teams" text="Contact" icon="contact"></nav-item>
    </template>

    <template v-slot:navigation-right>
      <ProfileButton :imageUrl="user.imageUrl" :name="user.companyName" :subline="user.city">
        <router-link :to="{name: 'profile_employer'}" tag="button">
          Profiel bekijken
        </router-link>
        <router-link :to="{name: 'shifts_employer_overview_archive'}" tag="button">
          Shift Archief
        </router-link>
        <button type="button" @click="logout()">
          {{ $t('general.logout') }}
        </button>
      </ProfileButton>
    </template>
  </PlatformNavigationTemplate>
</template>

<script>
// import LanguageSwitch from "@/components/nav/LanguageSwitch";
import PlatformNavigationTemplate from "@/components/nav/PlatformNavigationTemplate";
import NavItem from "@/components/nav/NavItem";
import ProfileButton from "@/components/nav/ProfileButton";

export default {
  components: {
    // LanguageSwitch,
    NavItem,
    PlatformNavigationTemplate,
    ProfileButton,
  },

  computed: {
    screenWidth() {
      return window.screen.width || 1200;
    },

    user() {
      return this.$store.getters['user/get_user']
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout_action');
    },
  }
}
</script>

<style scoped>

</style>
