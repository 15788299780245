<template>
  <div>
    <vue-dropzone ref="myVueDropzone" id="customdropzone" v-on:vdropzone-thumbnail="thumbnail" :include-styling="false" :useCustomSlot="true" :options="dropzoneOptions">
        <div class="flex items-center justify-center h-full w-full flex-col">
            <img src="@/assets/images/dropzone_icon.png" class="mb-2" />
            <h3 class="text-sm text-orange mb-1">Foto’s uploaden</h3>
            <p class="text-xs text-gray">PNG, JPG, GIF tot max. 3MB</p>
        </div>
    </vue-dropzone>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'app',
  components: {
    vueDropzone: vue2Dropzone
  },
  data: function () {
    return {
      dropzoneOptions: {
        addRemoveLinks: false,
        autoQueue: false,
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 3,
        acceptedFiles: ".png, .jpg, .jpeg",
        previewTemplate: this.template(),
      }
    }
  },

  methods: {
    getFiles() {
      const photos = this.$refs.myVueDropzone.getAcceptedFiles();
      let formData = new FormData();

      photos.forEach((item, index) => {
        const file = this.$helpers.dataURLtoBlob(item.dataURL);
        const fileName = item.name;

        formData.append(index, file, fileName);
      })

      return formData;
    },

    removeAllFiles(){
      this.$refs.myVueDropzone.removeAllFiles();
    },



    buildProfilePhotoData() {
      return {
        userId: 10,
      }
    },

    /*https://rowanwins.github.io/vue-dropzone/docs/dist/#/custom-preview*/
    template: function () {
      return `<div class="dz-preview dz-file-preview relative">
                <div class="dz-image">
                    <div data-dz-thumbnail-bg></div>
                </div>
                <div class="dz-details">
                    <div class="dz-filename"><span data-dz-name></span></div>
                    <div class="dz-size"><span data-dz-size></span></div>
                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                <div class="dz-error-mark"><i class="fa fa-close"></i></div>
                <button type="button" class="w-8 h-8 rounded-full bg-gray-lighter absolute top-0 right-0 -mt-2 mr-2 flex justify-center items-center" data-dz-remove>
                    <i class="icon-cross leading-none text-gray-darker" title="Verwijderen" />
                </button>
            </div>
        `;
    },


    thumbnail: function (file, dataUrl) {
      var j, len, ref, thumbnailElement;
      if (file.previewElement) {
        file.previewElement.classList.remove("dz-file-preview");
        ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
        }
        return setTimeout(((function () {
          return function () {
            return file.previewElement.classList.add("dz-image-preview");
          };
        })(this)), 1);
      }
    },

  }
}

</script>

<style>

#customdropzone {
  @apply bg-white rounded border-2 border-dashed border-gray-light;

  font-family: 'Arial', sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  transition: background-color .2s linear;
  min-height: 200px;
  padding: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#customdropzone.dz-started .dz-message {
    display: none;
}

#customdropzone .dz-preview {
  width: 160px;
  display: inline-block;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#customdropzone .dz-preview .dz-image {
  width: 120px;
  height: 80px;
  margin-bottom: .5rem;
}

#customdropzone .dz-preview .dz-image > div {
  width: inherit;
  height: inherit;
  border-radius: .5rem;
  background-size: contain;
}

#customdropzone .dz-preview .dz-image > img {
  width: 100%;
}

#customdropzone .dz-preview .dz-details {
  color: black;
  transition: opacity .2s linear;
  text-align: center;
}

#customdropzone .dz-preview .dz-details .dz-filename {

}

#customdropzone .dz-preview .dz-details .dz-size {
  @apply text-gray;
}

#customdropzone .dz-preview .dz-details .dz-size strong {
  @apply font-normal;
}

#customdropzone .dz-success-mark, .dz-error-mark {
  display: none;
}

/** Hack for some weird dropzone stuff */
#customdropzone .icon-cross {
    @apply hidden;
}

#customdropzone button .icon-cross {
    @apply inline-block;
}
</style>
