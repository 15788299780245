<template>
  <div>
    <!-- Modal overlay -->
    <div @click="closeModal()" class="fixed inset-0 bg-black opacity-25"></div>

    <!-- Modal container -->

    <div class="fixed inset-0 top-auto mt-32 rounded-2xl p-6 bg-white m-auto max-w-lg container overflow-auto" style="max-height: 90%;">
      <WorkExperienceList styleHandle="grey" :workexperience="workexperience" :jobId="job.id" :scrollable="true"></WorkExperienceList>
      <h3> {{ $t('components.WorkExperienceAdd.worked_as') }} <span class="text-orange">{{ job.title }}</span></h3>

      <ValidationObserver ref="form">
        <InputText id="name" :placeholder="$t('components.WorkExperienceAdd.hotel_or_restaurant')" :required="true" v-model="name"/>
        <InputText id="city" :placeholder="$t('general.city_name')" :required="true" v-model="city"/>


        <InputCheckbox id="is-luxury" v-model="isLuxury">
          <p>{{ $t('components.WorkExperienceAdd.is_luxury') }}</p>
        </InputCheckbox>


        <div class="flex justify-between">
          <InputNumber id="yearsExperience"
                       :label="$t('components.WorkExperienceAdd.amount_years')"
                       v-model="yearsExperience"
                       @increment="incrementYearsOfExperience"
                       @reduce="reduceYearsOfExperience"
          />
          <span class="w-4 block">&nbsp;</span>
          <InputNumber id="monthsExperience"
                       :label="$t('components.WorkExperienceAdd.amount_months')"
                       v-model="monthsExperience"
                       @increment="incrementMonthsOfExperience"
                       @reduce="reduceMonthsOfExperience"
          />
        </div>

        <button type="button" class="mb-2 w-full py-3 px-4 rounded bg-gray-lighter text-orange text-center" @click="handleNoExperience()">
          {{ $t('components.WorkExperienceAdd.has_no_experience') }}
        </button>

        <button type="button" class="mb-2 w-full py-3 px-4 rounded bg-gray-lighter text-orange text-center" @click="validateFormAddAnother()">
          + {{ $t('components.WorkExperienceAdd.add_another_company') }}
        </button>

        <button type="button" class="button button--primary" @click="validateForm()">
          {{ $t('general.save') }}
        </button>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import WorkExperienceList from "@/components/onboarding/WorkExperienceList";

export default {
  props: {
    job: {
      required: true,
      type: Object,
    },
  },
  components: {
    WorkExperienceList
  },

  data: () => ({
    name: '',
    city: '',
    yearsExperience: '',
    monthsExperience: '',
    hasNoExperience: false,
    isLuxury: false,
  }),

  computed: {
    workexperience: function () {
      return this.$store.getters['onboardingExperience/get_work_experience'](this.job.id)
    }
  },

  methods: {
    validateForm() {
      this.$refs.form.validate().then((validated) => {
        if (validated) {
          this.setStoreData();
          this.closeModal();
        }
      });
    },


    validateFormAddAnother() {
      this.$refs.form.validate().then((validated) => {
        if (validated) {
          this.setStoreData();
          this.clearData();
          this.$refs.form.reset();
        }
      });
    },

    handleNoExperience() {
      this.name = 'Geen ervaring';
      this.city = 'Geen ervaring';
      this.hasNoExperience = true;
      this.setStoreData();
      this.closeModal();
    },

    closeModal() {
      this.$emit('closeModal');
    },

    clearData() {
      this.name = '';
      this.city = '';
      this.yearsExperience = '';
      this.monthsExperience = '';
    },

    setStoreData() {
      const workExperienceData = {
        jobId: this.job.id,
        workexperience: {
          jobId: this.job.id,
          name: this.name,
          city: this.city,
          yearsExperience: this.yearsExperience,
          monthsExperience: this.monthsExperience,
          hasNoExperience: this.hasNoExperience,
          isLuxury: this.isLuxury,
        }
      }

      this.$store.dispatch('onboardingExperience/set_work_experience', workExperienceData);
    },

    incrementYearsOfExperience() {
      const numeric = parseInt(this.yearsExperience);
      this.yearsExperience = isNaN(numeric) ? 1 : numeric + 1;
    },

    reduceYearsOfExperience() {
      this.yearsExperience = Math.max(0, this.yearsExperience - 1);
    },

    incrementMonthsOfExperience() {
      const numeric = parseInt(this.monthsExperience);
      this.monthsExperience = isNaN(numeric) ? 1 : numeric + 1;
    },

    reduceMonthsOfExperience() {
      this.monthsExperience = Math.max(0, this.monthsExperience - 1);
    },
  },


}
</script>

