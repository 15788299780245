const state = () => ({
    isLoading: false
})

const mutations = {
    'IS_LOADING'(state, isLoading) {
        state.isLoading = isLoading;
    },
}

const actions = {
    startloading:({commit}) => {
        commit('IS_LOADING', true);
    },

    endloading:({commit}) => {
        commit('IS_LOADING', false);
    }
}


const getters = {
    isLoading: (state) => {
        return state.isLoading;
    },
};


export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
