<template>
  <div>
    <PageTitle title="Dashboard" />
    <template v-if="dataFetched">
      <div class="px-6">
        <div class="mt-8">
          <p v-if="!acceptedShifts.length && !pendingShifts.length && !declinedShifts.length && !unsignedShifts.length && !unsubscribedShifts.length ">Geen shifts beschikbaar..</p>
        </div>

        <ShiftsCarousel :shifts="acceptedShifts" v-if="acceptedShifts.length">
          <h2 class="text-2xl font-normal mb-4"> Geaccepteerde shifts</h2>
          <template v-slot:shiftItem="shifts">
            <ShiftItem
                v-for="(shift,index) in shifts.data"
                :shift="shift"
                :key="index"
            />
          </template>
        </ShiftsCarousel>


        <ShiftsCarousel :shifts="pendingShifts" v-if="pendingShifts.length">
          <h2 class="text-2xl font-normal mb-4">Shifts in afwachting</h2>
          <template v-slot:shiftItem="shifts">
            <ShiftItem
                v-for="(shift,index) in shifts.data"
                :shift="shift"
                :key="index"
            />
          </template>
        </ShiftsCarousel>

        <ShiftsCarousel :shifts="declinedShifts" v-if="declinedShifts.length">
          <h2 class="text-2xl font-normal mb-4">Afgewezen shifts</h2>
          <template v-slot:shiftItem="shifts">
            <ShiftItem
                v-for="(shift,index) in shifts.data"
                :shift="shift"
                :key="index"
            />
          </template>
        </ShiftsCarousel>

        <ShiftsCarousel :shifts="unsignedShifts" v-if="unsignedShifts.length">
          <router-link class="link_shifts_overview cursor-pointer" :to="{ name: 'shifts_employee_overview' }" tag="a" style="z-index: 10">Alle shifts</router-link>
          <h2 class="text-2xl font-normal mb-4">Nieuwe shifts</h2>

          <template v-slot:shiftItem="shifts">
            <ShiftItem
                v-for="(shift,index) in shifts.data"
                :shift="shift"
                :key="index"
            />
          </template>
        </ShiftsCarousel>


        <ShiftsCarousel :shifts="pastShifts" v-if="pastShifts.length">
          <h2 class="text-2xl font-normal mb-4">Gewerkte shifts</h2>
          <template v-slot:shiftItem="shifts">
            <ShiftItem
                v-for="(shift,index) in shifts.data"
                :shift="shift"
                :key="index"
                :has-passed="true"
            />
          </template>
        </ShiftsCarousel>


        <ShiftsCarousel :shifts="unsubscribedShifts" v-if="unsubscribedShifts.length">
          <h2 class="text-2xl font-normal mb-4">Afgemeld & Geannuleerd</h2>
          <template v-slot:shiftItem="shifts">
            <ShiftItem
                v-for="(shift,index) in shifts.data"
                :shift="shift"
                :key="index"
            />
          </template>
        </ShiftsCarousel>


      </div>
  </template>
  </div>
</template>

<script>

import shiftRepository from "@/repositories/shiftRepository";
import ShiftsCarousel from "@/components/shifts/ShiftsCarousel";
import ShiftItem from "@/components/shifts/ShiftItem";

export default {
  components: {
    ShiftItem,
    ShiftsCarousel,
  },


  data: () => ({
    dataFetched: false,
    pastShifts: [],
  }),


  computed: {
    shifts: function () {
      return this.$store.getters['shift/get_employee_shifts'];
    },

    unsignedShifts: function () {
      return this.$store.getters['shift/get_employee_unsigned_shifts'];
    },

    pendingShifts: function () {
      return this.$store.getters['shift/get_employee_pending_shifts'];
    },

    acceptedShifts: function () {
      return this.$store.getters['shift/get_employee_accepted_shifts'];
    },

    declinedShifts: function () {
      return this.$store.getters['shift/get_employee_declined_shifts'];
    },

    unsubscribedShifts: function () {
      return this.$store.getters['shift/get_employee_unsubscribed_shifts'];
    },
  },

  methods: {},

  async created() {
    this.$store.dispatch('general/startloading');
    await this.$store.dispatch('shift/fetch_employee_registered_shifts_action');
    await this.$store.dispatch('shift/fetch_employee_unregistered_shifts_action');
    this.pastShifts = await shiftRepository.getPastForEmployee();
    this.dataFetched = true;
    this.$store.dispatch('general/endloading');
  }
};
</script>


<style>
.link_shifts_overview {
  position: absolute;
  right: 10px;
  padding-top: 7px;
}

@screen md {
  .link_shifts_overview {
    right: 120px;
  }
}
</style>
