<template>
  <div class="relative">
    <button type="button" @click="showOptions = !showOptions">
      <div class="profile__button">
        <img v-if="imageUrl" :src="imageUrl" alt="name"/>

        <div class="profile__button__info">
          <span class="text-sm">{{ name }}</span>
          <span class="text-xs text-gray">{{ subline }}</span>
        </div>

        <i class="icon-chevron_down"></i>
      </div>
    </button>

    <div v-click-outside="hideOptions" v-if="showOptions" class="profile__button__menu">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileButton",

  props: {
    imageUrl: {

      required: true,
      validator: prop => typeof prop === 'string' || prop === null
    },
    name: {
      type: String,
      required: true,
    },
    subline: {
      type: String,
      required: true,
    }
  },

  data: () => ({
    showOptions: false,
  }),

  computed: {},

  methods: {
    hideOptions() {
      this.showOptions = false;
    }
  },
}
</script>

<style scoped>
.profile__button {
  @apply flex items-center text-left ml-2 mr-6 md:ml-0 md:mr-8 py-5;
}

.profile__button img {
  @apply relative rounded-full w-8 h-8 md:w-10 md:h-10;
}

.profile__button > i {
  @apply inline-block w-3;

  font-size: .5rem;
}

.profile__button__info {
  @apply flex flex-col mx-4;
}

.profile__button__menu {
  @apply absolute flex flex-col rounded-xl border border-gray-light bg-white py-1 z-50 mr-6 md:mr-8;

  top: 90%;
  right: 0rem;
  width: 270px;
}

.profile__button__menu > * {
  @apply select-none text-black text-sm text-left leading-none p-3 hover:bg-gray-lighter;
}

.button:focus {
  @apply select-none appearance-none outline-none;
}
</style>
