import repository from "@/repositories/repository";
const caoRepository = repository.get("cao");

const state = () => ({
    caoList: []
})

const mutations = {
    SET_CAO_LIST(state, caoList) {
        state.caoList = caoList
    },
}

const actions = {
     fetch_all_cao_action: async ({commit}) => {
         const response = await caoRepository.getAll();
         commit('SET_CAO_LIST', response.data);
    },
}

const getters = {
    get_cao_list: (state) => {
       return state.caoList;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
