import repository from "@/repositories/repository";
const certificateRepository = repository.get("certificate");

const state = () => ({
    certificatesList: []
})

const mutations = {
    FETCH_ALL_CERTIFICATES(state, certificatesList) {
        state.certificatesList = certificatesList
    },
}

const actions = {
    fetch_all_certificates_action: async ({commit}) => {
        const response = await certificateRepository.getAll();
        commit('FETCH_ALL_CERTIFICATES', response.data);
        return response.data;
    },
}

const getters = {
    get_certificate_list: (state) => {
        return state.certificatesList;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
