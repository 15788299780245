export default {

    data: () => ({
        sliderOptions_mixin: {
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            centerMode: true,
            variableWidth: true
        },
    }),
}
