import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
    customClass: {
        // container: '...',
        popup: 'swal-custom-popup',
        // header: '...',
        title: 'swal-custom-title',
        // closeButton: '...',
        // icon: '...',
        // image: '...',
        // content: '...',
        // input: '...',
        // inputLabel: '...',
        // validationMessage: '...',
        // actions: '...',
        confirmButton: 'button button--primary button--modal',
        denyButton: 'button',
        cancelButton: 'button',
        // loader: '...',
        // footer: '....'
    },
    closeButtonHtml: '<i class="icon-cross text-gray-darker text-xs p-4 hover:text-orange"></i>',
};

export default (Vue) => {
    Vue.use(VueSweetalert2, options);
}
