import AxiosClient from "@/config/axios/axios";
import store from "@/store/store";
import ErrorService from "@/services/errors/ErrorService";
const resource = "/cao";

export default {
    getAll() {
        store.dispatch('general/startloading');
        return AxiosClient.get(`${resource}/get/all`)
            .then((response) => {
                store.dispatch('general/endloading');
                return response;
            })
            .catch((error) => {
                ErrorService.onError(error)
            })
    },
}
