<template>
  <div>
    <PageTitle title="Shifts" subtitle="Alle ingeplande shifts" />
    <ShiftOverviewPaginated v-if="dataFetched"
        :pagination-results="paginationResults"
        ref="ShiftOverviewPaginated"
        @getNextPageResults="getPaginationResults($event)"
        @filterRequest="sendFilterRequest()"
    >    
      <template v-slot:shiftItem="shifts">
        <ShiftItem v-for="(shift, index) in shifts.data"
                   :key="index"
                   :shift="shift"
                   :fixed-width="false"
        />
      </template>
    </ShiftOverviewPaginated>

    

    <div v-if="shift_requirements_employee && user_is_self_employed">
      <Overlay @closeOverlay="shift_requirements_employee = false;">
        <div class="relative">
            <div class="contract__container mb-32">
              <template>
                <ul>
                  <li>Minimale shiftduur en dus minimaal aantal uitbetaalde uren:
                    <ul class="leading-none mt-2">
                      <li>Keuken: 6 uur</li>
                      <li>Hospitality: 4 uur</li>
                      <li>Facility: 3 uur</li>
                    </ul>
                  </li>
                  <li>Annuleren kan tot 48 uur voor aanvang van de shift. Je mag zelf vervanging zoeken als je niet kan. Dit dien je telefonisch door te geven aan ons via: 077-2030060</li>
                  <li>Wil je je binnen 48 uur afmelden en heb je geen vervanging dan bel je zelf naar het bedrijf en daarna bel je naar My Shifts kantoor. No Show zonder afmelding betekend dat je account per direct verwijderd wordt. DOE HET DUS NIET!</li>
                  <li>Klok direct na je shift en uiterlijk binnen 24 uur!</li>
                  <li>Als er geen geaccepteerde shift in het systeem staat mag er ook niemand werken! Je bent niet verzekerd en het is een risico voor ons als platform. Met ingang van 21-10-22 staat er een direct opeisbare boete van €100,- per shift tegeover. DOE HET DUS NIET! Wordt je toch gevraagd om te werken maar is er geen shift? Bel ons dan direct via 077-2030060. Bereik je ons niet? GA NIET WERKEN!</li>
                </ul>                
                <p>
                  Met vriendelijke groet,<br />
                  My Shifts team.
                </p>
              </template>
            </div>
            <div class="fixed bottom-0 left-0 right-0 mx-auto max-w-lg p-6 pt-0 bg-white bg-opacity-90">
                <ButtonPrimary class="mt-4" text="Accepteren" @click.native="shift_requirements_employee = false;" />
            </div>
        </div>
      </Overlay>
    </div>

  </div>
</template>

<script>
import shiftRepository from "@/repositories/shiftRepository";
import ShiftItem from "@/components/shifts/ShiftItem";
import ShiftOverviewPaginated from "@/components/shifts/ShiftOverviewPaginated";
import Overlay from "@/components/Overlay";
import ButtonPrimary from "@/components/buttons/ButtonPrimary";

export default {
  name: 'ShiftOverviewEmployee',

  components: {
    ShiftOverviewPaginated,
    ShiftItem,
    Overlay,
    ButtonPrimary
  },

  data: () => ({
    dataFetched: false,
    shift_requirements_employee: false,
    paginationResults: {},
  }),

  watch: {
    dataFetched: function() {
      if (this.dataFetched == true) {
        this.shift_requirements_employee = true;
      }
    }
  },

  computed: {
    userData() {
      return this.$store.getters['user/get_user'];
    },
    user_is_self_employed: function() {
      let val = false;
      if (this.userData.group == 2) {
        return true;
      }
      return val;
    }
  },

  methods: {
    async checkForFilters() {
      let filters = await this.$store.getters['shift/get_filters'];
      if (Object.keys(filters).length === 0) {
        console.log('It appears no filters were present in the shift store')
        await this.$store.dispatch('shift/set_default_filters');
        filters = await this.$store.getters['shift/get_filters'];
        return filters;
      }
      else {
        console.log('Filter options present in shift store')
        return filters;
      }
    },

    async getPaginationResults(pageNr) {
      const filters = await this.checkForFilters();      
      this.paginationResults = await shiftRepository.getFilteredPaginatedForEmployee(pageNr, filters);
    },

    async sendFilterRequest() {
      this.$store.dispatch('general/startloading');
      const filters = await this.checkForFilters();      
      this.$refs.ShiftOverviewPaginated.clearShifts();
      this.$refs.ShiftOverviewPaginated.clearPaginationInfo();
      this.paginationResults = await shiftRepository.getFilteredPaginatedForEmployee(1, filters);
      this.$store.dispatch('general/endloading');
    }
  },

  async created() {
    this.$store.dispatch('general/startloading');
    await this.getPaginationResults(1)
    this.$store.dispatch('general/endloading');
    this.dataFetched = true;
  },
}
</script>
