import constants from "@/settings/constants";


import ProfileEmployee from "@/views/ProfileEmployee";
import JEX from "@/views/JEX";
import Teams from "@/views/Teams";

import ContractUitzendkracht from "@/views/contract_agreements/ContractUitzendkracht";
import ContractWerkgever from "@/views/contract_agreements/ContractWerkgever";
import ContractZZP from "@/views/contract_agreements/ContractZZP";


const SELF_EMPLOYED = constants.USERGROUP_SELF_EMPLOYED;
const TEMPORARY_EMPLOYED = constants.USERGROUP_TEMPORARY_EMPLOYED;
const EMPLOYER = constants.USERGROUP_EMPLOYER;
export default [




    {
        path: "/employee/profile",
        name: "profile_employee",
        component: ProfileEmployee,
        meta: {
            requiresAuth: true,
            hasPermission: [TEMPORARY_EMPLOYED, SELF_EMPLOYED, EMPLOYER],
            layout: 'navigation-back'
        }
    },


    {
        path: "/JEX",
        name: "jex",
        component: JEX,
        meta: {
            requiresAuth: true,
            hasPermission: [TEMPORARY_EMPLOYED, SELF_EMPLOYED, EMPLOYER],
            layout: 'navigation'
        }
    },


    {
        path: "/teams",
        name: "teams",
        component: Teams,
        meta: {
            requiresAuth: true,
            hasPermission: [TEMPORARY_EMPLOYED, SELF_EMPLOYED, EMPLOYER],
            layout: 'navigation'
        }
    },
    
    {
        path: "/contract_agreements/uitzendkracht",
        component: ContractUitzendkracht,
        meta: {
            layout: 'navigation',
            requiresAuth: true,
            hasPermission: [TEMPORARY_EMPLOYED, SELF_EMPLOYED, EMPLOYER],
        }
    },
    {
        path: "/contract_agreements/werkgever",
        component: ContractWerkgever,
        meta: {
            layout: 'navigation',
            requiresAuth: true,
            hasPermission: [TEMPORARY_EMPLOYED, SELF_EMPLOYED, EMPLOYER],
        }
    },
    {
        path: "/contract_agreements/zzp",
        component: ContractZZP,
        meta: {
            layout: 'navigation',
            requiresAuth: true,
            hasPermission: [TEMPORARY_EMPLOYED, SELF_EMPLOYED, EMPLOYER],
        }
    },

]
