import authRepository from "@/repositories/authRepository";

export default class LocalStorageVersioningService {
    currentVersion;




    constructor() {
        this.currentVersion = this.getAppVersion();
    }




    static getAppVersion() {
        return process.env.VUE_APP_LOCAL_STORAGE_VERSION;
    }




    static getUserVersion() {
        return localStorage.getItem("LocalStorageVersion")
    }




    static setVersion() {
        localStorage.setItem("LocalStorageVersion", this.getAppVersion());
    }




    static isLatestVersion() {
        return this.getAppVersion() === this.getUserVersion();
    }




     static async clearLocalStorage() {
        await authRepository.logout();
        //timeout is used because of persisted state plugin
        setTimeout(function () {
            window.localStorage.removeItem('vuex');
            window.localStorage.removeItem('user');
            location.reload();
        }, 100);
    }


}
