<template>
<div>
    <LanguageSwitch />
    <slot></slot>
</div>
</template>

<script>
import LanguageSwitch from "@/components/nav/LanguageSwitch";
export default {
  components: {
    LanguageSwitch
  },
}
</script>

