<template>
    <div class="bg-black text-white p-6 mb-6">
        <div :class="{ 'container mx-auto sm:px-4': centered }">
            <div class="flex flex-row justify-between">
                <div class="flex flex-wrap content-center">
                    <div>
                        <p v-if="lead" class="text-orange text-sm">{{ lead }}</p>
                        <h2 v-if="title" class="text-xl md:text-4xl text-white font-normal mb-0">{{ title }}</h2>
                        <p v-if="subtitle" class="text-gray text-sm">{{ subtitle }}</p>
                        <slot name="pageTitleLeft"></slot>
                    </div>
                </div>
                <div class="flex-none">
                    <slot name="pageTitleRight"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        lead: {
            type: String,
            default: '',
        },
        subtitle: {
            type: String,
            default: '',
        },
        centered: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        checkSlot: function() {
            return !!this.$slots.pageTitleRight
        }
    }
}
</script>

<style lang="scss" scoped>
div.bg-black {
    position: relative;
    &:after {
        content: '';
        height: 27px;
        background: url('~@/assets/images/black_bar_@2x.png') #FFF;
        background-size: auto 27px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
    }
}
</style>