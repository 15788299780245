<template>
  <PlatformNavigationTemplate>
    <template v-slot:logo>
        <LogoBlackSvg :width="screenWidth > 768 ? 110 : 80"/>
    </template>

    <template v-slot:navigation>
      <nav-item route-name="dashboard_employee" text="Dashboard" icon="home"></nav-item>
      <nav-item route-name="shifts_employee_overview" text="Shifts" icon="search"></nav-item>
      <nav-item route-name="time_sheets_employee" text="Uren" icon="clock_hours_alt"></nav-item>
      <nav-item route-name="jex" :text="jexNavName" icon="checkmark_alt"></nav-item>
      <nav-item route-name="teams" text="Contact" icon="contact"></nav-item>
    </template>

    <template v-slot:navigation-right>
      <ProfileButton :imageUrl="user.imageUrl" :name="user.firstName" :subline="user.city">
        <router-link :to="{name: 'profile_employee'}" tag="button">
          Profiel bekijken
        </router-link>
        <router-link :to="{name: 'shifts_employee_overview_archive'}" tag="button">
          Gewerkte shifts
        </router-link>

        <a href="http://www.myshifts.shop" target="_blank">Kleding</a>

        <button type="button" @click="logout()">
          {{ $t('general.logout') }}
        </button>
      </ProfileButton>
    </template>
  </PlatformNavigationTemplate>
</template>

<script>

import PlatformNavigationTemplate from "@/components/nav/PlatformNavigationTemplate";
import NavItem from "@/components/nav/NavItem";
import ProfileButton from "@/components/nav/ProfileButton";
import constants from "@/settings/constants";

export default {
  components: {
    NavItem,
    PlatformNavigationTemplate,
    ProfileButton
  },
  computed: {
    screenWidth() {
      return window.screen.width || 1200;
    },

    user() {
      return this.$store.getters['user/get_user']
    },

    jexNavName(){
      switch (this.user.group) {
        case constants.USERGROUP_SELF_EMPLOYED:
          return 'Facturatie';
        case constants.USERGROUP_TEMPORARY_EMPLOYED:
          return 'Verloning';
        default:
          return 'Verloning';
      }
    }
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout_action');
    },
  }
}
</script>
