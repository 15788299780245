<template>
  <div>
    <div class="registrar">
      <img class="registrar__image" :src="registrar.user.imageUrl"/>
      <span v-if="registrar.status !== 'unsigned' && registrar.status !== 'pending'" class="registrar__status" :class="`registrar__status--${registrar.status}`">
        <i v-if="registrar.status === 'accepted'" class="icon-checkmark_alt"/>
        <i v-if="registrar.status === 'declined' || registrar.status === 'unsubscribed' || registrar.status === 'canceled'" class="icon-cross"/>
      </span>

      <span class="registrar__group">{{ userGroup_mixin(registrar.user.group) }}</span>

      <h3 class="mb-1">{{ registrar.user.firstName }} {{ registrar.user.lastName }}</h3>
      <div class="text-xs text-gray-dark mb-2">{{ registrar.jobSpecific }}</div>
      <div class="font-semibold text-orange mb-2">&euro; {{ parseFloat(registrar.labourCost).toFixed(2) }} /uur</div>
      <div class="text-sm mb-1">{{ registrar.user.city }} ({{ registrar.distance }} km)<template v-if="!shift.shift_hasNoTravelExpenses">, &euro; {{ registrar.travelAllowance.total }} reiskosten</template></div>
      <div class="text-sm mb-4">Total kosten shift: &euro; {{ shiftCosts }}</div>
      <i v-if="registrar.distance > 180" class="text-xs text-grey">* * maximale reisafstand die vergoed wordt is 180km</i>

      <template v-if="registrar.status === 'pending' && !isFilledShift && !hasPassed">
        <button type="button" class="button button--primary mb-2" @click="$emit('accept', registrar)">{{ registrar.user.firstName }} kiezen</button>
        <button type="button" class="button button--outline mb-4" @click="$emit('decline', registrar)">{{ registrar.user.firstName }} afwijzen</button>
      </template>

      <template v-if="registrar.status === 'accepted'">
        <div class="h-12 w-full flex items-center justify-center rounded border border-gray-lighter text-gray">
          <i class="icon-checkmark_alt mr-2 rounded-full border-2 border-white text-white inline-block h-6 w-6 flex items-center justify-center bg-green text-xs"/>
          Gekozen
        </div>

        <div class="w-full mt-2 text-left relative mb-8">
          <template v-if="!cancelDateHasPassed">
            <button class="button button--outline" @click="cancelRegistration(registrar.registrationId)">Registratie Annuleren</button>
          </template>
          <template v-if="cancelDateHasPassed">
            <button class="button button--outline" @click="unsubscribeRegistration(registrar.registrationId)">Registratie Afmelden</button>
          </template>
        </div>
      </template>

      <template v-if="registrar.status === 'pending' && isFilledShift">
        <div class="h-12 mb-8 w-full flex items-center justify-center rounded border border-gray-lighter text-gray">
          <i class="icon-checkmark_alt mr-2 rounded-full border-2 border-white text-white inline-block h-6 w-6 flex items-center justify-center bg-green text-xs"/>
          Shift is al gevuld
        </div>
      </template>

      <template v-if="registrar.status !== 'accepted' && registrar.status !== 'pending'">
        <div class="h-12 mb-8 w-full flex items-center justify-center rounded border border-gray-lighter text-gray">
          <i class="icon-cross mr-2 rounded-full border-2 border-white text-white inline-block h-6 w-6 flex items-center justify-center bg-red text-xs"/>
          <template v-if="registrar.status === 'declined'">
            Afgewezen
          </template>
          <template v-if="registrar.status === 'unsubscribed' || registrar.status === 'unsubscribedByEmployer'">
            Afgemeld <span v-if="registrar.status === 'unsubscribedByEmployer'">*</span>
          </template>
          <template v-if="registrar.status === 'canceled' || registrar.status === 'canceledByEmployer'">
            Geannuleerd <span v-if="registrar.status === 'canceledByEmployer'">*</span>
          </template>
        </div>
      </template>


      <div class="block">
        <h3>Spreekt de volgende talen</h3>
        <Flag class="mr-2" v-for="(language, index) in registrar.user.languages" :code="language.code" :key="index"/>
      </div>

      <div v-if="registrar.jobWorkExperience" class="block">
        <div v-for="(job, index) in Object.keys(workexperienceGroupedByJob)" :key="`${index}_with_experience`" class="mb-6">
          <h3>Werkervaring {{ job }}</h3>
          <ul v-for="(experience, innerIndex) in workexperienceGroupedByJob[job]" class="text-gray mb-1" :key="innerIndex">
           <li>{{ experience.nameOfEmployer }}, {{ experience.city }}</li>
          </ul>
        </div>
      </div>

      <div v-if="registrar.user.educations[this.shift.jobGroupId]" class="block">
        <h3>Opleiding ({{ registrar.user.educations[this.shift.jobGroupId].length }})</h3>
        <TinyAccordion title="Bekijk opleidingen">
          <ul v-for="(education, index) in registrar.user.educations[this.shift.jobGroupId]" class="text-gray-dark mb-3" :key="index">
           <li>{{ education.title }}</li>
          </ul>
        </TinyAccordion>
      </div>


      <div v-if="registrar.user.certificates[this.shift.jobGroupId]" class="block">
        <h3>Certificaten ({{ registrar.user.certificates[this.shift.jobGroupId].length }})</h3>
        <TinyAccordion title="Bekijk certificaten">
          <ul v-for="(certificate, index) in registrar.user.certificates[this.shift.jobGroupId]" class="text-gray-dark mb-3" :key="index">
            <li>{{ certificate.title }}</li>
          </ul>
        </TinyAccordion>
      </div>

      <div v-if="registrar.user.specialisations.length" class="block">
        <h3>Specilisaties ({{ registrar.user.specialisations.length }})</h3>
        <TinyAccordion title="Bekijk specialisatiess">
          <ul v-for="(specialty, index) in registrar.user.specialisations" class="text-gray-dark mb-3" :key="index">
            <li>{{ specialty.title }}</li>
          </ul>
        </TinyAccordion>
      </div>

      <router-link class="button button--outline mt-6 mb-4" :to="{name: 'profile_employee',  query: { id: registrar.user.id }}" tag="button">
        Profiel bekijken
      </router-link>

    </div>
  </div>
</template>


<script>

/* This component is the detail page of a employee user where employers can check worked hours etc. */
import Flag from '@/components/Flag';
import TinyAccordion from '@/components/TinyAccordion';
import shiftCalculationsMixin from "@/mixins/shiftCalculationsMixin";
import shiftRegistrationRepository from "@/repositories/shiftRegistrationRepository";

export default {
  name: 'ShiftRegistrar',
  
  mixins: [shiftCalculationsMixin],

  props: {
    registrar: {
      type: Object,
      required: true
    },
    shift: {
      type: Object,
      required: true
    },
    hasPassed: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  components: {
    Flag,
    TinyAccordion
  },

  computed: {
    shiftCosts() {
      return (this.registrar.travelAllowance.total + (this.shift.duration * this.registrar.labourCost)).toFixed(2);
    },

    workexperienceGroupedByJob: function () {
      return this.lodash.groupBy(this.registrar.jobWorkExperience, 'job')
    },
  },


  methods: {
    async cancelRegistration(registrationId) {
      let isConfirmed = await this.alertBeforeCancelUnsubscribe(true);
      if (isConfirmed) {
        this.$store.dispatch('general/startloading');
        shiftRegistrationRepository.cancel(registrationId).then((response) => {
          this.$store.dispatch('general/endloading');
          this.handleResponse(response)
        });
      }
    },

    async unsubscribeRegistration(registrationId) {
      let isConfirmed = await this.alertBeforeCancelUnsubscribe(false);
      if (isConfirmed) {
        const data = {
          registrationId: registrationId,
          reasonDecline: 'Geen reden',
        }
        this.$store.dispatch('general/startloading');
        shiftRegistrationRepository.unsubscribe(data).then((response) => {
          this.$store.dispatch('general/endloading');
          this.handleResponse(response)
        });
      }
    },

    handleResponse(response) {
      switch (response.message) {
        case 'shift_registration_canceled':
        case 'shift_registration_unsubscribed':
          this.successToast('Je hebt de registratie succesvol geannuleerd!')
          this.$emit('refreshData');
          break;
        default:
          this.errorToast('Er ging iets mis met annuleren, probeer het later opnieuw.')
      }
    },

    alertBeforeCancelUnsubscribe(isCancel) {
      let title = "Annuleer registratie";
      let text = "Weet je zeker dat je deze registratie wilt annuleren? Dit kan niet meer ongedaan worden gemaakt.";
      let buttonText = "Registratie annuleren";
      if (!isCancel) {
        title = "Meld registratie af";
        text = "Weet je zeker dat je deze shift wilt afmelden? Dit kan niet meer ongedaan worden gemaakt, en de registratie zal gewoon uitbetaald moeten worden.";
        buttonText = "Registratie afmelden";
      }
      return this.$swal({
        title: title,
        text: text,
        confirmButtonText: buttonText,
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonColor: '#FF5F00',
      }).then((result) => {
        return result.isConfirmed;
      })
    },

  },


};
</script>


<style scoped>
.registrar {
  @apply border border-gray-light rounded p-4 relative mt-10;

  width: 300px !important;
}

.registrar::before {
  @apply absolute block inset-0 bg-no-repeat bg-left-bottom;
  user-select: none;
  background-image: url('~@/assets/images/noise_gray_left_@2x.png');
  background-size: 129px 20px;
  content: ' ';
  margin-bottom: 0 !important;
}

.registrar__image {
  @apply rounded-full w-20 h-20 block absolute right-0 top-0 -mt-10 mr-4 z-10;
}

.registrar__status {
  @apply absolute right-0 top-0 -mt-10 mr-2 z-20 rounded-full border-2 border-white text-white inline-block h-8 w-8 flex items-center justify-center;
}

.registrar__status--accepted {
  @apply bg-green text-xs;
}

.registrar__status--accepted i {
  @apply relative;
  left: -2px;
}

.registrar__status--pending {
  @apply bg-yellow text-sm;
}

.registrar__status--pending i {
  @apply relative;
  top: -1px;
}

.registrar__status--declined, .registrar__status--unsubscribed, .registrar__status--canceled {
  @apply bg-red text-sm;
}

.registrar__status--declined i {
  @apply relative;
  top: -1px;
}

.registrar__group {
  @apply text-sm rounded bg-black text-white py-1 px-2 inline-block mb-4;
}

.registrar button {
  @apply w-full;
}

.block {
  @apply mb-6 text-xs md:text-sm;
}

.block h3 {
  @apply mb-2 text-sm md:text-base;
}
</style>
