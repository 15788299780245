<template>
  <div>
    <template v-if="label">
      <label :for="id">{{ label }} <span v-if="required">*</span></label>
    </template>

    <ValidationProvider :rules="required ? 'required' : ''" v-slot="{ errors }">
      <label class="border border-gray-light rounded p-4 block items-center mt-3 cursor-pointer"
             v-for="(option, index) in options"
             :key="index"
             :class="{ 'bg-orange': isSelected(option.value), 'bg-noise': isSelected(option.value), 'text-white': isSelected(option.value) }"
      >
        <div class="flex w-full">
          <span v-if="isSelected(option.value)" class="mr-3 text-black">
            <i class="icon-checkmark"/>
          </span>
          <input type="radio" class="opacity-0 absolute"
                 :id="id + index"
                 :value="option.value"
                 v-model="selectedOption"
                 @change="updateValue(option.value)"
          />

          {{ option.title }}
        </div>
      </label>

      <div class="validate-error">{{ errors[0] }}</div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    options: {
      type: [Array],
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: [String, Object],
      default: "",
    },
  },

  data: () => ({
    selectedOption: '',
  }),

  methods: {
    updateValue(value) {
      this.$emit("newValue", value);
    },

    isSelected(value) {
      return this.selectedOption === value;
    }
  },


};
</script>
