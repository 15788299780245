import LayoutDefault from '@/layouts/LayoutDefault'
import LayoutNavigation from "@/layouts/LayoutNavigation";
import LayoutNavigationBack from "@/layouts/LayoutNavigationBack";
import LayoutNoNavigation from "@/layouts/LayoutNoNavigation";
import LayoutOnboarding from "@/layouts/LayoutOnboarding";
import PageTitle from "@/components/page/PageTitle";

export default (Vue) =>{
    Vue.component('layout-default', LayoutDefault)
    Vue.component('layout-navigation', LayoutNavigation)
    Vue.component('layout-navigation-back', LayoutNavigationBack)
    Vue.component('layout-no-navigation', LayoutNoNavigation)
    Vue.component('layout-onboarding', LayoutOnboarding)

    Vue.component('PageTitle', PageTitle);
}
