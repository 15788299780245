<template>
<div class="form-group">
    <template v-if="label">
        <label :for="id">{{label}} <span v-if="required">*</span></label>
    </template>

    <ValidationProvider rules="required" v-slot="{ errors, classes }">
        <input class="form-field" type="date"
            :id="id"
            :class="classes"
            :value="value"
            :min="disabledDatesUpTo"
            :max="disabledDatesFrom"
            :placeholder="placeholder"
            @input="$emit('input', $event.target.value)"
        />
        <div class="validate-error">{{ errors[0] }}</div>
    </ValidationProvider>

</div>
</template>

<script>
export default {
    props: {
        id: {
            required: true,
            type: String,
        },

        label: {
            required: false,
            type: String,
        },

        placeholder: {
            required: false,
            type: String,
        },

        required: {
            required: false,
            type: Boolean,
        },

        disabledDatesUpTo: {
            type: String,
            required: false,
        },

        disabledDatesFrom: {
            type: String,
            required: false,
        },

        value: {
            required: false,
            type: String,
        },
    },
}
</script>
