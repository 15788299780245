<template>
<div>
    <div class="black-bar"></div>
    <div class="flex flex-col items-center text-center p-4">
        <a class="no-underline mt-2 mb-4" href="/">
            <LogoBlackSvg />
        </a>

        <div class="container md:w-1/2">
            <h1>{{ $t('password_forgot.title') }}</h1>

            <InputEmail id="email" :placeholder="$t('general.email')" :required="true" v-model="pwrUsername" />

            <div class="alert" v-if="loginFeedback">{{ loginFeedback }}</div>

            <button type="button" class="button button--primary" @click="resetPasswordRequest">
                {{ $t('password_forgot.button') }}
            </button>
        </div>
    </div>

    <div class="px-4 text-sm md:text-center">
        <p>
            <router-link :to="{ name: 'login' }">{{ $t('general.login') }}</router-link>
        </p>
        <p>
            <router-link :to="{ name: 'onboarding_options' }">{{ $t('login.create_account') }}</router-link>
        </p>
    </div>
</div>
</template>

<script>
export default {
    data: () => ({
        pwrUsername: '',
        loginFeedback: false,
    }),

    methods: {
        resetPasswordRequest() {
            this.$store.dispatch('general/startloading');
            this.$store.dispatch('auth/password_reset_init_action', {
                'pwrUsername': this.pwrUsername
            }).then((response) => {
                this.handleResponse(response)
            })
        },

        handleResponse(response) {
            if (response.message === 'password_reset_mail_sent') {
                this.loginFeedback = this.$t('password_forgot.feedback.password_reset_mail_sent');
                setTimeout(() => {
                    this.$router.push({
                        name: 'login'
                    });
                }, 3000)
            }
            if (response.message === 'user_not_found') {
                this.loginFeedback = this.$t('password_forgot.feedback.user_not_found');
            }
            this.$store.dispatch('general/endloading');
        },


    },
};
</script>
